import { checkIsDevelopmentMode } from '@/utils';
import { CONSOLE_EXCLUDED_ERROR_FRAGMENTS } from '@constants';
import { FrontendErrorType } from '@enums';
import { store } from '@store';
import { FrontendError, FrontendErrorRequest } from '@types';
import { format } from 'date-fns';

const ErrorLoggingService = (() => {
  let hasCritical = false;
  let controller: AbortController | null = null;
  let errors: FrontendErrorRequest[] = [];

  const catchFn = async (type: FrontendErrorType, error: { [key: string]: number | string | undefined } | Error) => {
    const transformedError = transformError(type, error);
    if (checkIsErrorInExceptionList(transformedError)) return;

    errors.push(transformedError);
    await send();
  };

  const send = async () => {
    if (store.getState().system.networkConnection.isOffline) return;

    if (errors.length > 5) {
      hasCritical = true;
    }

    const errorsToSend: FrontendError[] = shouldSendErrors() ? [...errors] : [];
    if (errorsToSend.length) {
      abortController();
      controller = new AbortController();

      try {
        // await API.post('/pm/public/js-errors', errorsToSend, {
        //   signal: controller.signal,
        // });
        errors = [];
      } catch (error) {
        console.error('Error sending logs:', error);
      }
    }
  };

  const checkIsErrorInExceptionList = (error: FrontendError) => {
    return CONSOLE_EXCLUDED_ERROR_FRAGMENTS.some((fragment) => error.message?.toString().includes(fragment));
  };

  const abortController = () => {
    if (controller) {
      controller.abort();
    }
  };

  const transformError = (type: FrontendErrorType, error: FrontendError): FrontendErrorRequest => {
    const keys = Object.getOwnPropertyNames(error);
    const errorMessage = [
      `type: ${type}`,
      ...getUserData(),
      ...keys.filter((key) => error[key]).map((key) => `${key}: ${error[key]?.toString()}`),
    ].join(' | ');

    return {
      id: generateId(),
      path: getErrorPath(),
      message: errorMessage,
    };
  };

  const shouldSendErrors = () => {
    return !hasCritical && !checkIsDevelopmentMode() && !location.hostname.includes('localhost');
  };

  const getUserData = (): string[] => {
    const user = store.getState().user.user;
    if (!user) return [];
    return [`userId: ${user.id || 'NULL'}`, `roles: ${user.roles || 'NULL'}`];
  };

  const getErrorPath = () => {
    return location.href;
  };

  const generateId = () => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const formattedDate = format(currentDate, 'dd-MM-yyyy');
    return `${formattedDate}/${timestamp}`;
  };

  return {
    catch: catchFn,
    send,
    checkIsErrorInExceptionList,
    transformError,
    shouldSendErrors,
    getUserData,
    getErrorPath,
    generateId,
  };
})();

export const ErrorLogging = ErrorLoggingService;
