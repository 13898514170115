import { useMemo } from 'react';

import { ApiEndpoint, DashboardEndpoint, FormType } from '@enums';
import { useFormFilterCriteria } from '@store';

export const useTimeAndDomainFilters = ({
  formType,
  endpoint,
}: {
  formType: FormType;
  endpoint?: ApiEndpoint | DashboardEndpoint;
}) => {
  const filterCriteria = useFormFilterCriteria({ formType, endpoint }).filter(
    (criterion) => criterion.from === endpoint,
  );
  return useMemo(
    () => ({
      timeFilter: filterCriteria.find((criterion) => criterion.label === 'interval')?.value ?? '15m',
      domainFilter: filterCriteria.find((criterion) => criterion.label === 'domain_id')?.value ?? 'all',
    }),
    [filterCriteria],
  );
};
