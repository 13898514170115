import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface CardProps {
  className?: string;
  onClick?: () => void;
  rounded?: string;
  withoutPadding?: boolean;
}

export const Cards = ({
  children,
  className = '',
  withoutPadding = false,
  rounded = 'rounded-md',
  onClick,
}: PropsWithChildren<CardProps>) => {
  const dynamicClasses = classNames('shadow-card bg-white bg-opacity-90', {
    'p-6': !withoutPadding,
    [rounded]: rounded,
    [className]: className,
  });

  return (
    <div className={dynamicClasses} onClick={onClick}>
      {children}
    </div>
  );
};
