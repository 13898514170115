import { StatusType } from '@enums';
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, TrashIcon } from '@heroicons/react/20/solid';
import { CheckBadgeIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

interface StatusConfig {
  icon: ({ className }: { className?: string }) => JSX.Element;
  title: string;
  background: { light: string; main: string };
  text: TextStyles;
  utilities: Utilities;
}

interface TextStyles {
  main: string;
  title: string;
  description: string;
}

interface Utilities {
  ring: string;
  border: string;
  buttonColor: string;
}

enum Color {
  LightBlue = 'bg-blue-50',
  MainBlue = 'fill-blue-400',
  LightGreen = 'bg-green-50',
  MainGreen = 'fill-green-400',
  LightYellow = 'bg-yellow-50',
  MainYellow = 'fill-yellow-400',
  LightRed = 'bg-red-50',
  MainRed = 'fill-red-400',
  LightViolet = 'bg-violet-50',
  MainViolet = 'fill-violet-400',
  LightGray = 'bg-gray-50',
  MainGray = 'fill-gray-400',
}

const defaultTextStyles: TextStyles = {
  main: 'text-blue-400',
  title: 'text-blue-800',
  description: 'text-blue-700 hover:text-blue-600',
};

const defaultUtilities: Utilities = {
  ring: 'ring-blue-400',
  border: 'border-blue-400',
  buttonColor: 'bg-blue-50 text-blue-800 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50',
};

const statusTypeMappings: Partial<Record<StatusType, StatusConfig>> = {
  [StatusType.Info]: {
    title: 'Info',
    icon: ({ className }) => (
      <InformationCircleIcon className={`${className ?? 'h-5 w-5'} text-blue-400`} aria-hidden="true" />
    ),
    background: { light: Color.LightBlue, main: Color.MainBlue },
    text: { ...defaultTextStyles },
    utilities: { ...defaultUtilities },
  },
  [StatusType.Success]: {
    title: 'Success',
    icon: ({ className }) => (
      <CheckCircleIcon className={`${className ?? 'h-5 w-5'} text-green-400`} aria-hidden="true" />
    ),
    background: { light: Color.LightGreen, main: Color.MainGreen },
    text: { ...defaultTextStyles, main: 'text-green-400', description: 'text-green-700 hover:text-green-600' },
    utilities: {
      ring: 'ring-green-400',
      border: 'border-green-400',
      buttonColor: 'bg-green-50 text-green-800 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50',
    },
  },
  [StatusType.Warning]: {
    title: 'Warning',
    icon: ({ className }) => (
      <ExclamationTriangleIcon className={`${className ?? 'h-5 w-5'} text-yellow-400`} aria-hidden="true" />
    ),
    background: { light: Color.LightYellow, main: Color.MainYellow },
    text: { ...defaultTextStyles, main: 'text-yellow-400', description: 'text-yellow-700 hover:text-yellow-600' },
    utilities: {
      ring: 'ring-yellow-400',
      border: 'border-yellow-400',
      buttonColor: 'bg-yellow-50 text-yellow-800 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50',
    },
  },
  [StatusType.Error]: {
    title: 'Error',
    icon: ({ className }) => (
      <ExclamationCircleIcon className={`${className ?? 'h-5 w-5'} text-red-400`} aria-hidden="true" />
    ),
    background: { light: Color.LightRed, main: Color.MainRed },
    text: { ...defaultTextStyles, main: 'text-red-400', description: 'text-red-700 hover:text-red-600' },
    utilities: {
      ring: 'ring-red-400',
      border: 'border-red-400',
      buttonColor: 'bg-red-50 text-red-800 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
    },
  },
  [StatusType.Delete]: {
    title: 'Delete',
    icon: ({ className }) => <TrashIcon className={`${className ?? 'h-5 w-5'} text-red-400`} aria-hidden="true" />,
    background: { light: Color.LightRed, main: Color.MainRed },
    text: { ...defaultTextStyles, main: 'text-red-400', description: 'text-red-700 hover:text-red-600' },
    utilities: {
      ring: 'ring-red-400',
      border: 'border-red-400',
      buttonColor: 'bg-red-50 text-red-800 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
    },
  },
  [StatusType.Inactive]: {
    title: 'Inactive',
    icon: ({ className }) => (
      <CheckBadgeIcon className={`${className ?? 'h-5 w-5'} text-gray-400`} aria-hidden="true" />
    ),
    background: { light: Color.LightGray, main: Color.MainGray },
    text: { ...defaultTextStyles, main: 'text-gray-400', description: 'text-gray-700 hover:text-gray-600' },
    utilities: {
      ring: 'ring-gray-400',
      border: 'border-gray-400',
      buttonColor: 'bg-gray-50 text-gray-800 hover:bg-gray-100 focus:ring-gray-600 focus:ring-offset-gray-50',
    },
  },
};

// Function to get color mapping for a given status type
export const getColorMapping = (statusType: StatusType) =>
  statusTypeMappings[statusType] || statusTypeMappings[StatusType.Info];
