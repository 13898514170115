export enum Translation {
  Common = 'common',
  ProfileSettings = 'profile_settings',
  SignIn = 'sign_in',
  Home = 'homepage',
  Dashboard = 'dashboard',
  Maintenance = 'maintenance',
  Errors = 'errors',
  Form = 'form',
  Cdn = 'cdn',
  Configuration = 'configuration',
  Validation = 'validation',
  Description = 'description',
}

export enum Dictionaries {
  Roles = 'ROLES',
  Languages = 'LANGUAGES',
  Operator = 'OPERATOR',
  Variable = 'VARIABLE',
  OriginType = 'ORIGIN_TYPE',
  HostType = 'HOST_TYPE',
  WAFAction = 'WAF_ACTION',
  WAFSensitivity = 'WAF_SENSITIVITY',
  WAFType = 'WAF_TYPE',
  WAFLanguage = 'WAF_LANGUAGE',
  SSLType = 'SSL_TYPE',
  Time = 'TIME',
  WAFRules = 'WAF_RULES',
  Regions = 'REGIONS',
  RouteAction = 'ROUTE_ACTION',
  ClientVariableType = 'CLIENT_VARIABLE_TYPE',
  RequestMethodVariableType = 'REQUEST_METHOD_VARIABLE_TYPE',
  BooleanVariableType = 'BOOLEAN_VARIABLE_TYPE',
  ClientCountryType = 'CLIENT_COUNTRY_TYPE',
  BrowserCacheOption = 'BROWSER_CACHE_OPTION',
  CachingLevelOption = 'CACHING_LEVEL_OPTION',
  LimitRequestRate = 'LIMIT_REQUEST_RATE',
  LimitRequestRateRejectionAction = 'LIMIT_REQUEST_RATE_REJECTION_ACTION',
  LimitResponseDataRateUnit = 'LIMIT_RESPONSE_DATA_RATE_UNIT',
  RedirectCurrent = 'REDIRECT_CURRENT',
  RedirectHost = 'REDIRECT_HOST',
  RedirectStatusCode = 'REDIRECT_STATUS_CODE',
  ResponseCookieValue = 'RESPONSE_COOKIE_VALUE',
  ResponseHeaderVariable = 'RESPONSE_HEADER_VARIABLE',
  ApiSpamLimitOption = 'API_SPAM_LIMIT_OPTION',
  CacheTTLOption = 'CACHE_TTL_OPTION',
  PageRulesRedirectOption = 'PAGE_RULES_REDIRECT_OPTION',
  DisplayMode = 'DISPLAY_MODE',
  UnderAttackMode = 'UNDER_ATTACK_MODE',
  DashboardFilter = 'DASHBOARD_FILTER',
  RequestRateUnit = 'REQUEST_RATE_UNIT',
  DashboardBlockAction = 'DASHBOARD_BLOCK_ACTION',
  CacheOverwriteHedaer = 'CACHE_OVERWRITE_HEADER',
}
