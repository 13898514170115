import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, Text } from '@components';
import { FormActionType, StatusType, Translation, VariantType } from '@enums';

const AnimatedWifi = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <motion.path
      d="M5 12.55a11 11 0 0 1 14.08 0"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      initial={{ pathLength: 0, opacity: 0.2 }}
      animate={{ pathLength: 1, opacity: 1 }}
      transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut', repeatType: 'reverse' }}
    />
    <motion.path
      d="M8.53 16.11a6 6 0 0 1 6.95 0"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      initial={{ pathLength: 0, opacity: 0.2 }}
      animate={{ pathLength: 1, opacity: 1 }}
      transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut', repeatType: 'reverse', delay: 0.2 }}
    />
    <motion.circle
      cx="12"
      cy="20"
      r="1"
      fill="currentColor"
      initial={{ scale: 0.5, opacity: 0.2 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut', repeatType: 'reverse', delay: 0.4 }}
    />
  </svg>
);

export const UnverifiedDomainsAnnouncement = ({
  cname,
  currentApplicationData,
  isDomainSubmitting,
  actionHandlers,
}) => {
  const { t: tTab } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.domain' });

  const unverifiedDomains = useMemo(() => {
    return cname && currentApplicationData.length > 0
      ? currentApplicationData?.filter((domain) => !domain.cname_verified)
      : [];
  }, [cname, currentApplicationData]);

  if (unverifiedDomains.length === 0) {
    return null;
  }

  const domainCount = unverifiedDomains.length;

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-gradient-to-r from-gray-50 to-gray-50 rounded-lg overflow-hidden shadow-lg border border-gray-100"
    >
      <div className="p-4 sm:p-5 flex flex-col sm:flex-row items-start sm:space-x-4">
        <div className="flex-shrink-0 mb-3 sm:mb-0 sm:-mt-1.5">
          <AnimatedWifi />
        </div>
        <div className="flex-grow w-full">
          <Text $level={4} className="font-semibold text-gray-700 mb-2 leading-6">
            {domainCount === 1
              ? tTab('domainCnameVerification', { count: domainCount })
              : tTab('domainsCnameVerification', { count: domainCount })}
          </Text>
          <Text $level={6} className="text-gray-600 mb-3 leading-5">
            {tTab('domainCnameVerificationDescription')}
          </Text>
          <div className="flex flex-wrap gap-2 sm:gap-4 mt-5">
            {unverifiedDomains.map((domain, index) => (
              <div key={index} className={'max-w-[12.5rem]'}>
                <Chip
                  smallFont
                  chipType={StatusType.Info}
                  onChipClick={() => actionHandlers[FormActionType.Verification].handler(domain)}
                  label={domain.domain}
                  chipVariant={VariantType.Outlined}
                  disabled={isDomainSubmitting}
                  disableHover={isDomainSubmitting}
                  withoutHover
                  textClassName={'w-full sm:w-auto truncate'}
                  withoutScale
                  className="mb-2 sm:mb-0"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
