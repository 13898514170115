import { Form, Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { Feature, Geometry } from 'geojson';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SearchInput } from '@components';
import { Dictionaries, Translation } from '@enums';
import { getCodeValueFromCatalog } from '@utils';

interface CountrySearchProps {
  searchResults: Feature<Geometry>[];
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  handleCountrySelect: (feature: Feature<Geometry>) => void;
  handleSearch: (search: string) => void;
  handleReset: () => void;
  formikRef: any;
}

const CountrySearch: React.FC<CountrySearchProps> = memo(
  ({ searchResults, showDropdown, setShowDropdown, handleCountrySelect, formikRef, handleSearch, handleReset }) => {
    const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.dashboard' });

    const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setShowDropdown(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [setShowDropdown]);

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
        const value = e.target.value;
        setFieldValue('search', value);
        handleSearch(value);
        setShowDropdown(true);
      },
      [handleSearch, setShowDropdown],
    );

    const handleCountryClick = useCallback(
      (feature: Feature<Geometry>) => {
        handleCountrySelect(feature);
        if (formikRef.current) {
          formikRef.current.setFieldValue('search', feature.properties?.ADMIN || '');
        }
        setShowDropdown(false);
      },
      [handleCountrySelect, formikRef, setShowDropdown],
    );

    const clearSearch = (resetForm: () => void) => {
      resetForm();
      handleSearch('');
      setShowDropdown(false);
      handleReset();
    };

    return (
      <Formik initialValues={{ search: '' }} onSubmit={() => {}} innerRef={formikRef}>
        {({ values, setFieldValue, resetForm }) => (
          <Form className="relative">
            <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
              <SearchInput
                name={'search'}
                className="bg-white bg-opacity-50 focus:bg-opacity-100 focus-within:bg-opacity-100"
                placeholder={tForm('enterRegionName')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, setFieldValue)}
                onFocus={() => setShowDropdown(true)}
                clearFunction={() => clearSearch(resetForm)}
              />
            </motion.div>
            <AnimatePresence>
              {showDropdown && searchResults.length > 0 && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  ref={dropdownRef}
                  className="absolute mt-1 w-full bg-white border rounded-md shadow-lg py-1.5 max-h-64 overflow-y-auto z-20"
                >
                  {searchResults.map((result, index) => (
                    <motion.div key={index} whileHover={{ backgroundColor: '#f3f4f6' }}>
                      <Button
                        customizeColor
                        className="flex items-center justify-between p-2 rounded cursor-pointer"
                        onClick={() => handleCountryClick(result)}
                        onMouseEnter={() => setHoveredCountry(result.properties?.ISO_A2 || '')}
                        onMouseLeave={() => setHoveredCountry(null)}
                        fullWidth
                      >
                        <span className="truncate">
                          {getCodeValueFromCatalog(Dictionaries.Regions, result.properties?.ISO_A2)}
                        </span>
                        {hoveredCountry === result.properties?.ISO_A2 ? (
                          <span className="text-gray-400">→</span>
                        ) : (
                          <span className="text-gray-400">{result.properties?.ISO_A2}</span>
                        )}
                      </Button>
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </Form>
        )}
      </Formik>
    );
  },
);

export default CountrySearch;
