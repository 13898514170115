import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '@hooks';
import { InitSliceState } from '../../types';

export const initInitialState: InitSliceState = {
  isLoading: true,
  error: null,
};

export const initSlice = createSlice({
  name: 'init',
  initialState: initInitialState,
  reducers: {
    appInit: () => {},
    appInitSuccess(state) {
      state.isLoading = false;
    },
    appInitError(state, { payload }: PayloadAction<any>) {
      state.error = payload;
      state.isLoading = false;
    },
    setAppLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
  },
});

export const { appInit, appInitSuccess, setAppLoading, appInitError } = initSlice.actions;

export const useInitLoading = () => useAppSelector((state: RootState) => state.init.isLoading);
export const useInitData = () => useAppSelector((state: RootState) => state);

const initReducer = initSlice.reducer;
export default initReducer;
