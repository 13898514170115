import { AnyAction, combineReducers, configureStore, Middleware, Reducer, Store } from '@reduxjs/toolkit';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { rootReducers } from './slices';

type AsyncReducers = { [key: string]: Reducer };
type RootReducer = typeof rootReducers;
type RootState = {
  [K in keyof RootReducer]: ReturnType<RootReducer[K]>;
} & {
  [K in keyof AsyncReducers]?: ReturnType<AsyncReducers[K]>;
};

export const reducers: RootReducer = rootReducers;

export const createRootReducer = (asyncReducers: AsyncReducers = {}): Reducer<RootState, AnyAction> => {
  return combineReducers({
    ...asyncReducers,
    ...reducers,
  }) as Reducer<RootState, AnyAction>;
};

interface StoreWithDynamicReducers extends Store<RootState, AnyAction> {
  asyncReducers: AsyncReducers;
  injectReducer: (key: keyof AsyncReducers, reducer: Reducer) => void;
}

const sagaMiddleware = createSagaMiddleware();

let store: StoreWithDynamicReducers;

const initStore = (): StoreWithDynamicReducers => {
  if (!store) {
    store = configureStore({
      reducer: createRootReducer(),
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
          thunk: false,
        }).concat(sagaMiddleware as Middleware),
      devTools: process.env.NODE_ENV !== 'production',
    }) as StoreWithDynamicReducers;

    store.asyncReducers = {};
    store.injectReducer = (key: keyof AsyncReducers, asyncReducer: Reducer) => {
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(createRootReducer(store.asyncReducers));
    };
  }
  return store;
};

export const getStore = (): StoreWithDynamicReducers => store || initStore();

store = initStore();

if (import.meta.env.MODE === 'development' && import.meta.hot) {
  import.meta.hot.accept(() => {
    const currentState = store.getState();
    store.replaceReducer(createRootReducer(store.asyncReducers));
    store.dispatch({ type: 'RESET_STATE', payload: currentState });
  });
}

export { sagaMiddleware, store };
export const runSaga = sagaMiddleware.run as <S extends Saga>(saga: S, ...args: Parameters<S>) => ReturnType<S>;
