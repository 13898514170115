import { Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SearchInput } from '@components';
import { Translation, VariantType } from '@enums';

interface RegionListProps {
  regions: { label: string; value: string }[];
  onToggleRegion: (regionCode: string) => void;
  placeholder: string;
  title: string;
  onMoveAll: () => void;
  name: string;
}

export const RegionList: React.FC<RegionListProps> = ({
  regions,
  onToggleRegion,
  placeholder,
  title,
  name,
  onMoveAll,
}) => {
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.security' });
  const { t: tModals } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.security' });

  const [hoveredRegion, setHoveredRegion] = useState<string | null>(null);

  return (
    <div className="w-full flex flex-col gap-3">
      <div className="flex justify-between items-center rounded-t-lg">
        <article className="font-medium text-sm">{title}</article>
        <Button
          variant={VariantType.Clean}
          customizeColor
          withoutPadding
          className="text-blue-500 hover:underline text-xs"
          onClick={onMoveAll}
        >
          {tButton('moveAll')}
        </Button>
      </div>
      <Formik initialValues={{ [name]: '' }} onSubmit={() => {}}>
        {({ values }) => {
          const filteredRegions = regions.filter((region) =>
            region.label.toLowerCase().includes(values[name].toLowerCase()),
          );
          return (
            <Fragment>
              <SearchInput name={name} placeholder={placeholder} />
              <div className="h-64 rounded-lg border overflow-y-auto py-2 padded-scrollbar-track">
                {filteredRegions.map((region) => (
                  <Button
                    key={region.value}
                    customizeColor
                    className="flex items-center justify-between p-2 hover:bg-gray-100 rounded cursor-pointer"
                    onClick={() => onToggleRegion(region.value)}
                    onMouseEnter={() => setHoveredRegion(region.value)}
                    onMouseLeave={() => setHoveredRegion(null)}
                    fullWidth
                  >
                    <span className="truncate">{region.label}</span>
                    {hoveredRegion === region.value ? (
                      <span className="text-gray-400">{title === tModals('availableRegions') ? '→' : '←'}</span>
                    ) : (
                      <span className="text-gray-400">{region.value}</span>
                    )}
                  </Button>
                ))}
              </div>
            </Fragment>
          );
        }}
      </Formik>
    </div>
  );
};
