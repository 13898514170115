export const createRequestTracker = () => {
  const pendingRequests = new Map<string, AbortController>();
  const RETRY_DELAYS = [1000, 2000, 5000];
  const MAX_RETRIES = 1;

  return {
    pendingRequests,
    RETRY_DELAYS,
    MAX_RETRIES,
  };
};

export const tracker = createRequestTracker();

export const cleanupRequestTracker = (requestKey?: string) => {
  if (requestKey) {
    tracker.pendingRequests.delete(requestKey);
  }
};
