export type StorageUtility<T> = {
  getItem: () => T | null;
  setItem: (data: T) => void;
  clearItem: () => void;
  exists: () => boolean;
};

export function createStorageUtility<T>(storageKey: string): StorageUtility<T> {
  const setItem = (data: T): void => {
    try {
      const serializedData = typeof data === 'string' ? data : JSON.stringify(data);
      localStorage.setItem(storageKey, serializedData);
    } catch (e) {
      console.error(`Error saving data for key '${storageKey}':`, e);
    }
  };

  const getItem = (): T | null => {
    try {
      const serializedData = localStorage.getItem(storageKey);
      if (serializedData === null) return null;

      try {
        return JSON.parse(serializedData) as T;
      } catch {
        return serializedData as unknown as T;
      }
    } catch (error) {
      console.error(`Error retrieving data for key '${storageKey}':`, error);
      return null;
    }
  };

  const clearItem = (): void => {
    try {
      localStorage.removeItem(storageKey);
    } catch (error) {
      console.error(`Error clearing data for key '${storageKey}':`, error);
    }
  };

  const exists = (): boolean => {
    return localStorage.getItem(storageKey) !== null;
  };

  return { getItem, setItem, clearItem, exists };
}
