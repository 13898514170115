import * as Headless from '@headlessui/react';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { colors } from '@components';
import { capitalize } from '@utils';

type AvatarProps = {
  src?: string | null;
  square?: boolean;
  initials?: string;
  alt?: string;
  className?: string;
  textAvatar?: boolean;
  withoutRing?: boolean;
  color?: string;
  bgColor?: any;
};

export function Avatar({
  src = null,
  square = false,
  withoutRing = false,
  initials,
  alt = '',
  className,
  textAvatar,
  color = 'blue',
  bgColor,
  ...props
}: AvatarProps & React.ComponentPropsWithoutRef<'span'>) {
  if (textAvatar)
    return (
      <Tippy content={alt} role="tooltip">
        <span
          data-slot="avatar"
          className={clsx(
            className,
            // Basic layout
            'inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1',

            // Add the correct border radius
            'rounded-full *:rounded-full',
            'w-8 h-8 flex items-center justify-center bg-opacity-100',
            withoutRing
              ? ''
              : 'outline outline-5 -outline-offset-1 outline-gray-50 dark:outline-white/[--ring-opacity]',
            colors[bgColor] ?? bgColor,
          )}
        >
          {capitalize(src)}
        </span>
      </Tippy>
    );

  return (
    <span
      data-slot="avatar"
      {...props}
      className={clsx(
        className,
        // Basic layout
        'inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1',
        'outline -outline-offset-1 outline-black/[--ring-opacity] dark:outline-white/[--ring-opacity]',
        withoutRing ? 'outline-0' : 'outline-1',
        // Border Radius
        square ? 'rounded-[--avatar-radius] *:rounded-[--avatar-radius]' : 'rounded-full *:rounded-full',
        'max-w-10',
      )}
    >
      {initials && (
        <svg
          className="size-full select-none fill-current p-[5%] text-[48px] font-medium uppercase"
          viewBox="0 0 100 100"
          aria-hidden={alt ? undefined : 'true'}
        >
          {alt && <title>{alt}</title>}
          <text x="50%" y="50%" alignmentBaseline="middle" dominantBaseline="middle" textAnchor="middle" dy=".125em">
            {initials}
          </text>
        </svg>
      )}
      {src && <img className="size-full" src={src} alt={alt} />}
    </span>
  );
}

export const AvatarButton = React.forwardRef(function AvatarButton(
  {
    src,
    square = false,
    initials,
    alt,
    className,
    textAvatar,
    ...props
  }: AvatarProps &
    (Omit<Headless.ButtonProps, 'className'> | Omit<React.ComponentPropsWithoutRef<typeof Link>, 'className'>),
  ref: React.ForwardedRef<HTMLElement>,
) {
  const classes = clsx(
    className,
    square ? 'rounded-[20%]' : 'rounded-full',
    'relative inline-grid focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500',
  );

  return 'to' in props ? (
    <Link {...props} className={classes} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
      <Avatar src={src} square={square} initials={initials} alt={alt} />
    </Link>
  ) : (
    <Headless.Button {...props} className={classes} ref={ref}>
      <Avatar src={src} square={square} initials={initials} alt={alt} />
    </Headless.Button>
  );
});
