import { motion } from 'framer-motion';
import { memo } from 'react';

import { VariantType } from '@enums';
import { Button } from './Button';

interface PaginationButtonProps {
  onClick: () => void;
  disabled: boolean;
  active?: boolean;
  children: React.ReactNode;
  ariaLabel?: string;
  'aria-current'?: 'page' | undefined;
  'data-testid'?: string;
  variants?: any;
  ANIMATION_DURATION?: number;
}

export const PaginationButton: React.FC<PaginationButtonProps> = memo(
  ({
    onClick,
    disabled,
    active,
    children,
    ariaLabel,
    variants,
    ANIMATION_DURATION = 0.2,
    'aria-current': ariaCurrent,
    'data-testid': testId,
  }) => (
    <motion.div
      variants={variants}
      whileHover="hover"
      whileTap="tap"
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: ANIMATION_DURATION }}
    >
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={VariantType.Transparent}
        rounded="rounded-full"
        withoutPadding
        customizeColor
        className={`
        ${active ? 'bg-theme-primary-main text-white text-opacity-100 opacity-100' : 'text-theme-neutral-text'}
        ${disabled ? 'cursor-not-allowed' : 'hover:bg-blue-50'}
        min-w-7 min-h-7 flex items-center justify-center
        transition-colors duration-200
      `}
        aria-label={ariaLabel}
        aria-current={ariaCurrent}
        data-testid={testId}
      >
        {children}
      </Button>
    </motion.div>
  ),
);
