import { FieldArray, useFormikContext } from 'formik';
import { FC, Fragment, useEffect, useMemo } from 'react';

import { FormField } from '@components';
import { ActionType, InputMod, columnDirection, redirect_currentCustom } from '@enums';
import { fieldActionInput } from '@types';
import { validateValueEqual } from '@utils';

export const ActionTableForm: FC<any> = ({ name, format, row, rowId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

  const actionFields = useMemo(() => fieldActionInput(row.field) ?? [], [row.field]);

  useEffect(() => {
    const allFields = fieldActionInput(values.previousActionKey);
    allFields.forEach((field) => {
      const fieldName = `${name}[${rowId}].${field.name}`;
      if (!actionFields.some((af) => af.name === field.name)) {
        setFieldTouched(fieldName, false);
      }
    });
  }, [actionFields, name, rowId, values.previousActionKey, setFieldTouched]);

  useEffect(() => {
    actionFields.forEach((actionField) => {
      const fieldName = `${name}[${rowId}].${actionField.name}`;
      setFieldValue(fieldName, actionField.value, false);
    });
  }, [actionFields, name, rowId, setFieldValue]);

  return (
    <FieldArray name={`${name}[${rowId}]`}>
      {({ name: fieldName }) =>
        actionFields.map((actionForm, actionFormId) => {
          const key = `${fieldName}_${actionFormId}`;
          const isCurrentCustomRedirect =
            row.field === ActionType.redirect &&
            [
              validateValueEqual(actionForm.name, 'uriValue') &&
                validateValueEqual(row.uri, redirect_currentCustom.Current),
              validateValueEqual(actionForm.name, 'customUriArguments') &&
                validateValueEqual(row.uriArguments, redirect_currentCustom.Current),
              validateValueEqual(actionForm.name, 'domain') &&
                validateValueEqual(row.host, redirect_currentCustom.Current),
            ].some((condition) => condition);

          if (isCurrentCustomRedirect) return null;

          return (
            <Fragment key={key}>
              <FormField
                inputType={actionForm.inputType}
                label={format ? format(actionForm.name) : actionForm.name}
                withoutLabel
                options={actionForm.option}
                format={actionForm.format}
                placeholder={actionForm.placeholder}
                name={`${fieldName}.${actionForm.name}`}
                fullWidth
                inputMod={InputMod.Filled}
                smallFont
                description={actionForm.description}
                type={actionForm.type}
                direction={columnDirection.Column}
              />
            </Fragment>
          );
        })
      }
    </FieldArray>
  );
};
