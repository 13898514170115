import { Text } from '@/components/Text';
import { AnimatePresence, motion } from 'framer-motion';

const ErrorIcon = ({ size = 28, color = '#FF6347', accentColor = '#FFFFFF' }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2L2 26H26L14 2Z" fill={color} stroke={color} strokeWidth="2" />
    <motion.path
      d="M14 11L14 17"
      stroke={accentColor}
      strokeWidth="2"
      strokeLinecap="round"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1, stroke: [accentColor, color, accentColor] }}
      transition={{ duration: 0.3, delay: 0.2, stroke: { duration: 2, repeat: Infinity } }}
    />
    <motion.circle
      cx="14"
      cy="21"
      r="1.5"
      fill={accentColor}
      initial={{ scale: 0 }}
      animate={{ scale: 1, fill: [accentColor, color, accentColor] }}
      transition={{ type: 'spring', stiffness: 500, delay: 0.5, fill: { duration: 2, repeat: Infinity } }}
    />
    <motion.path
      d="M9 7C10 5 12 6 14 7C16 8 18 7 19 5"
      stroke={accentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1, stroke: [accentColor, color, accentColor] }}
      transition={{ duration: 0.5, delay: 0.8, stroke: { duration: 2, repeat: Infinity } }}
    />
  </svg>
);

const ErrorMessage = ({ isError, errorClassName, meta }) => {
  if (!isError) return null;

  return (
    <AnimatePresence>
      {meta.error && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`overflow-hidden ${errorClassName}`}
        >
          <div className="flex items-start">
            <motion.div
              animate={{ rotate: [0, -5, 5, 0] }}
              transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}
              className="mr-2 flex-shrink-0"
            >
              <ErrorIcon size={18} />
            </motion.div>
            <Text $level={6} color="text-red-600" className="font-medium flex flex-col leading-4">
              {Array.isArray(meta.error) ? (
                meta.error.filter(Boolean).map((error, index) => <span key={index}>{error}</span>)
              ) : (
                <span className="whitespace-pre-line">{meta.error}</span>
              )}
            </Text>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ErrorMessage;
