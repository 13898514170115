import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { checkIdProperty, checkNameProperty, validateValueEqual } from '@utils';
import { PencilLineIcon, Trash2Icon } from 'lucide-react';
import { Fragment, memo, ReactNode, useMemo } from 'react';

import { Button, DraggableItem, Text } from '@components';
import { FormActionType, FormType, VariantType } from '@enums';
import { FormHandlers, useAppDispatch } from '@hooks';
import { updateFormProcedure, useFormProcedure } from '@store';

interface ActionTableCellProps<T> {
  multiLines?: boolean;
  data: T;
  formType: FormType;
  actionHandlers: FormHandlers<T>;
}

interface ActionConfig {
  icon: ReactNode;
  color: string;
  tooltip: string;
  condition: (data: any) => boolean;
}

type ActionConfigType = {
  [K in FormActionType.Edit | FormActionType.View | FormActionType.Delete]: ActionConfig;
};

const actionConfigs: ActionConfigType = {
  [FormActionType.Edit]: {
    icon: <PencilLineIcon className="w-4 h-4" />,
    color: 'text-blue-600',
    tooltip: 'Edit',
    condition: () => true,
  },
  [FormActionType.View]: {
    icon: <DocumentMagnifyingGlassIcon className="w-4 h-4" />,
    color: 'sky',
    tooltip: 'View',
    condition: () => true,
  },
  [FormActionType.Delete]: {
    icon: <Trash2Icon className="w-4 h-4" />,
    color: 'text-red-500',
    tooltip: 'Delete',
    condition: checkIdProperty,
  },
};

export const ActionTableCell = memo(
  <T extends DraggableItem>({ data, actionHandlers, formType, multiLines = false }: ActionTableCellProps<T>) => {
    const dispatch = useAppDispatch();
    const currentProcedure = useFormProcedure(formType);

    const renderActionButtons = useMemo(() => {
      return Object.entries(actionHandlers)
        .map(([type, { customize, handler, disabled = false }]: [any, any]) => {
          const config = actionConfigs[type as FormActionType];
          const handleMouseEnter = () => {
            if (currentProcedure !== FormActionType.Edit && currentProcedure !== FormActionType.Delete) {
              dispatch(updateFormProcedure({ formType, procedure: type }));
            }
          };

          if (validateValueEqual(type, FormActionType.Custom)) {
            return <Fragment key={type}>{customize.children}</Fragment>;
          }
          if (
            !config?.condition(data) ||
            ![FormActionType.Edit, FormActionType.View, FormActionType.Delete, FormActionType.Verification].includes(
              type as FormActionType,
            )
          ) {
            return null;
          }

          const baseText = data[formType] || (checkNameProperty(data) ? data.name : formType || '');
          return (
            <Button
              key={type}
              hoverEffect={!disabled}
              hint={
                <Text $level={4} color="text-white">
                  {disabled ? 'Function under development' : `${config.tooltip} ${baseText}`}
                </Text>
              }
              variant={VariantType.Icon}
              iconWithoutM
              onMouseEnter={handleMouseEnter}
              withoutPadding
              disabled={disabled}
              rounded="rounded-md"
              className={`bg-transparent text-opacity-80 ${disabled ? '' : config.color}`}
              onClick={() => handler?.(data)}
              icon={config.icon}
            />
          );
        })
        .filter(Boolean);
    }, [actionHandlers, data, formType]);

    if (!actionHandlers || Object.keys(actionHandlers).length === 0) {
      return null;
    }

    return (
      <div className="flex relative gap-x-3 h-full w-full ">
        <div className={`absolute z-20 flex space-x-2 ${multiLines ? '-top-1' : 'top-1/2 -translate-y-1/2'}`}>
          {renderActionButtons}
        </div>
      </div>
    );
  },
);
