import { FieldArray, useField } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormField, FormModal, ModalActions } from '@components';
import { FormType, InputType, Translation, VariantType, columnDirection } from '@enums';
import { useFormSubmissionStatus } from '@store';

interface CacheFileExtensionModalProps {
  name: string;
  open: boolean;
  onSubmit: any;
  formik: any;
  title: string;
  placeholder: string;
  description: string;
  formType: FormType;
  onClose: () => void;
}

export const CacheFileExtensionModal = ({
  name,
  open,
  title,
  placeholder,
  description,
  formik,
  onSubmit,
  formType,
  onClose,
}: CacheFileExtensionModalProps) => {
  const [field] = useField(name);
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.cache' });
  const { t: tValidation } = useTranslation(Translation.Validation, { keyPrefix: 'format' });

  const isSubmitting = useFormSubmissionStatus({ formType });
  const passData = useMemo(() => {
    if (Array.isArray(field.value)) {
      return field.value.filter((item) => item.trim() !== '');
    } else if (field.value && typeof field.value === 'string') {
      return field.value
        .split('\n')
        .map((item) => item.trim())
        .filter((item) => item !== '');
    } else {
      return [];
    }
  }, [field.value]);

  const handleChange = useCallback((event) => {
    const splitValue = event.target.value.split('\n').map((item) => item.trim());
    field.onChange(splitValue);
  }, []);

  const convertArrayData = useCallback(() => {
    if (Array.isArray(field.value)) {
      return field.value.map((item) => item.replace(/^\n/, '')).join('\n');
    } else field.value ?? '';
  }, [field.value]);

  if (!open && !name) return;

  return (
    <FormModal formType={formType} title={title} description={description} open={open} onClose={onClose}>
      <FieldArray name={name}>
        {() => (
          <FormField
            label={title}
            name={name}
            smallFont
            withoutLabel
            centerItem
            textInputClassName="min-h-40"
            direction={columnDirection.Column}
            inputType={InputType.Area}
            value={convertArrayData()}
            onChange={handleChange}
            placeholder={tValidation('block', {
              content: placeholder,
            })}
            fullWidth
            className="space-y-3"
          />
        )}
      </FieldArray>
      <ModalActions>
        <Button
          variant={VariantType.Outlined}
          className="rounded-md ring-theme-primary-main ring-opacity-30"
          disabled={isSubmitting}
          onClick={onClose}
        >
          {tButton('cancel')}
        </Button>
        <Button
          loading={isSubmitting}
          onClick={(e) => {
            onSubmit(passData, formik, name);
          }}
        >
          {tButton('configure')}
        </Button>
      </ModalActions>
    </FormModal>
  );
};
