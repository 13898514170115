import { domainValidationReg } from '@/regexp';
import { sslCertificateType } from '@enums';

import i18n from 'i18next';
import * as Yup from 'yup';

export const sslFormSchema = Yup.object().shape({
  name: Yup.string().required(() => i18n.t('validation:ssl.certificateNameRequired')),
  type: Yup.string().required(() => i18n.t('validation:ssl.sslTypeRequired')),
  public_key: Yup.string().when('type', (type, schema) => {
    return type?.[0] === sslCertificateType.manual
      ? schema.required(() => i18n.t('validation:ssl.publicKeyRequired'))
      : schema.notRequired();
  }),
  private_key: Yup.string().when('type', (type, schema) => {
    return type?.[0] === sslCertificateType.manual
      ? schema.required(() => i18n.t('validation:ssl.privateKeyRequired'))
      : schema.notRequired();
  }),
  domain: Yup.array().when('type', {
    is: 'auto',
    then: () =>
      Yup.array().of(
        Yup.string()
          .nullable()
          .matches(domainValidationReg, () => i18n.t('validation:format.domain'))
          .test(
            'domain-required',
            () => i18n.t('validation:ssl.atLeastOneDomainRequired'),
            function (value, context) {
              const { parent } = context;
              if (parent.indexOf(value) === 0 && parent.every((v) => !v)) {
                return false;
              }
              return true;
            },
          ),
      ),
    otherwise: () => Yup.array().of(Yup.string().nullable()),
  }),
});
