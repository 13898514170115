export enum DisplayMode {
  Light = 'light',
  Dark = 'dark',
}

export enum Language {
  English = 'en',
  Mandarin = 'cmn',
  Malay = 'ms',
  Tamil = 'ta',
  Chinese = 'ch',
  Other = 'other',
}
