import classNames from 'classnames';
import { useField } from 'formik';
import { InputHTMLAttributes, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from '../Buttons';
import ErrorMessage from './TextInput/errorMessage';

interface AreaInputProps extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'size'> {
  adornment?: string;
  name: string;
  className?: string;
  isCorrect?: boolean;
  isfilled?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  smallFont?: boolean;
  rows?: number;
  uploadLabel?: string;
  errorClassName?: string;
}

export const TextAreaInput: React.FC<AreaInputProps> = ({
  name,
  className = '',
  placeholder,
  isCorrect,
  fullWidth,
  value,
  disabled,
  uploadLabel,
  smallFont,
  rows = 4,
  errorClassName = '',
}: AreaInputProps) => {
  const [field, meta, helpers] = useField(name);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isError = meta.error && meta.touched;

  const dynamicClasses = classNames(
    'block w-full rounded-md border-0  placeholder-gray-400  ring-1 focus:ring-offset-2 sm:leading-6 padded-scrollbar-track',
    {
      'pr-20': isCorrect,
      'text-us': smallFont,
      'text-sm': !smallFont,
      'bg-theme-primary-light': disabled,
      'ring-theme-error-main bg-red-50 focus:ring-theme-error-main': isError,
      'ring-theme-border-default focus:ring-theme-icon-lightBlue': !isError,
      [className]: className,
    },
  );

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/plain') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e?.target?.result ?? '';
        helpers.setValue(text);
      };
      reader.readAsText(file);
    }
  };

  return (
    <motion.div
      className={`flex flex-col h-fit relative space-y-3 ${fullWidth ? 'w-full' : ''}`}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <textarea
        {...field}
        autoComplete="off"
        id={name}
        rows={rows}
        value={value ?? field.value ?? ''}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        aria-invalid={!!(meta.error && meta.touched)}
        aria-describedby={isError ? `${name}-error` : undefined}
        className={dynamicClasses}
      />
      {uploadLabel && (
        <div className="flex flex-col items-center pt-4">
          <Button smallFont fullWidth onClick={() => fileInputRef.current?.click()}>
            <article>{uploadLabel}</article>
          </Button>
          <input ref={fileInputRef} type="file" accept=".txt" onChange={handleFileUpload} className="hidden" />
        </div>
      )}
      <AnimatePresence>
        {isError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ErrorMessage isError={isError} errorClassName={errorClassName} meta={meta} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
