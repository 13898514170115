import { PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';

import { DEFAULT_INTERVAL_DURATION, DEFAULT_MAX_TICK_COUNT } from '@constants';
import { useAppDispatch } from '@hooks';
import { Event } from '@services';
import { systemDispatch } from '@store';

interface UseClearIntervalOptions {
  intervalDuration?: number;
  maxTickCount?: number;
  eventName?: string;
}

export const useClearInterval = ({
  intervalDuration = DEFAULT_INTERVAL_DURATION,
  maxTickCount = DEFAULT_MAX_TICK_COUNT,
  eventName = 'TIMER_TICK',
}: UseClearIntervalOptions = {}) => {
  const dispatch = useAppDispatch();

  const handleSystemDispatch = useCallback(
    (action: PayloadAction<any>) => {
      if (action?.type) {
        try {
          dispatch(action);
        } catch (error) {
          console.error('Dispatch failed:', error);
        }
      }
    },
    [dispatch],
  );

  useEffect(() => {
    try {
      Event.subscribe(systemDispatch.type, handleSystemDispatch);
    } catch (error) {
      console.error('Event subscription failed:', error);
    }

    let tickCount = 1;
    const timerId = setInterval(() => {
      try {
        Event.fire(eventName, tickCount);
      } catch (error) {
        console.error('Event firing failed:', error);
      }
      tickCount = tickCount === maxTickCount ? 1 : tickCount + 1;
    }, intervalDuration);

    return () => {
      try {
        Event.unsubscribe(systemDispatch.type, handleSystemDispatch);
      } catch (error) {
        console.error('Event unsubscription failed:', error);
      }
      clearInterval(timerId);
    };
  }, [handleSystemDispatch, intervalDuration, maxTickCount, eventName]);

  return null;
};
