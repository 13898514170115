import { useMemo } from 'react';

import { useCdnSummary } from '@store';

type CdnData = ReturnType<typeof useCdnSummary>;

interface BreadcrumbItem {
  id: string;
  name: string;
}

export const useBreadcrumbItems = (
  pathname: string,
  cdnData: CdnData,
  translate: (key: string) => string,
): BreadcrumbItem[] => {
  return useMemo(() => {
    const segments = pathname.split('/').filter(Boolean);

    return segments.reduce<BreadcrumbItem[]>((acc, segment, index) => {
      if (segment === 'configuration') {
        return acc;
      }

      let name: string;

      const isNumeric = /^\d+$/.test(segment);
      if (isNumeric && Number(cdnData?.id) === Number(segment)) {
        name = cdnData.name || segment;
      } else {
        const translatedKey = `navigator.${segment}`;
        const translatedName = translate(translatedKey);
        name = translatedName !== translatedKey ? translatedName : segment;
      }

      acc.push({ id: segment, name });
      return acc;
    }, []);
  }, [pathname, cdnData, translate]);
};
