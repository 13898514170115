import { FormikProps } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormHandlers } from '@/hooks';
import { ActionButtonGroup, ConfigurationTab, Responsive } from '@components';
import { FormActionType, FormType } from '@enums';
import { useFormLoadingStatus, useFormProcedure, useFormSubmissionStatus } from '@store';
import { validateValueEqual } from '@utils';
import { AppConfigurationForm } from './AppConfigurationForm';

export const AppConfiguration = () => {
  const formType = FormType.CdnApplicationConfiguration;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.appConfiguration' });
  const { t: tConfigurationButton } = useTranslation('configuration', { keyPrefix: 'buttons' });

  const tabs = [{ name: tTabs('appConfiguration'), key: 'appConfiguration' }];

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const formProcedure = useFormProcedure(formType);
  const formSubmitting = useFormSubmissionStatus({ formType });
  const formLoading = useFormLoadingStatus({ formType });

  useEffect(() => {
    if (formikRef.current?.errors && Object.keys(formikRef.current.errors).length > 0) {
      formikRef.current.validateForm();
    }
  }, [tTabs]);

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  const AppConfigurationFormHandler = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Save,
        function: handleSaveSettings,
        label: tConfigurationButton('saveSettings'),
        loading: formSubmitting,
        disabled: formLoading,
      },
    ],
  });

  return (
    <div className="flex flex-col flex-1 gap-6 sm:gap-8">
      <div className="flex flex-col sm:flex-row justify-between gap-4">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          <ActionButtonGroup actionHandlers={AppConfigurationFormHandler} procedure={formProcedure} />
        </Responsive>
      </div>
      {validateValueEqual(activeTab, 'appConfiguration') && (
        <AppConfigurationForm onFormikInstance={handleFormikInstance} />
      )}
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'appConfiguration') && (
          <ActionButtonGroup actionHandlers={AppConfigurationFormHandler} procedure={formProcedure} />
        )}
      </Responsive>
    </div>
  );
};
