import i18n from 'i18next';

import { Dictionaries } from '@enums';
import { DictionaryMapping, DictionaryOptionItem, FilteredOptionListProps } from '@types';

import * as CN_Translations from '@translations/cn';
import * as EN_Translations from '@translations/en';

const TRANSLATIONS = {
  en: EN_Translations,
  cn: CN_Translations,
} as const;

const translate = (key: string): string => {
  try {
    return i18n.t(key);
  } catch (error) {
    console.warn(`Translation failed for key: ${key}`);
    return key;
  }
};

const createDictionaryMappings = (): DictionaryMapping => {
  try {
    const currentLang = i18n.language.toLowerCase().split('-')[0];
    const translations = TRANSLATIONS[currentLang as keyof typeof TRANSLATIONS] || TRANSLATIONS.en;
    const langSuffix = currentLang === 'cn' ? 'CN' : 'EN';

    const entries = Object.values(Dictionaries).map((value): [string, Record<string, string>] => {
      const key = `${value}_${langSuffix}` as const;
      const translation = translations[key];

      if (process.env.NODE_ENV !== 'production' && !translation) {
        console.warn(`Missing translation for ${currentLang}: ${key}`);
      }

      return [value, translation ?? {}];
    });

    return Object.fromEntries(entries) as DictionaryMapping;
  } catch (error) {
    console.error('Failed to create dictionary mappings:', error);
    return {} as DictionaryMapping;
  }
};

let cachedMappings: Record<string, DictionaryMapping> = {};

export const getDictionaryMappings = (): DictionaryMapping => {
  const currentLang = i18n.language;

  if (!cachedMappings[currentLang]) {
    cachedMappings[currentLang] = Object.freeze(createDictionaryMappings());
  }

  return cachedMappings[currentLang];
};

export const isDictionaryKey = (key: string): key is keyof DictionaryMapping => {
  return key in getDictionaryMappings();
};

export const getOptionListFromCatalog = (
  name?: Dictionaries,
  disabledOptions: number[] = [],
): DictionaryOptionItem[] => {
  try {
    if (!name || !isDictionaryKey(name)) return [];

    const mappings = getDictionaryMappings()[name];
    if (!mappings) return [];

    return Object.entries(mappings).map(([code, label], index) => ({
      label: String(label ?? code),
      value: code,
      disabled: disabledOptions.includes(index),
    }));
  } catch {
    return [];
  }
};

export const getCodeValueFromCatalog = (key: Dictionaries, value: string): string => {
  if (!isDictionaryKey(key)) {
    return value;
  }
  return i18n.t(translate(`${key}:${value}`));
};

export const getfilteredOptionList = ({
  dictionaries,
  availableVariables,
}: FilteredOptionListProps): DictionaryOptionItem[] => {
  const options = getOptionListFromCatalog(dictionaries);
  return options?.filter((option) => availableVariables.includes(option.value)) ?? [];
};

export const clearDictionaryCache = () => {
  cachedMappings = {};
};
