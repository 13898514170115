import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

interface PasswordValidationitemProps {
  isMet: boolean;
  message: string;
}

export const PasswordValidationitem = ({ isMet, message }: PasswordValidationitemProps) => {
  return (
    <div
      className={`flex items-center rounded px-3 py-1.5 ${isMet ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}
    >
      {isMet ? <CheckCircleIcon className="h-5 w-5 mr-2" /> : <ExclamationCircleIcon className="h-5 w-5 mr-2" />}
      {message}
    </div>
  );
};
