import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, InformationModal } from '@components';
import { Translation } from '@enums';

import { ChangePasswordModal, ProfileSettingsForm } from './subLayer';

export const ProfileSettings = () => {
  const { t: tModals } = useTranslation(Translation.ProfileSettings, { keyPrefix: 'modals' });

  const [openVerifyDialog, setOpenVerifyDialog] = useState<boolean>(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);

  const handleVerifyDialog = () => {
    setOpenVerifyDialog(!openVerifyDialog);
  };
  const handleChangePassword = () => {
    setOpenChangePasswordModal(!openChangePasswordModal);
  };

  return (
    <div className="flex flex-1 flex-col gap-8">
      <Breadcrumbs />
      <div className="flex flex-col justify-between gap-5 sm:gap-8">
        <ProfileSettingsForm handleVerifyDialog={handleVerifyDialog} handleChangePassword={handleChangePassword} />
        <ChangePasswordModal open={openChangePasswordModal} onClose={handleChangePassword} />
        <InformationModal
          title={tModals('emailVerified')}
          topTitle
          content={
            <article className="text-xs leading-5 text-justify pt-1">{tModals('emailVerifiedDescription')}</article>
          }
          onClose={handleVerifyDialog}
          open={openVerifyDialog}
        />
      </div>
    </div>
  );
};
