export enum originType {
  HTTPS = 'https',
  HTTP = 'http',
}

export enum hostType {
  Ip = 'ip',
  Domain = 'domain',
}

export enum verificationType {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}

export enum WAFRulesType {
  scanner_detection = 'scanner_detection',
  protocol_enforcement = 'protocol_enforcement',
  protocol_attack = 'protocol_attack',
  application_attack_lfi = 'application_attack_lfi',
  application_attack_rfi = 'application_attack_rfi',
  application_attack_rce = 'application_attack_rce',
  application_attack_php = 'application_attack_php',
  application_attack_nodejs = 'application_attack_nodejs',
  application_attack_sqli = 'application_attack_sqli',
  application_attack_session_fixation = 'application_attack_session_fixation',
  application_attack_java = 'application_attack_java',
}

export enum WAFActionType {
  Javascript_Challenge = ' js-challenge',
  Log = 'log',
  Block = 'block',
  Close_Connection = 'close-connection',
}

export enum WAFApplicationType {
  Blog = 'blog',
  E_Commerce = 'e-commerce',
  Game = 'game',
}

export enum WAFApplicationLanguage {
  Python = 'python',
  PHP = 'php',
  Java = 'java',
  Node_JS = 'nodejs',
}

export enum WAFSensitivity {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum ActionType {
  redirect = 'redirect',
  delay = 'delay',
  limitRequestRate = 'limitRequestRate',
  limitRequestCount = 'limitRequestCount',
  blockRequest = 'blockRequest',
  setResponseHeader = 'setResponseHeader',
  addResponseHeader = 'addResponseHeader',
  setExpirationTime = 'setExpirationTime',
  setResponseCookie = 'setResponseCookie',
  limitResponseDataRate = 'limitResponseDataRate',
  enableHCaptcha = 'enableHCaptcha',
  setProxyHeader = 'setProxyHeader',
  setProxyHost = 'setProxyHost',
}

export enum ApiSpamLimit {
  Hundred_Request_Per_Minute = 'hundredRequestPerMinute',
  FIVE_Hundred_Request_Per_Minute = 'fiveHundredRequestPerMinute',
  Thousand_Request_Per_Minute = 'thousandRequestPerMinute',
}
