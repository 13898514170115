import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import { Form } from 'formik';
import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef, memo, MouseEvent, ReactNode, useCallback, useRef } from 'react';

import { useElementScrollLock } from '@hooks';
import { fontLevelClass, Text } from '../Text/text';
import debounce from 'lodash/debounce';

export const modalSizes = {
  xs: 'sm:max-w-xs',
  sm: 'sm:max-w-sm',
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '4xl': 'sm:max-w-4xl',
  '5xl': 'sm:max-w-5xl',
};

export const DialogModal = memo(
  ({
    open,
    onClose,
    size = 'lg',
    className,
    notForm = false,
    children,
    withoutPadding = false,
    ...props
  }: {
    size?: keyof typeof modalSizes;
    className?: string;
    children: ReactNode;
    withoutPadding?: boolean;
    notForm?: boolean;
    open: boolean;
    onClose: () => void;
  } & Omit<Headless.DialogProps, 'className'>) => {
    useElementScrollLock('mainContainer', open);
    const dialogRef = useRef<HTMLDivElement>(null);
    const startTimeRef = useRef(0);

    const handleBackdropClick = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (event.target !== event.currentTarget) return;

        const selection = window.getSelection();
        if (selection && selection.toString().length > 0) return;

        const clickDuration = Date.now() - startTimeRef.current;
        if (clickDuration > 200) return;

        onClose();
      },
      [onClose],
    );

    const handleMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        startTimeRef.current = Date.now();
      }
    }, []);
    const debouncedOnClose = useCallback(
      debounce((value) => {
        onClose(value);
      }, 100),
      [onClose],
    );
    return (
      <Headless.Dialog
        ref={dialogRef}
        open={open}
        onClose={(value) => {
          const isClickOutside = !dialogRef.current?.contains(document.activeElement);
          if (isClickOutside) {
            debouncedOnClose(value);
          }
        }}
        className="fixed inset-0 z-40 overflow-y-auto"
        {...props}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15, ease: 'easeOut' }}
          className="fixed inset-0 bg-zinc-950/25 dark:bg-zinc-950/50 backdrop-blur-[2px]  will-change-opacity"
        />

        <div className="fixed inset-0 w-screen overflow-y-auto padded-scrollbar-track pt-6 sm:pt-0">
          <motion.div
            className={clsx(
              'min-h-full flex justify-center sm:p-8',
              notForm ? 'px-6 pb-6 items-center' : 'items-end sm:items-center',
            )}
            initial={{ translateY: '100%' }}
            animate={{ translateY: '0%' }}
            exit={{ translateY: '100%' }}
            transition={{
              duration: 0.4,
              ease: [0.25, 0.1, 0.25, 1],
            }}
            onClick={handleBackdropClick}
            onMouseDown={handleMouseDown}
            style={{ pointerEvents: 'auto' }}
          >
            <div
              className={clsx(
                className,
                modalSizes[size],
                'relative row-start-2 w-full min-w-0',
                'bg-white dark:bg-zinc-900',
                'shadow-card ring-1 ring-zinc-950/10 dark:ring-white/10',
                'forced-colors:outline',
                notForm ? 'rounded-3xl' : 'rounded-t-3xl sm:rounded-3xl',
                withoutPadding ? '' : 'p-8 pt-10 sm:pt-8',
              )}
            >
              {children}
            </div>
          </motion.div>
        </div>
      </Headless.Dialog>
    );
  },
);

export function ModalTitle({
  className,
  level = 2,
  ...props
}: { className?: string; level?: number } & Omit<Headless.DialogTitleProps, 'className'>) {
  return (
    <Headless.DialogTitle
      {...props}
      className={clsx(
        className,
        fontLevelClass[level],
        'text-balance font-medium text-zinc-950 sm:text-base/6 dark:text-white leading-5',
      )}
    />
  );
}

export function ModalDescription({
  className,
  $level = 5,
  ...props
}: {
  className?: string;
  level?: number;
} & Omit<Headless.DescriptionProps<typeof Text>, 'className'>) {
  return (
    <Headless.Description
      as={Text}
      {...props}
      $level={$level}
      className={clsx(className, $level && fontLevelClass[$level], ' mt-4 sm:mt-3 text-pretty leading-4')}
    />
  );
}

type DivProps = ComponentPropsWithoutRef<'div'>;
type FormProps = ComponentPropsWithoutRef<'form'>;

interface ModalBodyProps extends DivProps {
  notForm?: boolean;
}

export function ModalBody({ className, notForm = false, ...props }: Readonly<ModalBodyProps>) {
  const commonClassName = clsx(className, 'gap-4 sm:gap-5 flex flex-col');

  if (notForm) {
    return <div {...props} className={commonClassName} />;
  }

  return <Form {...(props as FormProps)} className={commonClassName} />;
}

export function ModalActions({ className, ...props }: Readonly<ComponentPropsWithoutRef<'div'>>) {
  return (
    <div
      {...props}
      className={clsx(className, 'mt-3 flex flex-row items-center justify-end gap-7 *:w-full sm:flex-row w-auto')}
    />
  );
}
