import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ServiceModalName } from '@enums';
import { useAppDispatch } from '@hooks';
import { Event } from '@services';
import { fetchFormList, removeServiceModal, resetFormPagination, resetSubmitFormState } from '@store';
import type { FormPayload } from '@types';

interface UseFetchFormListProps extends FormPayload {
  isFormInit?: boolean;
  additionalDeps?: any[];
  additionalCondition?: boolean;
}

export const useFetchFormList = ({
  formType,
  params,
  globalOptions,
  additionalDeps,
  additionalCondition = true,
}: UseFetchFormListProps) => {
  const dispatch = useAppDispatch();
  const { key } = useLocation();

  useEffect(
    () => {
      const getFormList = dispatch(
        fetchFormList({
          formType,
          params,
          globalOptions,
        }),
      );
      additionalCondition && Event.subscribe(removeServiceModal(ServiceModalName.FormModal), getFormList);

      return () => Event.unsubscribe(removeServiceModal(ServiceModalName.FormModal), getFormList);
    },
    additionalDeps ?? [params?.application_id, key],
  );

  useEffect(() => {
    return () => {
      if (globalOptions?.pagination) {
        dispatch(resetFormPagination(formType));
      }
      dispatch(resetSubmitFormState(formType));
    };
  }, [formType]);
};
