import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getCdnOriginListColumns } from '@constants';
import { FormListLayout } from '@containers';
import { FormActionType, FormType, ServiceModalName, StatusType, Translation } from '@enums';
import { useAppDispatch, useFormHandlers } from '@hooks';
import { addServiceModal, deleteFormContent, useFormSubmissionStatus } from '@store';

import { OriginModal } from './originModal';

export const OriginForm = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnOrigin;

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.origin' });
  const { t: tButtons } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.origin' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });
  const { t: tOriginModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.origin' });
  const { t: tModals } = useTranslation(Translation.Configuration, { keyPrefix: 'modals' });
  const { t: tCommon } = useTranslation(Translation.Common);

  const [addOriginModal, setAddOriginModal] = useState<boolean>(false);

  const isOriginSubmitting = useFormSubmissionStatus({ formType });

  const cdnOriginTableColumns = useMemo(getCdnOriginListColumns, [tTables]);

  const originFormHandlers = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Create,
        function: () => setAddOriginModal(true),
        label: tButtons('addNewOrigin'),
      },
      {
        type: FormActionType.Edit,
        function: () => setAddOriginModal(true),
      },
      {
        type: FormActionType.Delete,
        function: (rowData) => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.ConfirmModal,
              payload: {
                label: {
                  title: tOriginModal('originDelete'),
                  text: tModals('areYouSureDelete', { domain: rowData?.name }),
                  confirmButton: tCommon('button.delete'),
                },
                option: {
                  modalType: StatusType.Delete,
                  button: {
                    loading: isOriginSubmitting,
                  },
                },
                callbackFunction: {
                  submit: () =>
                    dispatch(
                      deleteFormContent({
                        formType,
                        params: {
                          content_id: rowData?.id,
                          application_id: application_id,
                        },
                        globalOptions: {
                          refetch_id: application_id,
                        },
                      }),
                    ),
                },
              },
            }),
          );
        },
      },
    ],
  });

  return (
    <FormListLayout
      label={{
        title: tTabs('originList'),
      }}
      tableData={{
        columns: cdnOriginTableColumns,
        actionHandlers: originFormHandlers,
        hasChildren: true,
        formType,
      }}
    >
      <OriginModal open={addOriginModal} onClose={() => setAddOriginModal(false)} />
    </FormListLayout>
  );
};
