import { useEffect } from 'react';

import { ApiEndpoint, FormType } from '@enums';
import { resetSubmitFormState, useFormError, useFormSaveStatus, useFormSubmissionStatus } from '@store';
import { useAppDispatch } from '../storeHooks';

interface UseFormSubmissionProps {
  formType: FormType;
  endpoint?: ApiEndpoint;
  callbackFunction?: {
    close: () => void;
    additional?: () => void;
  };
}

export const useFormSubmission = ({
  formType,
  endpoint = ApiEndpoint.DEFAULT,
  callbackFunction,
}: UseFormSubmissionProps) => {
  const dispatch = useAppDispatch();

  const submitError = useFormError(formType);
  const isSubmitting = useFormSubmissionStatus({ formType, endpoint });
  const saveSuccess = useFormSaveStatus(formType);

  useEffect(() => {
    const isFormSubmitSuccess = !submitError && !isSubmitting && saveSuccess;

    if (isFormSubmitSuccess) {
      callbackFunction?.close();
      dispatch(resetSubmitFormState(formType));
      callbackFunction?.additional?.();
    }
  }, [submitError, isSubmitting, saveSuccess]);
};
