import { domainValidationReg, ipValidationReg } from '@/regexp';
import i18n from 'i18next';
import * as Yup from 'yup';

export const originFormSchema = Yup.object().shape({
  name: Yup.string().required(() => i18n.t('validation:origin.originRequired')),
  type: Yup.string().required(() => i18n.t('validation:origin.originTypeRequired')),
  origins: Yup.array()
    .when('origin_type', {
      is: 'ip',
      then: () =>
        Yup.array()
          .of(
            Yup.object().shape({
              origin: Yup.string()
                .matches(
                  ipValidationReg,
                  () => `${i18n.t('validation:origin.checkIpFormat')} (${i18n.t('validation:format.ip')})`,
                )
                .required(() => i18n.t('validation:origin.filledIpRequired')),
            }),
          )
          .min(1, () => i18n.t('validation:origin.minimumOneIp')),
    })
    .when('origin_type', {
      is: 'domain',
      then: () =>
        Yup.array()
          .of(
            Yup.object().shape({
              origin: Yup.string()
                .matches(
                  domainValidationReg,
                  () => `${i18n.t('validation:origin.checkDomainFormat')} (${i18n.t('validation:format.domain')})`,
                )
                .required(() => i18n.t('validation:origin.filledDomainRequired')),
            }),
          )
          .min(1, () => i18n.t('validation:origin.minimumOneDomain')),
    }),
  enable_proxy_host: Yup.boolean().oneOf([true, false]),
  proxy_host: Yup.string().when('enable_proxy_host', {
    is: true,
    then: () =>
      Yup.string()
        .required(() => i18n.t('validation:origin.filledHostHeaderRequired'))
        .matches(
          domainValidationReg,
          () => `${i18n.t('validation:origin.checkOverwriteHostHeaderFormat')} (${i18n.t('validation:format.domain')})`,
        ),
  }),
});
