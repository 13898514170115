type FormatType = 'string' | 'number' | 'json' | 'array';

export const validateValueEqual = <T>(data: T, valueToCheck: any, format?: FormatType): boolean => {
  if (data === undefined || valueToCheck === undefined) return false;

  const normalize = (value: any, format?: FormatType) => {
    switch (format) {
      case 'string':
        return String(value);
      case 'number':
        return Number(value);
      case 'json':
        return typeof value === 'string' ? JSON.parse(value) : value;
      default:
        return value;
    }
  };

  const normalizedData = normalize(data, format);
  const normalizedValue = normalize(valueToCheck, format);

  if (Array.isArray(normalizedData)) {
    return normalizedData.some((item) =>
      format ? normalize(item, format) === normalizedValue : item === normalizedValue,
    );
  }

  if (typeof normalizedData === 'object' && normalizedData !== null) {
    const values = Object.values(normalizedData);
    return values.some((val) => (format ? normalize(val, format) === normalizedValue : val === normalizedValue));
  }

  return normalizedData === normalizedValue;
};
