export enum InputType {
  Custom,
  Text,
  Area,
  Filled,
  IpInput,
  Switch,
  Table,
  Select,
  MultipleSelect,
  MultipleInput,
  MultipleSelectInput,
  Action,
  Checkbox,
  Radio,
}

export enum Direction {
  Left,
  Right,
  Up,
  Down,
  Outside,
  Default,
}

export enum InputMod {
  Filled,
  Default,
  Clean,
}

export enum VariantType {
  Contained,
  Outlined,
  Transparent,
  Icon,
  Clean,
  Normal,
  Custom,
}

export enum columnSpace {
  Half,
  TwoOverFive,
  ThreeOverFive,
}

export enum columnDirection {
  Row,
  Column,
  ColumnRow,
}

export enum InputTextType {
  Normal,
  Uppercase,
  Capitalize,
}
