import { dashboardGraphByteUnitConverter } from './dashboardGraphByteUnitConverter';

interface FormatYAxisValueParams {
  value: number;
  decimalPlaces?: number;
  useUnit?: boolean;
  allowFloating?: boolean;
}

const formatNumber = (value: number, decimalPlaces: number = 0): string => {
  if (value === 0) return value.toFixed(0);
  if (value < 0.01) return value.toFixed(3);
  if (value < 1) return value.toFixed(2);
  if (value < 10) return value.toFixed(1);
  return value.toFixed(decimalPlaces);
};
export const formatYAxisValue = ({
  value = 0,
  decimalPlaces = 0,
  useUnit = false,
  allowFloating = false,
}: FormatYAxisValueParams): string => {
  let formattedValue: string;
  let unit = '';
  if (useUnit) {
    const { value: convertedValue, unit: convertedUnit } = dashboardGraphByteUnitConverter(value);
    value = convertedValue;
    unit = convertedUnit;
  }
  if (allowFloating && decimalPlaces === 0) {
    formattedValue = formatNumber(value);
  } else {
    formattedValue = value.toFixed(decimalPlaces);
  }
  return unit ? `${formattedValue} ${unit}` : formattedValue;
};
