import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframe for the jump animation
const jump = keyframes`
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-200%);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translateY(0);
    animation-timing-function: ease-in;
  }
`;

// Keyframe for the morph animation
const morph = keyframes`
  0% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(1);
  }
  20%, 25% {
    transform: scaleY(0.6) scaleX(1.3);
    animation-timing-function: ease-in-out;
  }
  30% {
    transform: scaleY(1.15) scaleX(0.9);
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: scaleY(1);
  }
  70%, 85%, 100% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleY(0.8) scaleX(1.2);
  }
`;

// Container styled component
const Container = styled.div<{ size: number }>`
  --uib-size: ${(props) => `${props.size / 16}rem`}; /* Convert px to rem */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: calc(var(--uib-size) * 0.6);
`;

// Cube styled component
const Cube = styled.div<{ $delay?: number }>`
  /* Use $ prefix to make it a transient prop */
  flex-shrink: 0;
  width: 20%;
  height: 20%;
  animation: ${jump} var(--uib-speed, 1.75s) ease-in-out infinite;
  animation-delay: ${(props) => props.$delay || 0}s;
`;

// Inner cube styled component
const CubeInner = styled.div<{ $delay?: number; color: string }>`
  /* Use $ prefix to make it a transient prop */
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 25%;
  background-color: ${(props) => props.color};
  transform-origin: center bottom;
  animation: ${morph} var(--uib-speed, 1.75s) ease-in-out infinite;
  animation-delay: ${(props) => props.$delay || 0}s;
  transition: background-color 0.3s ease;
`;

interface CubeLoaderProps {
  loaderSize?: number;
  loaderColor?: string;
  className?: string;
}

export const CubeLoader: React.FC<CubeLoaderProps> = ({ loaderSize = 25, loaderColor = 'gray', className }) => {
  return (
    <Container size={loaderSize} className={className}>
      <Cube>
        <CubeInner color={loaderColor} />
      </Cube>
      <Cube $delay={-0.36}>
        <CubeInner color={loaderColor} $delay={-0.36} />
      </Cube>
      <Cube $delay={-0.2}>
        <CubeInner color={loaderColor} $delay={-0.2} />
      </Cube>
    </Container>
  );
};
