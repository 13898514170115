import i18n from 'i18next';
import * as Yup from 'yup';

export const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required(() => i18n.t('validation:changePassword.currentPasswordRequired')),
  password: Yup.string()
    .required(() => i18n.t('validation:changePassword.newPasswordRequired'))
    .min(8, () => i18n.t('validation:changePassword.passwordMinLength', { minLength: 8 }))
    .matches(/\d/, () => i18n.t('validation:changePassword.passwordNumberRequired'))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, () => i18n.t('validation:changePassword.passwordSpecialCharRequired'))
    .matches(/^\S*$/, () => i18n.t('validation:changePassword.passwordNoWhitespace')),
  confirm_password: Yup.string()
    .oneOf([Yup.ref<string>('password')], () => i18n.t('validation:changePassword.passwordsMustMatch'))
    .required(() => i18n.t('validation:changePassword.confirmPasswordRequired')),
});
