import i18n from 'i18next';

import { Column, Text } from '@components';
import { CellType } from '@enums';
import { getColumnSizes } from '@utils';

export const getCdnOriginListColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(50), multiLines: true },
  { label: i18n.t('configuration:tables.name'), value: 'name', ...getColumnSizes(140), multiLines: true },
  {
    label: i18n.t('configuration:tables.host'),
    value: 'origins',
    format: (value) => {
      return (
        <div className="flex flex-col">
          {value?.length > 0 ? (
            value?.map((data, index) => (
              <Text
                $level={4}
                color="text-black"
                className="text-sm font-normal select-text leading-6 ellipsis-text"
                key={index}
              >
                {data.origin ?? '—'}
              </Text>
            ))
          ) : (
            <Text $level={4} color="text-black" className="text-sm font-normal select-text leading-6 ellipsis-text">
              -
            </Text>
          )}
        </div>
      );
    },
    ...getColumnSizes(140),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    ...getColumnSizes(90),
    multiLines: true,
  },
];
