import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@components';
import { Translation, VariantType } from '@enums';

interface MapControllerProps {
  zoomLevel: number;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleReset: () => void;
}

const maximumZoom = 12;
const minimumZoom = 0.8;

const MapController: React.FC<MapControllerProps> = memo(({ zoomLevel, handleZoomIn, handleZoomOut, handleReset }) => {
  const { t: tCommon } = useTranslation(Translation.Common, { keyPrefix: 'button' });

  return (
    <div className="flex flex-row gap-3">
      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
        <Button
          className={`p-1.5 sm:p-2.5 w-fit h-fit ring-1 rounded-md shadow-md ${
            zoomLevel >= maximumZoom
              ? 'ring-slate-200 text-slate-400 bg-gray-50'
              : 'ring-theme-primary-main text-theme-primary-main bg-white'
          }`}
          variant={VariantType.Outlined}
          withoutPadding
          customizeColor
          onClick={handleZoomIn}
          disabled={zoomLevel >= maximumZoom}
        >
          <PlusIcon className="size-3 sm:size-4" />
        </Button>
      </motion.div>
      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
        <Button
          className={`p-1.5 sm:p-2.5 w-fit h-fit ring-1 rounded-md shadow-md ${
            zoomLevel <= minimumZoom
              ? 'ring-slate-200 text-slate-400 bg-gray-50'
              : 'ring-theme-primary-main text-theme-primary-main bg-white'
          }`}
          variant={VariantType.Outlined}
          withoutPadding
          customizeColor
          onClick={handleZoomOut}
          disabled={zoomLevel <= minimumZoom}
        >
          <MinusIcon className="size-3 sm:size-4" />
        </Button>
      </motion.div>
      {zoomLevel !== 1 && (
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Button
            className={`py-1.5 px-2 sm:py-2 sm:px-3 ring-1 rounded-md shadow-md h-full ${
              zoomLevel === 1
                ? 'ring-slate-200 text-slate-400 bg-gray-50'
                : 'ring-theme-primary-main text-theme-primary-main bg-white'
            }`}
            variant={VariantType.Outlined}
            disabled={zoomLevel === 1}
            customizeColor
            withoutPadding
            onClick={handleReset}
          >
            <Text $level={6}>{tCommon('reset')}</Text>
          </Button>
        </motion.div>
      )}
    </div>
  );
});

export default MapController;
