import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { ApiEndpoint, DashboardEndpoint, FormType, ServiceModalName, Translation } from '@enums';

import { HorizontalBarChart } from '@components';
import { useAppDispatch, useTimeAndDomainFilters } from '@hooks';
import { fetchDashboardGraph, removeServiceModal, resetDashboardFilter } from '@store';
import {
  AttackCountGraph,
  CardWrapper,
  CdnConfigurationDashboardCacheChart,
  CdnConfigurationDashboardQpsGraph,
  CdnConfigurationDashboardTotalTrafficGraph,
  GraphCardContent,
  MapGraph,
} from '../subComponents';

import { Event } from '@/services';

export const TrafficPage: FC = () => {
  const { id: application_id } = useParams();
  const { key } = useLocation();
  const dispatch = useAppDispatch();

  const { t: tModals } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.dashboard' });
  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.dashboard' });

  const formType = FormType.CdnDashboard;

  const { timeFilter, domainFilter } = useTimeAndDomainFilters({
    formType,
    endpoint: DashboardEndpoint.TOTAL_TRAFFIC,
  });

  useEffect(() => {
    if (application_id) {
      const getFormList = dispatch(
        fetchDashboardGraph({
          formType,
          params: { application_id },
          globalOptions: {
            filter: true,
            endpoint: [
              ApiEndpoint.DEFAULT,
              DashboardEndpoint.REQUEST_PER_IP,
              DashboardEndpoint.CACHE,
              DashboardEndpoint.TOTAL_TRAFFIC,
              DashboardEndpoint.ATTACK_COUNT,
              DashboardEndpoint.QPS,
              DashboardEndpoint.TOP_TEN_URL,
            ],
          },
        }),
      );
      Event.subscribe(removeServiceModal(ServiceModalName.ConfirmModal), getFormList);
      return () => Event.unsubscribe(removeServiceModal(ServiceModalName.ConfirmModal), getFormList);
    }
  }, [application_id, timeFilter, domainFilter, key]);

  useEffect(() => {
    return () => {
      dispatch(resetDashboardFilter(formType));
    };
  }, []);

  const cards: any[] = useMemo(
    () => [
      {
        payload: { formType },
        component: MapGraph,
      },
      {
        title: tModals('requestCountPerSecond'),
        payload: { formType, endpoint: DashboardEndpoint.QPS },
        component: CdnConfigurationDashboardQpsGraph,
      },
      {
        title: tTabs('cacheVsNocache'),
        payload: { formType, endpoint: DashboardEndpoint.CACHE },
        component: CdnConfigurationDashboardCacheChart,
      },
      {
        title: tTabs('totalTraffic'),
        payload: { formType, endpoint: DashboardEndpoint.TOTAL_TRAFFIC },
        component: CdnConfigurationDashboardTotalTrafficGraph,
      },
      {
        title: tTabs('attackCount'),
        payload: { formType, endpoint: DashboardEndpoint.ATTACK_COUNT },
        component: AttackCountGraph,
      },
      {
        title: tTabs('top10ClientUrlsByRequestCounts'),
        payload: { formType, endpoint: DashboardEndpoint.TOP_TEN_URL },
        component: HorizontalBarChart,
      },
      {
        title: tTabs('top10ClientIpsByRequestCounts'),
        payload: { formType, endpoint: DashboardEndpoint.REQUEST_PER_IP },
        component: HorizontalBarChart,
      },
    ],
    [tModals, tTabs],
  );

  return (
    <div className="flex flex-col flex-1 gap-8">
      <AnimatePresence>
        {cards.map((card, index) => (
          <CardWrapper key={index} delay={0.2 * (index + 1)}>
            <GraphCardContent title={card.title} hasData={true}>
              <card.component payload={card.payload} />
            </GraphCardContent>
          </CardWrapper>
        ))}
      </AnimatePresence>
    </div>
  );
};
