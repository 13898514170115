import classNames from 'classnames';
import { memo } from 'react';

import { Column, ColumnLayout } from './DraggableTable';

interface HeaderCellProps {
  column: Column;
  layout: ColumnLayout;
}

export const HeaderCell = memo(({ column, layout }: HeaderCellProps) => {
  const { label } = column;

  return (
    <div
      className={classNames('py-4 text-xs font-normal text-gray-400', {
        'text-center': column.center,
        'text-left': !column.center,
        'px-6': label === '#' || label === 'Order',
        'px-2.5': label !== '#' && label !== 'Order',
      })}
      style={{
        width: `${layout.percentage}%`,
        minWidth: layout.minWidth,
        flex: layout.flex,
      }}
    >
      {label}
    </div>
  );
});
