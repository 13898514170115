import { useMemo } from 'react';

import { ViewPermissions } from '@enums';
import { useUserPermissions } from '@store';

export const useRoutePermissions = () => {
  const permissions = useUserPermissions();

  const showRoute = useMemo(() => {
    const routePermissions = {
      login: true,
      permissions: true,
      dashboard: permissions.includes(ViewPermissions.ViewDashboard),
      homepage: permissions.includes(ViewPermissions.ViewHomepage),
      configuration: permissions.includes(ViewPermissions.ViewConfiguration),
      cdn: permissions.includes(ViewPermissions.ViewCdn),
      profileSettings: permissions.includes(ViewPermissions.ViewProfileSetings),
      notfound: false,
    };

    return routePermissions;
  }, [permissions]);

  return { showRoute };
};
