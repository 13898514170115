export const convertGraphDateTimeFormat = (filterValue: string) => {
  switch (filterValue) {
    case '15m':
    case '1h':
    case '4h':
    case '12h':
    case '1d':
      return 'HH:mm';
    case '7d':
    case '14d':
    case '30d':
      return 'dd MMM';
    default:
      return 'dd MMM';
  }
};
