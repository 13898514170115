import { useParams } from 'react-router-dom';

import { FormType } from '@enums';
import { useFetchFormList } from '@hooks';
import { useFormInit } from '@store';

import { Skeleton } from '@components';
import { OriginForm, RouteForm } from './subComponents';

export const Origins = () => {
  const { id: application_id } = useParams();

  const routeFormType = FormType.CdnRoute;
  const originFormType = FormType.CdnOrigin;

  const isRouteFormInit = useFormInit(routeFormType);
  const isOriginFormInit = useFormInit(originFormType);

  useFetchFormList({
    formType: originFormType,
    params: { application_id },
    globalOptions: {
      pagination: true,
    },
  });

  useFetchFormList({
    formType: routeFormType,
    params: { application_id },
    globalOptions: {
      pagination: true,
    },
  });

  if (isRouteFormInit || isOriginFormInit)
    return <Skeleton className="px-10 py-8 rounded-2xl shadow-card w-full h-full" />;

  return (
    <div className="flex flex-1 flex-col gap-8">
      <RouteForm />
      <OriginForm />
    </div>
  );
};
