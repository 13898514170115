import { ApexOptions } from 'apexcharts';
import { FC, memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { NoRecordFound } from '@components';
import { ApiEndpoint, DashboardEndpoint, FormType } from '@enums';
import { useChartDimensions, useTimeAndDomainFilters } from '@hooks';
import { useFormList } from '@store';
import { convertGraphDateTimeFormat, formatNumberWithSeparator, formatYAxisValue, validateValueEqual } from '@utils';

const DIMENSIONS = {
  mobile: { height: 250 },
  tablet: { height: 300 },
  desktop: { height: 450 },
  large: { height: 550 },
};

const BASE_CHART_OPTIONS = {
  chart: {
    type: 'line',
    zoom: { enabled: false },
    toolbar: { show: false },
    animations: { enabled: false },
  },
  stroke: { curve: 'smooth', width: 2 },
  dataLabels: { enabled: false },
  yaxis: {
    min: 0,
    labels: {
      formatter: (value: number) =>
        validateValueEqual(value, 0) ? '' : formatNumberWithSeparator(formatYAxisValue({ value })),
    },
  },
  tooltip: {
    x: { format: 'dd MMM yyyy HH:mm' },
    y: { formatter: (value: number) => formatNumberWithSeparator(value) },
  },
} as const;

interface ChartPayload {
  formType: FormType;
  endpoint: ApiEndpoint;
}

export const AttackCountGraph: FC<{ payload: ChartPayload }> = memo(({ payload }) => {
  const graphData = useFormList(payload);
  const { chartHeight } = useChartDimensions({ dimensionsData: DIMENSIONS });
  const { timeFilter } = useTimeAndDomainFilters({
    formType: payload.formType,
    endpoint: DashboardEndpoint.TOTAL_TRAFFIC,
  });

  const { seriesData, chartOptions } = useMemo(() => {
    const transformedData = graphData?.map(({ timestamp = 0, delay = 0 }) => [timestamp * 1000, delay]) || [];

    const options: ApexOptions = {
      ...BASE_CHART_OPTIONS,
      xaxis: {
        type: 'datetime',
        labels: {
          format: convertGraphDateTimeFormat(timeFilter),
          datetimeUTC: false,
        },
      },
    };

    return {
      seriesData: transformedData,
      chartOptions: options,
    };
  }, [graphData, timeFilter]);

  const series = useMemo(
    () => [
      {
        name: 'Attack count',
        data: seriesData,
      },
    ],
    [seriesData],
  );

  if (!timeFilter) return null;
  if (!seriesData.length) return <NoRecordFound />;

  return (
    <div className="mt-8">
      <ReactApexChart options={chartOptions} series={series} height={chartHeight} type="line" />
    </div>
  );
});
