export enum PageRulesAction {
  Redirect = 'redirect',
  Delay = 'delay',
  Limit_Request_Rate = 'limitRequestRate',
  Limit_Request_Count = 'limitRequestCount',
  Block_Request = 'blockRequest',
  Set_Response_Header = 'setResponseHeader',
  Add_Response_Header = 'addResponseHeader',
  Set_Expiration_Time = 'setExpirationTime',
  Set_RESPONSE_COOKIE = 'setResponseCookie',
  LIMIT_RESPONSE_DATA_RATE = 'limitResponseDataRate',
  ENABLE_H_CAPTCHA = 'enableHCaptcha',
  Set_PROXY_HEADER = 'setProxyHeader',
  Set_PROXY_HOST = 'setProxyHost',
  HTTPS_TO_HTTP = 'httpsToHttp',
  HTTP_TO_HTTPS = 'httpToHttps',
}

export enum redirect_currentCustom {
  Current = 'current',
  Custom = 'custom',
}

export enum redirect_hostCustom {
  HTTP = 'http',
  HTTPS = 'https',
  Current = 'current',
}

export enum redirect_statusCode {
  MovedTemporarily = '302',
  MovedPermanently = '301',
  TemporaryRedirect = '307',
  SeeOtherUri = '303',
}

export enum limitRequestRate_key {
  NoKey = 'noKey',
  ClientIpAddress = 'clientIpAddress',
  Uri = 'uri',
  UriArgument = 'uriArgument',
  RequestCookie = 'requestCookie',
  firstAddress = 'firstAddress',
  lastAddress = 'lastAddress',
  requestHeader = 'requestHeader',
}

export enum limitRequestRate_rejectAction {
  CloseConnection = 'closeConnection',
  ErrorPage = 'errorPage',
  EnableHCaptcha = 'enableHCaptcha',
  EnableOpenRestyEdgeBuiltInCaptcha = 'enableOpenRestyEdgeBuiltInCaptcha',
  RedirectValidate = 'redirectValidate',
  JavascriptChallenge = 'javascriptChallenge',
}

export enum responseHeader_headerVariable {
  String = 'string',
  BuiltInVariable = 'builtInVariable',
}

export enum responseCookie_headerValue {
  userId = 'userId',
}

export enum limitResponseDataRate_rateUnit {
  KbPerSecond = 'kbPerSecond',
  MbPerMinute = 'mbPerMinute',
}
