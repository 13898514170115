import classNames from 'classnames';
import { useMemo } from 'react';

interface SkeletonFormProps {
  customStyles?: boolean;
  className?: string;
  type?: SkeltonType;
}

export enum SkeltonType {
  Text,
  Avatar,
  Button,
}

export const Skeleton = ({
  customStyles = false,
  type = SkeltonType.Text,
  className = type === SkeltonType.Text ? 'w-1/3 h-3' : '',
}: SkeletonFormProps) => {
  const isButton = type === SkeltonType.Button;
  const isAvatar = type === SkeltonType.Avatar;
  const dynamicClasses = useMemo(
    () =>
      classNames('bg-gray-300 animate-pulse', {
        'rounded ': !customStyles,
        'h-8 bg-gray-300 rounded-2xl flex flex-1': isButton && !customStyles,
        'h-8 w-8 rounded-full': isAvatar && !customStyles,
        [className]: className,
      }),
    [className, customStyles, isAvatar, isButton],
  );

  return <div className={dynamicClasses} />;
};
