import type { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorBoundary } from '@components';
import { ROUTES } from '@constants';
import { NoPermissions } from '@containers';
import { useUserPermissions } from '@store';
import { validateValueEqual } from '@utils';

type MainLayoutProps = PropsWithChildren;

export const MainLayout = ({ children }: MainLayoutProps) => {
  const userPermissions = useUserPermissions();
  const { pathname } = useLocation();

  const isLoginRoute = validateValueEqual(pathname, ROUTES.login);

  return (
    <ErrorBoundary logErrors resetKey={pathname}>
      {!isLoginRoute || userPermissions.length ? children : <NoPermissions />}
    </ErrorBoundary>
  );
};
