import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Cards, DraggableTable, Text } from '@components';
import { cdnErrorLogTable } from '@constants';
import { DashboardEndpoint, FormType, Translation, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import { cancelAutoReload, fetchDashboardGraph, useFormLastUpdated, useFormList, useFormLoadingStatus } from '@store';
import { formatUnixTimestamp } from '@utils';

export const ErrorLog = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnDashboard;
  const [reloadLog, setReloadLog] = useState(true);

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.dashboard' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });

  const errorLogTableColumns = useMemo(cdnErrorLogTable, [tTables]);

  const lastUpdated = useFormLastUpdated(formType);
  const loggedData = useFormList({ formType, endpoint: DashboardEndpoint.ERROR_LOG });
  const isDataLoading = useFormLoadingStatus({ formType, endpoint: DashboardEndpoint.ERROR_LOG });

  const formattedLastUpdated = formatUnixTimestamp(lastUpdated);

  const handleAutoReload = useCallback(() => {
    setReloadLog((prev) => !prev);
    if (reloadLog) {
      dispatch(cancelAutoReload());
    }
  }, []);

  useEffect(() => {
    if (application_id && reloadLog) {
      dispatch(
        fetchDashboardGraph({
          formType,
          params: { application_id },
          globalOptions: {
            reload: true,
            endpoint: [DashboardEndpoint.ERROR_LOG],
          },
        }),
      );
    }
    return () => {
      dispatch(cancelAutoReload());
    };
  }, [application_id, reloadLog]);

  return (
    <Cards rounded="rounded-2xl px-8 py-7 space-y-6 max-w-full overflow-hidden" withoutPadding>
      <div className="flex justify-between">
        <article className="font-medium text-theme-primary-main">{tTabs('errorLogs')}</article>
        <div className="flex gap-5 items-center">
          <Text className="" color="text-gray-500" $level={6}>
            {tTabs('lastUpdated', { date: formattedLastUpdated })}
          </Text>
          <Button
            onClick={handleAutoReload}
            variant={VariantType.Icon}
            icon={
              <ArrowPathIcon
                className={`w-4 h-4 ${reloadLog ? 'animate-spin-gradual text-theme-primary-main' : 'text-zinc-300'}`}
              />
            }
          />
        </div>
      </div>
      <DraggableTable rows={loggedData} columns={errorLogTableColumns} isDataLoading={isDataLoading} />
    </Cards>
  );
};
