import * as CN_Translations from './cn';
import * as EN_Translations from './en';

import { Dictionaries, Translation } from '@enums';
import { COMMON_SYSTEM, ERRORS_SYSTEM, HOME_SYSTEM, LANGUAGES_SYSTEM, SIGN_IN_SYSTEM } from './system';

export const resources = {
  en: {
    ...Object.fromEntries(
      [...Object.values(Translation), ...Object.values(Dictionaries)].map((value) => {
        const translationKey = `${value.toUpperCase()}_EN` as keyof typeof EN_Translations;
        const translationValue = EN_Translations[translationKey];
        if (!translationValue) {
          console.warn(`Translation for ${translationKey} not found.`);
        }

        return [value, translationValue || {}];
      }),
    ),
  },
  cn: {
    ...Object.fromEntries(
      [...Object.values(Translation), ...Object.values(Dictionaries)].map((value) => {
        const translationKey = `${value.toUpperCase()}_CN` as keyof typeof CN_Translations;
        const translationValue = CN_Translations[translationKey];
        if (!translationValue) {
          console.warn(`Translation for ${translationKey} not found.`);
        }

        return [value, translationValue || {}];
      }),
    ),
  },
  system: {
    common: COMMON_SYSTEM,
    languages: LANGUAGES_SYSTEM,
    signIn: SIGN_IN_SYSTEM,
    home: HOME_SYSTEM,
    errors: ERRORS_SYSTEM,
  },
} as const;

export type TranslationResources = typeof resources;
