import i18n from 'i18next';

import { Column } from '@components';
import { CellType, ConditionOperatorType, ConditionVariableType, Dictionaries, InputType } from '@enums';
import { convertEnumToObjectArray, getColumnSizes, getOptionListFromCatalog } from '@utils';

export const getCustomWAFRulesColumns = (): Column[] => [
  { label: '', value: 'id', multiLines: true, ...getColumnSizes(100) },
  { label: i18n.t('configuration:tables.rulesName'), value: 'name', multiLines: true, ...getColumnSizes(150) },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    multiLines: true,
    ...getColumnSizes(100),
  },
];

export const getWAFConditionsColumns = (): any => [
  {
    label: i18n.t('configuration:tables.variable'),
    value: 'field',
    multiLines: true,
    inputType: InputType.Select,
    options: getOptionListFromCatalog(Dictionaries.Variable, [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14]),
  },
  {
    label: i18n.t('configuration:tables.operator'),
    value: 'operator',
    multiLines: true,
    inputType: InputType.Select,
    options: getOptionListFromCatalog(Dictionaries.Operator),
  },
  {
    label: i18n.t('configuration:tables.value'),
    value: 'value',
    multiLines: true,
    inputType: InputType.Text,
    placeholder: 'Enter Your Value',
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'action',
    multiLines: true,
    inputType: InputType.Action,
  },
];

export const getWAFRulesColumns = (): any => [
  {
    label: i18n.t('configuration:tables.variable'),
    value: 'field',
    multiLines: true,
    inputType: InputType.Select,
    options: [...convertEnumToObjectArray(ConditionVariableType)],
  },
  {
    label: i18n.t('configuration:tables.operator'),
    value: 'operator',
    multiLines: true,
    inputType: InputType.Select,
    options: [...convertEnumToObjectArray(ConditionOperatorType)],
  },
  {
    label: i18n.t('configuration:tables.value'),
    value: 'value',
    multiLines: true,
    inputType: InputType.Text,
    placeholder: 'Enter Your Value',
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'action',
    multiLines: true,
    inputType: InputType.Action,
  },
];
