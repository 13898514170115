import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '@hooks';

export const initialState: any = {
  init: true,
  loading: false,
  summary: {},
  error: null,
};

export const cdnSlice = createSlice({
  name: 'cdn',
  initialState,
  reducers: {
    getCdnSummary: (state, { payload }: PayloadAction<string | number | undefined>) => {
      state.loading = true;
    },
    getCdnSummarySuccess(state, { payload }: PayloadAction<any>) {
      state.init = false;
      state.loading = false;
      state.summary = payload;
    },
    getCdnSummaryError(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.error = payload;
    },
    resetCdnSummary: () => initialState,
  },
});

export const { getCdnSummary, getCdnSummarySuccess, getCdnSummaryError, resetCdnSummary } = cdnSlice.actions;

export const useCdnSummaryLoading = () => useAppSelector((state: RootState) => state.cdn.loading);
export const useCdnSummarInit = () => useAppSelector((state: RootState) => state.cdn.init);
export const useCdnSummary = () => useAppSelector((state: RootState) => state.cdn.summary);
export const useCdnSummaryError = () => useAppSelector((state: RootState) => state.cdn.error);

const cdnReducer = cdnSlice.reducer;
export default cdnReducer;
