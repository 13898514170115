export enum cacheLevelType {
  Standard = '1',
  Ignore_Query_String = '2',
}

export enum cacheTTLOptionType {
  Follow_Origin_Cache_Header = '0',
  Five_Minutes = '300',
  Thirty_Minutes = '1800',
  One_Hour = '3600',
  Four_Hours = '14400',
  One_Day = '86400',
  Five_Days = '432000',
  One_Week = '604800',
}

export enum browserCache {
  One_Day = '24',
  Four_Days = '96',
  Twelve_Days = '288',
  Twenty_Four_Days = '576',
  One_Week = '168',
  One_Month = '720',
  Respect_Existing_Headers = 'default',
}
