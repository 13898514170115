import { Cards, Skeleton, SkeltonType } from '@components';
import { Fragment } from 'react/jsx-runtime';

export const CdnSkeleton = () => {
  return (
    <Fragment>
      {Array.from({ length: 8 }, (_, index) => (
        <Cards key={index} rounded="rounded-xl" withoutPadding className="flex max-h-72 h-full w-full">
          <div className="flex flex-1 flex-col p-6 relative space-y-6 animate-pulse">
            <div className="flex justify-between">
              <div className="flex flex-col flex-1 space-y-2">
                <Skeleton />
                <Skeleton className="w-2/3 h-5" />
              </div>
              <Skeleton type={SkeltonType.Avatar} />
            </div>
            <Skeleton className=" w-2/3 h-4" />

            <div className="flex gap-x-3">
              <Skeleton type={SkeltonType.Button} />
              <Skeleton type={SkeltonType.Button} />
            </div>
          </div>
        </Cards>
      ))}
    </Fragment>
  );
};
