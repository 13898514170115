import { useParams } from 'react-router-dom';

import { Skeleton } from '@components';
import { FormType, originType, verificationType } from '@enums';
import { useFetchFormList } from '@hooks';
import { useFormInit } from '@store';
import { DomainForm } from './subComponents';

export interface DomainDataProps {
  id?: number;
  domain: string;
  origin?: any;
  type?: originType;
  name: string;
  status?: verificationType;
  connect_timeout?: number;
  send_timeout?: number;
  read_timeout?: number;
  verify_ssl_cert?: boolean;
}

export const Domain = () => {
  const { id: application_id } = useParams();

  const formType = FormType.CdnDomain;
  const isFormInit = useFormInit(formType);

  useFetchFormList({
    formType,
    params: { application_id },
    globalOptions: {
      pagination: true,
    },
  });

  if (isFormInit) return <Skeleton className="px-10 py-8 rounded-2xl shadow-card w-full h-full" />;
  return (
    <div className="flex flex-1">
      <DomainForm />
    </div>
  );
};
