import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Skeleton } from '@components';
import { FormType } from '@enums';
import { useFetchFormList } from '@hooks';
import { useFormInit } from '@store';

import { SSLForm } from './subComponents';

export const Ssl: FC = () => {
  const { id: application_id } = useParams();

  const formType = FormType.CdnSSL;
  const isFormInit = useFormInit(formType);

  useFetchFormList({
    formType,
    params: { application_id },
    globalOptions: {
      pagination: true,
    },
  });

  if (isFormInit) return <Skeleton className="px-10 py-8 rounded-2xl shadow-card w-full h-full" />;

  return (
    <div className="flex flex-1">
      <SSLForm />
    </div>
  );
};
