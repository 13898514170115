import classNames from 'classnames';

import { Button } from '@components';
import { VariantType } from '@enums';

export const ConfigurationTab = ({ arrayTab, currentTab, setCurrentTab }) => {
  return (
    <div className="bg-gray-200 bg-opacity-90 w-fit rounded-lg relative">
      <nav className="flex flex-1 h-full" aria-label="Tabs">
        {arrayTab.map((tab, index) => {
          const dynamicButtonClasses = classNames(
            'text-xs font-normal transition-all duration-300 ease-in-out focus:outline-0',
            {
              'hover:text-theme-primary-main text-gray-400': currentTab !== tab.key,
            },
          );
          return (
            <Button
              key={`${tab.key}.${index}`}
              onClick={() => setCurrentTab(tab.key)}
              className={dynamicButtonClasses}
              variant={currentTab === tab.key ? VariantType.Contained : VariantType.Transparent}
              aria-current={currentTab === tab.key ? 'page' : undefined}
              data-key={tab.key}
            >
              {tab.name}
            </Button>
          );
        })}
      </nav>
    </div>
  );
};
