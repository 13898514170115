import i18n from 'i18next';

import { Column } from '@components';
import { CellType } from '@enums';
import { convertOptionList, getColumnSizes } from '@utils';

export const CdnRouteListTable = (originOption): Column[] => [
  { label: i18n.t('configuration:tables.id'), multiLines: true, value: 'id', ...getColumnSizes(50) },
  { label: i18n.t('configuration:tables.rulesName'), value: 'name', multiLines: true, ...getColumnSizes(140) },
  {
    label: i18n.t('configuration:tables.origin'),
    value: 'origin_id',
    format: (value: string) => {
      const { getLabelByValue } = convertOptionList({
        originalOptions: originOption,
        defaultOptions: '',
        output: { value: 'id', label: 'name' },
      });
      return getLabelByValue(value);
    },
    multiLines: true,
    ...getColumnSizes(140),
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    multiLines: true,
    ...getColumnSizes(90),
  },
];

export const getCdnRoutePriorityColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'original_id', multiLines: true, ...getColumnSizes(40) },
  { label: i18n.t('configuration:tables.rulesName'), value: 'name', multiLines: true, ...getColumnSizes(100) },
];
