import { geoMercator, geoPath } from 'd3-geo';
import { scaleLinear } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import { zoom as d3Zoom, zoomIdentity } from 'd3-zoom';
import { FormikProps } from 'formik';
import { Feature, Geometry } from 'geojson';
import debounce from 'lodash/debounce';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { feature } from 'topojson-client';

import { Responsive, Skeleton } from '@components';
import { Dictionaries, Translation } from '@enums';
import { useChartDimensions } from '@hooks';
import { formatNumberWithSeparator, getCodeValueFromCatalog } from '@utils';

import CountrySearch from './components/countrySearch';
import MapController from './components/mapController';

import mapData from '@assets/network_threat_map_data.topo.json';

interface CountryData {
  country_code: string;
  count: number;
}

interface WorldMapProps {
  data: CountryData[];
  backgroundColor?: string;
  mapLoading: boolean;
}

interface MyFormValues {
  search: '';
}

interface StrokeWidthData {
  strokeWidth: number;
}

export const maximumZoom = 12;
const minimumZoom = 0.8;

const WorldMap: React.FC<WorldMapProps> = ({ data, backgroundColor = '#f0f0f0', mapLoading }) => {
  const { t: tConfiguration } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.dashboard' });

  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const gRef = useRef<SVGGElement | null>(null);
  const formikRef = useRef<FormikProps<MyFormValues>>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const zoomBehavior = useRef<ReturnType<typeof d3Zoom<SVGSVGElement, unknown>> | null>(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Feature<Geometry>[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const countryDataMap = useMemo(() => new Map(data.map((item) => [item.country_code.toUpperCase(), item])), [data]);

  const colorScale = useMemo(() => {
    const maxCount = Math.max(...data.map((d) => d.count));
    return scaleLinear<string>().domain([0, maxCount]).range(['#d1e7ff', '#0056b3']);
  }, [data]);

  const updateDimensions = useCallback(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      const height = width * 0.6;
      setDimensions({ width, height });
    }
  }, []);

  const debouncedUpdateDimensions = useMemo(() => debounce(updateDimensions, 250), [updateDimensions]);

  const dimensionData = {
    mobile: { height: 250, strokeWidth: 0.1 },
    tablet: { height: 300, strokeWidth: 0.2 },
    desktop: { height: 450, strokeWidth: 0.3 },
    large: { height: 550, strokeWidth: 0.5 },
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', debouncedUpdateDimensions);
    return () => window.removeEventListener('resize', debouncedUpdateDimensions);
  }, [updateDimensions, debouncedUpdateDimensions]);

  const { strokeWidth } = useChartDimensions<StrokeWidthData>({ dimensionsData: dimensionData });

  const topology = useMemo(() => feature(mapData, mapData.objects.countries), []);

  const countryColors = useMemo(() => {
    const colors = new Map();
    topology.features.forEach((feature: any) => {
      const countryCode = feature.properties?.ISO_A2;
      const countryData = countryDataMap.get(countryCode);
      colors.set(countryCode, countryData ? colorScale(countryData.count) : backgroundColor);
    });
    return colors;
  }, [topology.features, countryDataMap, colorScale, backgroundColor]);

  const projection = useMemo(() => {
    if (dimensions.width === 0) return null;
    return geoMercator().fitSize([dimensions.width, dimensions.height], topology);
  }, [dimensions, topology]);

  const pathGenerator = useMemo(() => {
    if (!projection) return null;
    return geoPath().projection(projection);
  }, [projection]);

  const handleCountrySelect = useCallback(
    (feature: Feature<Geometry>) => {
      if (!svgRef.current || !zoomBehavior.current || !pathGenerator) return;

      const [[x0, y0], [x1, y1]] = pathGenerator.bounds(feature);
      const dx = x1 - x0;
      const dy = y1 - y0;
      const x = (x0 + x1) / 2;
      const y = (y0 + y1) / 2;
      const scale = Math.max(
        minimumZoom,
        Math.min(maximumZoom, 0.9 / Math.max(dx / dimensions.width, dy / dimensions.height)),
      );
      const translate = [dimensions.width / 2 - scale * x, dimensions.height / 2 - scale * y];

      if (translate[0] && translate[1]) {
        select(svgRef.current)
          .transition()
          .duration(750)
          .call(zoomBehavior.current.transform, zoomIdentity.translate(translate[0], translate[1]).scale(scale));

        setZoomLevel(scale);
        setShowDropdown(false);
        setSearchResults([]);
      }
    },
    [dimensions, pathGenerator],
  );

  const createZoomBehavior = useCallback(() => {
    if (!projection || !pathGenerator || dimensions.width === 0 || !topology) return null;

    const bounds = pathGenerator.bounds(topology);
    const dx = bounds[1][0] - bounds[0][0];
    const dy = bounds[1][1] - bounds[0][1];
    const x = (bounds[0][0] + bounds[1][0]) / 2;
    const y = (bounds[0][1] + bounds[1][1]) / 2;

    const maxTranslateX = dx / 4;
    const maxTranslateY = dy / 4;

    return d3Zoom<SVGSVGElement, unknown>()
      .scaleExtent([1, 4])
      .translateExtent([
        [x - dx / 2 - maxTranslateX, y - dy / 2 - maxTranslateY],
        [x + dx / 2 + maxTranslateX, y + dy / 2 + maxTranslateY],
      ])
      .extent([
        [0, 0],
        [dimensions.width, dimensions.height],
      ]);
  }, [projection, pathGenerator, dimensions, topology]);

  const positionTooltip = useCallback(
    (event: MouseEvent, d: any) => {
      if (tooltipRef.current && svgRef.current) {
        const [x, y] = pointer(event, svgRef.current);
        const tooltipWidth = tooltipRef.current.offsetWidth;
        const tooltipHeight = tooltipRef.current.offsetHeight;
        const padding = 10;

        let tooltipX = x + 10;
        let tooltipY = y - tooltipHeight - 10;

        if (tooltipX + tooltipWidth > dimensions.width - padding) {
          tooltipX = x - tooltipWidth - 10;
        }

        if (tooltipY < padding) {
          tooltipY = y + 10;
        }

        if (tooltipX < padding) {
          tooltipX = padding;
        }

        tooltipRef.current.style.left = `${tooltipX}px`;
        tooltipRef.current.style.top = `${tooltipY}px`;
        const countryData = countryDataMap.get(d.properties?.ISO_A2);
        tooltipRef.current.textContent = ` ${getCodeValueFromCatalog(Dictionaries.Regions, d.properties?.ISO_A2)}: ${formatNumberWithSeparator(countryData?.count ?? 0)} ${tConfiguration('visitors')} `;
        tooltipRef.current.style.display = 'block';
      }
    },
    [dimensions, countryDataMap, tConfiguration],
  );

  useEffect(() => {
    if (!svgRef.current || !projection || !pathGenerator || dimensions.width === 0) return;

    setIsLoading(true);
    const svg = select(svgRef.current);
    const g = svg.append('g');
    gRef.current = g.node();

    const paths = g
      .selectAll('path')
      .data(topology.features)
      .join('path')
      .attr('d', (d: any) => pathGenerator(d) ?? '')
      .attr('stroke', '#fff')
      .attr('stroke-width', `${strokeWidth}px`)
      .attr('fill', (d: any) => countryColors.get(d.properties?.ISO_A2) || backgroundColor)
      .attr('class', 'country cursor-pointer transition-colors duration-200 hover:brightness-90');

    if (!zoomBehavior.current) {
      zoomBehavior.current = createZoomBehavior();
    }

    if (zoomBehavior.current) {
      zoomBehavior.current.on('zoom', (event) => {
        const { k, x, y } = event.transform;
        if (!isNaN(k) && !isNaN(x) && !isNaN(y)) {
          g.attr('transform', `translate(${x},${y}) scale(${k})`);
          setZoomLevel(k);
        }
      });

      const bounds = pathGenerator.bounds(topology);
      const dx = bounds[1][0] - bounds[0][0];
      const dy = bounds[1][1] - bounds[0][1];
      const x = (bounds[0][0] + bounds[1][0]) / 2;
      const y = (bounds[0][1] + bounds[1][1]) / 2;
      const scale = Math.max(1, Math.min(4, 0.9 / Math.max(dx / dimensions.width, dy / dimensions.height)));

      svg.call(zoomBehavior.current).call(
        zoomBehavior.current.transform,
        zoomIdentity
          .translate(dimensions.width / 2, dimensions.height / 2)
          .scale(scale)
          .translate(-x, -y),
      );

      svg.on('wheel.zoom', null);
    }

    paths
      .on('mousemove', (event, d: any) => {
        positionTooltip(event, d);
      })
      .on('mouseout', () => {
        if (tooltipRef.current) {
          tooltipRef.current.style.display = 'none';
        }
      });

    setIsLoading(false);
    return () => {
      paths.on('mousemove', null).on('mouseout', null);
      if (zoomBehavior.current) {
        svg.on('.zoom', null);
      }
      svg.selectAll('*').remove();
    };
  }, [positionTooltip]);

  useEffect(() => {
    if (!gRef.current) return;

    const paths = select(gRef.current).selectAll('path');

    paths
      .transition()
      .duration(200)
      .attr('fill', (d: any) => countryColors.get(d.properties?.ISO_A2) || backgroundColor);
  }, [countryColors, backgroundColor]);

  const handleZoomIn = useCallback(() => {
    if (svgRef.current && zoomBehavior.current) {
      select(svgRef.current).transition().call(zoomBehavior.current.scaleBy, 1.2);
    }
  }, []);

  const handleZoomOut = useCallback(() => {
    if (svgRef.current && zoomBehavior.current) {
      select(svgRef.current)
        .transition()
        .call(zoomBehavior.current.scaleBy, 1 / 1.2);
    }
  }, []);

  const handleReset = useCallback(() => {
    if (svgRef.current && zoomBehavior.current) {
      select(svgRef.current)
        .transition()
        .call(zoomBehavior.current.transform, zoomIdentity)
        .on('end', () => {
          setZoomLevel(1);
          setShowDropdown(false);
          setSearchResults([]);
          if (formikRef.current) {
            formikRef.current.resetForm();
          }
        });
    }
  }, []);

  const handleSearch = useCallback(
    (search: string) => {
      if (search) {
        const matchingFeatures = topology.features.filter((f) => {
          const countryCode = f.properties?.ISO_A2;
          if (!countryCode) return false;
          const countryName = getCodeValueFromCatalog(Dictionaries.Regions, countryCode);
          return countryName?.toLowerCase().includes(search.toLowerCase());
        });
        setSearchResults(matchingFeatures);
        setShowDropdown(matchingFeatures.length > 0);
      } else {
        setSearchResults([]);
        setShowDropdown(false);
      }
    },
    [topology.features],
  );

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  return (
    <>
      <Responsive showBelow="sm">
        <CountrySearch
          searchResults={searchResults}
          formikRef={formikRef}
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
          handleCountrySelect={handleCountrySelect}
          handleSearch={debouncedHandleSearch}
          handleReset={handleReset}
        />
      </Responsive>
      <div className="relative pb-[60%]" ref={containerRef}>
        <div className="absolute inset-0 shadow-card rounded-2xl overflow-hidden">
          {mapLoading || isLoading ? (
            <Skeleton className="absolute inset-0 flex items-center justify-center z-10" />
          ) : (
            <>
              <svg
                ref={svgRef}
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid meet"
                className="w-full h-full"
              />
              <div
                ref={tooltipRef}
                className="absolute pointer-events-none bg-black text-white px-2 py-1 rounded text-sm"
                style={{ display: 'none' }}
              />
              <div className="absolute sm:top-6 sm:left-6 top-4 left-4 flex flex-row gap-3 sm:gap-4">
                <MapController
                  zoomLevel={zoomLevel}
                  handleZoomIn={handleZoomIn}
                  handleZoomOut={handleZoomOut}
                  handleReset={handleReset}
                />
              </div>
              <Responsive showAbove="sm">
                <div className="absolute top-6 right-6 w-full max-w-64">
                  <CountrySearch
                    searchResults={searchResults}
                    formikRef={formikRef}
                    setShowDropdown={setShowDropdown}
                    showDropdown={showDropdown}
                    handleCountrySelect={handleCountrySelect}
                    handleSearch={debouncedHandleSearch}
                    handleReset={handleReset}
                  />
                </div>
              </Responsive>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(WorldMap, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.backgroundColor === nextProps.backgroundColor &&
    prevProps.mapLoading === nextProps.mapLoading
  );
});
