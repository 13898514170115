import { motion } from 'framer-motion';
import React from 'react';

import { Cards, NoRecordFound, Text } from '@components';

interface AnimatedCardProps {
  children: React.ReactNode;
  delay?: number;
  className?: string;
  card?: boolean;
}

export const AnimatedCard: React.FC<AnimatedCardProps> = ({ children, delay = 0, className = '', card = false }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    className="w-full h-full"
  >
    {card ? (
      <Cards rounded={'rounded-2xl p-8 pt-7 gap-6 flex flex-col'} withoutPadding className={className}>
        {children}
      </Cards>
    ) : (
      children
    )}
  </motion.div>
);

interface GraphCardProps {
  children: React.ReactNode;
  title?: string;
}

export const GraphCard: React.FC<GraphCardProps> = ({ children, title }) => (
  <Cards
    rounded="rounded-2xl px-6 pb-6 pt-7 flex flex-col h-full hover:shadow-lg transition-shadow duration-300"
    withoutPadding
  >
    {!!title && (
      <Text $level={4} className="font-semibold text-center">
        {title}
      </Text>
    )}

    <div className="flex-grow">{children}</div>
  </Cards>
);

interface GraphCardContentProps {
  title?: string;
  hasData: boolean;
  children: React.ReactNode;
}

export const GraphCardContent: React.FC<GraphCardContentProps> = ({ title, hasData, children }) => (
  <GraphCard title={title}>{hasData ? children : <NoRecordFound />}</GraphCard>
);

interface CardWrapperProps {
  children: React.ReactNode;
  delay: number;
  className?: string;
  card?: boolean;
}

export const CardWrapper: React.FC<CardWrapperProps> = React.memo(
  ({ children, delay, className = '', card = false }) => (
    <AnimatedCard delay={delay}>
      {card ? (
        <Cards rounded={'rounded-2xl p-8 pt-7 gap-6 flex flex-col'} withoutPadding className={className}>
          {children}
        </Cards>
      ) : (
        children
      )}
    </AnimatedCard>
  ),
);
