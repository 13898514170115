import { addHours, differenceInSeconds, format, isAfter, parseISO } from 'date-fns';
import { AlertTriangle, CheckCircle, Clock, Cpu, Database, Server, Wifi, XCircle } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { getAllStorageData } from '@/storage';
import { Button } from '@components';
import { useAppDispatch } from '@hooks';
import { updateToken, useApiStatus, useRedirectPath } from '@store';

const float = (x, y, rotate) => keyframes`
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(${x}px, ${y}px) rotate(${rotate}deg); }
`;

const glow = keyframes`
  0%, 100% { text-shadow: 0 0 10px rgba(59, 130, 246, 0.5), 0 0 20px rgba(59, 130, 246, 0.3), 0 0 30px rgba(59, 130, 246, 0.2); }
  50% { text-shadow: 0 0 20px rgba(59, 130, 246, 0.8), 0 0 30px rgba(59, 130, 246, 0.5), 0 0 40px rgba(59, 130, 246, 0.3); }
`;

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom right, #1e3a8a, #3730a3, #6b21a8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;
  position: relative;
`;

const BackgroundIcon = styled(({ icon: Icon, ...props }) => <Icon {...props} />)`
  position: absolute;
  color: rgba(147, 197, 253, 0.2);
  animation: ${(props) => float(props.x, props.y, props.rotate)} ${(props) => props.duration}s ease-in-out infinite;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Card = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 1.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  width: 90%;
  max-width: 42rem;
  text-align: center;
  position: relative;
  z-index: 10;
  border: 1px solid rgba(59, 130, 246, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    border-radius: 1.5rem;
    opacity: 0.2;
  }

  @media (max-width: 640px) {
    padding: 1.5rem;
    width: 95%;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
  animation: ${glow} 3s ease-in-out infinite;

  @media (max-width: 640px) {
    font-size: 1.75rem;
  }
`;

const Subtitle = styled.p`
  color: #bfdbfe;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  animation: ${fadeInUp} 1s ease-out;
  max-width: 90%;

  @media (max-width: 640px) {
    font-size: 0.875rem;
  }
`;

const AlertBox = styled.div`
  background-color: rgba(30, 58, 138, 0.5);
  border-left: 4px solid #fbbf24;
  color: #fef3c7;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  align-items: center;
  animation: ${fadeInUp} 1s ease-out;
  width: 100%;
  max-width: 90%;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.875rem;
  }
`;

const StyledButton = styled(Button)`
  width: 90%;
  max-width: 20rem;
  background: linear-gradient(to right, #3b82f6, #8b5cf6);
  color: white;
  font-weight: 600;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  animation: ${shimmer} 3s linear infinite;
  background-size: 200% 100%;
  margin-bottom: 1rem;

  &:hover {
    transform: translateY(-2px);
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 640px) {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
`;

const MainIcon = styled(Cpu)`
  color: #60a5fa;
  width: 5rem;
  height: 5rem;
  animation: ${spin} 20s linear infinite;

  @media (max-width: 640px) {
    width: 3rem;
    height: 3rem;
  }
`;

const ClockIcon = styled(Clock)`
  color: #fcd34d;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @media (max-width: 640px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StatusIndicator = styled.div<{ $isOnline: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-weight: bold;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  ${({ $isOnline }) =>
    $isOnline
      ? css`
          background-color: rgba(16, 185, 129, 0.2);
          color: #10b981;
          border: 1px solid #10b981;
        `
      : css`
          background-color: rgba(239, 68, 68, 0.2);
          color: #ef4444;
          border: 1px solid #ef4444;
        `}

  @media (max-width: 640px) {
    padding: 0.3rem 0.6rem;
    font-size: 0.75rem;
  }
`;
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-20px); }
  60% { transform: translateY(-10px); }
`;

const BouncingIcon = styled.div`
  animation: ${bounce} 2s infinite;
  margin: 1rem 0;
`;

const StatusIcon = styled(({ icon: Icon, ...props }) => <Icon {...props} />)`
  margin-right: 0.5rem;
`;

const DisplayTime = styled.strong`
  animation: ${pulse} 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  display: inline-block;
`;

export const Maintenance: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t: tMaintenance } = useTranslation('maintenance');

  const redirectPath = useRedirectPath();
  const [timeLeft, setTimeLeft] = useState(0);
  const apiStatus = useApiStatus();
  const { current_access_token, current_refresh_token, current_init_data } = getAllStorageData();

  useEffect(() => {
    if (apiStatus) {
      navigate(redirectPath ?? '/');
    }
  }, []);

  useEffect(() => {
    const setMaintenanceEndTime = () => {
      const storedEndTime = localStorage.getItem('maintenanceEndTime');
      if (storedEndTime) {
        const endTime = parseISO(storedEndTime);
        const now = new Date();
        if (isAfter(endTime, now)) {
          setTimeLeft(differenceInSeconds(endTime, now));
        } else {
          localStorage.removeItem('maintenanceEndTime');
          navigate(redirectPath);
        }
      } else {
        const twoHoursFromNow = addHours(new Date(), 2);
        localStorage.setItem('maintenanceEndTime', twoHoursFromNow.toISOString());
        setTimeLeft(2 * 60 * 60);
      }
    };

    setMaintenanceEndTime();

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          localStorage.removeItem('maintenanceEndTime');
          navigate(redirectPath);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate, redirectPath]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return format(new Date(0, 0, 0, hours, minutes, secs), 'HH:mm:ss');
  };

  const handleCheckStatus = () => {
    if (apiStatus) {
      localStorage.removeItem('maintenanceEndTime');
      navigate(redirectPath);
    } else if (current_access_token && current_refresh_token && current_init_data) {
      dispatch(updateToken());
    }
  };

  return (
    <Container>
      <BackgroundIcon
        icon={Wifi}
        x={-20}
        y={-20}
        rotate={10}
        duration={6}
        size={64}
        style={{ top: '10%', left: '10%' }}
      />
      <BackgroundIcon
        icon={Server}
        x={20}
        y={20}
        rotate={-10}
        duration={8}
        size={80}
        style={{ bottom: '15%', right: '15%' }}
      />
      <BackgroundIcon
        icon={Database}
        x={-15}
        y={15}
        rotate={5}
        duration={7}
        size={56}
        style={{ top: '20%', right: '20%' }}
      />
      <Card>
        <IconWrapper>
          <MainIcon />
          <ClockIcon />
        </IconWrapper>
        <Title>{tMaintenance(apiStatus ? 'title.online' : 'title.maintenance')}</Title>
        <StatusIndicator $isOnline={apiStatus}>
          <StatusIcon icon={apiStatus ? CheckCircle : XCircle} size={20} />
          {tMaintenance(apiStatus ? 'status.operational' : 'status.maintenance')}
        </StatusIndicator>
        <Subtitle>{tMaintenance(apiStatus ? 'subtitle.online' : 'subtitle.maintenance')}</Subtitle>
        {!apiStatus && (
          <AlertBox>
            <AlertTriangle size={20} style={{ marginRight: '0.5rem', marginBottom: '0.25rem' }} />
            <p>
              {tMaintenance('estimatedCompletion')} <DisplayTime>{formatTime(timeLeft)}</DisplayTime>
            </p>
          </AlertBox>
        )}
        <StyledButton onClick={handleCheckStatus}>
          {tMaintenance(apiStatus ? 'button.online' : 'button.maintenance')}
        </StyledButton>
        {apiStatus ? (
          <BouncingIcon>
            <Cpu size={36} color="#10B981" />
          </BouncingIcon>
        ) : (
          <Subtitle>{tMaintenance('appreciationMessage')}</Subtitle>
        )}
      </Card>
    </Container>
  );
};
