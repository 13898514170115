import * as React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoaderSizeProps {
  loading?: boolean;
  color?: string;
  speedMultiplier?: number;
  cssOverride?: React.CSSProperties;
  size?: number | string;
  className?: string;
}

// Define keyframes for the ring animations
const rightKeyframes = keyframes`
  0% { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
  100% { transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg); }
`;

const leftKeyframes = keyframes`
  0% { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
  100% { transform: rotateX(360deg) rotateY(180deg) rotateZ(360deg); }
`;

// Wrapper styled component for the overall loader container
const Wrapper = styled.span<{ size: string }>`
  display: inherit;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  position: relative;
`;

// Circle styled component for each rotating circle
const Circle = styled.span<{
  size: string;
  color: string;
  $animation: ReturnType<typeof keyframes>; // Use transient prop
  $speedMultiplier: number; // Use transient prop
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border: ${(props) => `calc(${props.size} / 10) solid ${props.color}`};
  opacity: 0.4;
  border-radius: 100%;
  animation-fill-mode: forwards;
  perspective: 800px;
  animation: ${(props) => props.$animation} ${(props) => `${2 / props.$speedMultiplier}s`} 0s infinite linear;
`;

export const RingLoader = ({
  loading = true,
  color = '#000000',
  speedMultiplier = 1,
  cssOverride = {},
  size = 60,
  className,
  ...additionalProps
}: LoaderSizeProps): JSX.Element | null => {
  // Helper function to convert size to a string with appropriate unit
  const getSizeValue = (size: number | string): string => {
    if (typeof size === 'number') {
      return `${size / 16}rem`; // Convert px to rem
    }
    return size;
  };

  const sizeValue = getSizeValue(size);

  if (!loading) {
    return null;
  }

  return (
    <Wrapper
      size={sizeValue}
      className={className}
      style={cssOverride} // Pass cssOverride as inline styles
      {...additionalProps}
    >
      <Circle size={sizeValue} color={color} $animation={rightKeyframes} $speedMultiplier={speedMultiplier} />
      <Circle size={sizeValue} color={color} $animation={leftKeyframes} $speedMultiplier={speedMultiplier} />
    </Wrapper>
  );
};
