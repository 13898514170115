import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { FormType } from '@enums';
import { useFormError, useFormSubmissionStatus } from '@store';

interface UseErrorFormInnerProps {
  formType?: FormType;
  submitError?: any;
  isSubmitting?: boolean;
}
export const useErrorFormInner = ({
  formType = FormType.Empty,
  submitError,
  isSubmitting = false,
}: UseErrorFormInnerProps) => {
  const { setFieldError, setFieldTouched } = useFormikContext();
  const isFormError = useFormError(formType) ?? submitError;
  const isFormSubmitting = useFormSubmissionStatus({ formType }) ?? isSubmitting;

  useEffect(() => {
    if (isFormSubmitting || !isFormError) return;

    const processError = (error: unknown): string => {
      if (!error) return '';

      if (typeof error === 'string') return error;

      if (Array.isArray(error)) {
        return error
          .map((item) => {
            if (typeof item === 'object' && item !== null) {
              return Object.values(item).join('\n ');
            }
            return String(item);
          })
          .join('\n ');
      }
      if (typeof error === 'object' && error !== null) {
        return Object.values(error).join('\n ');
      }

      return error.toString();
    };

    const setError = (field: string, error: any) => {
      const errorMessage = processError(error);
      setFieldError(field, errorMessage);
      setFieldTouched(field, true, false);
    };

    const traverseErrors = (errors: any, parentField = '') => {
      if (!errors) return;

      Object.entries(errors).forEach(([field, error]) => {
        const isNumericKey = !isNaN(Number(field));
        const fullField = parentField ? (isNumericKey ? parentField : `${parentField}.${field}`) : field;
        setError(fullField, error);
      });
    };

    if (typeof isFormError === 'string') {
      setError('submitError', isFormError);
    } else {
      traverseErrors(isFormError);
    }
  }, [isFormSubmitting, isFormError, setFieldError, setFieldTouched]);
};
