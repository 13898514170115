import { FormikProps } from 'formik';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActionButtonGroup, ConfigurationTab, Responsive, SettingsButtons } from '@components';
import { FormActionType, FormType, Translation } from '@enums';
import { useFetchFormList, useFormHandlers } from '@hooks';
import { useFormLoadingStatus, useFormProcedure, useFormSubmissionStatus } from '@store';
import { validateValueEqual } from '@utils';

import { CachePurge, PredefinedCacheView } from './';

export const Cache: FC = () => {
  const { id: application_id } = useParams();

  const predefinedCacheFormType = FormType.CdnCache;

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.cache' });
  const { t: tConfigurationButton } = useTranslation('configuration', { keyPrefix: 'buttons' });

  const formProcedure = useFormProcedure(predefinedCacheFormType);
  const formSubmitting = useFormSubmissionStatus({ formType: predefinedCacheFormType });
  const formLoading = useFormLoadingStatus({ formType: predefinedCacheFormType });

  const tabs = [
    { name: tTabs('cacheRules'), key: 'cacheRules' },
    // { name: 'Custom configuration', key: 'customConfiguration' },
  ];

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  useFetchFormList({
    formType: predefinedCacheFormType,
    params: { application_id },
    globalOptions: {
      content: true,
    },
  });

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleFormikInstance = (formik) => {
    formikRef.current = formik;
  };

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const CacheFormHandler = useFormHandlers({
    formType: predefinedCacheFormType,
    handlerOptions: [
      {
        type: FormActionType.Save,
        function: handleSaveSettings,
        label: tConfigurationButton('saveSettings'),
        loading: formSubmitting,
        disabled: formLoading,
      },
    ],
  });

  return (
    <div className="flex flex-col flex-1 space-y-8">
      <div className="flex justify-between">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          {validateValueEqual(activeTab, 'cacheRules') && (
            <ActionButtonGroup actionHandlers={CacheFormHandler} procedure={formProcedure} />
          )}
        </Responsive>
      </div>
      <div className="flex flex-col gap-8">
        <CachePurge />
        <PredefinedCacheView formikRef={formikRef} onFormikInstance={handleFormikInstance} />
      </div>
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'cacheRules') && <SettingsButtons handleSaveModal={handleSaveSettings} />}
      </Responsive>
    </div>
  );
};
