import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Translation } from '@enums';
import { PaginationButton, Text } from '..';

const MINIMUM_PAGES_FOR_ELLIPSIS = 4;
const ANIMATION_DURATION = 0.2;

const pageButtonVariants = {
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

interface PaginationProps {
  readonly first_page: number;
  readonly last_page: number;
  readonly next_page: number;
  readonly page: number;
  readonly total: number;
  readonly total_pages: number;
  readonly page_size: number;
}

interface PaginationComponentProps {
  pagination: PaginationProps;
  onChangePage: (page: number) => void;
  isLoading?: boolean;
  className?: string;
}

const containerVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.05,
    },
  },
  exit: { opacity: 0, y: -20 },
};

const calculateVisiblePages = (currentPage: number, totalPages: number): number[] => {
  if (totalPages <= 2) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  if (currentPage <= 2) return [1, 2, 3];
  if (currentPage >= totalPages - 1) return [totalPages - 2, totalPages - 1, totalPages];

  return [currentPage - 1, currentPage, currentPage + 1];
};

const Pagination: React.FC<PaginationComponentProps> = ({
  pagination,
  onChangePage,
  isLoading = false,
  className = '',
}) => {
  const { t } = useTranslation(Translation.Common, { keyPrefix: 'tables' });
  const { page: currentPage, first_page: firstPage, total_pages: totalPages, page_size: pageSize, total } = pagination;

  const visiblePages = useMemo(() => calculateVisiblePages(currentPage, totalPages), [currentPage, totalPages]);

  const currentPageItemCount = useCallback(() => {
    if (!pagination?.page_size || !pagination?.total || !pagination?.page || !pagination?.total_pages) {
      return 0;
    }

    return pagination.page === pagination.total_pages
      ? pagination.total % pagination.page_size || pagination.page_size
      : pagination.page_size;
  }, [])();

  const isFirstPage = currentPage === firstPage;
  const isLastPage = currentPage === totalPages;

  const handlePageClick = useCallback(
    (page: number) => {
      if (page !== currentPage && !isLoading) {
        onChangePage(page);
      }
    },
    [currentPage, isLoading, onChangePage],
  );

  const renderPageInfo = useMemo(
    () => (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: ANIMATION_DURATION }}>
        <Text $level={6} color="text-gray-500" className="hidden sm:flex">
          {t('displaying')}
          <motion.span layout className="font-medium mx-2 text-black">
            {(currentPage - 1) * pageSize + 1}
          </motion.span>
          {t('to')}
          <motion.span layout className="font-medium mx-2 text-black">
            {Math.min(currentPage * pageSize, total)}
          </motion.span>
          {t('of')}
          <motion.span layout className="font-medium mx-2 text-black">
            {total}
          </motion.span>
          {t('totalResults')}
        </Text>
      </motion.div>
    ),
    [currentPage, pageSize, total, t],
  );

  if (total === 0) return null;
  if (totalPages < firstPage) {
    return null;
  }
  return (
    <motion.div
      className={classNames(`flex items-center justify-center sm:justify-between px-6 py-2`, {
        'bg-gray-50': currentPageItemCount % 2 !== 0,
        'bg-transparent': currentPageItemCount % 2 === 0 || currentPageItemCount === 0,
        [className]: className,
      })}
      variants={containerVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="flex sm:flex-1 sm:items-center sm:justify-between">
        {renderPageInfo}
        <AnimatePresence mode="wait">
          <motion.nav className="isolate inline-flex space-x-2 items-center" role="navigation" aria-label="Pagination">
            <PaginationButton
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={isFirstPage || isLoading}
              ariaLabel="Previous page"
            >
              <ChevronLeftIcon
                className={`h-5 w-5 ${isFirstPage ? 'text-gray-300' : 'text-theme-primary-main'}`}
                aria-hidden="true"
              />
            </PaginationButton>

            {currentPage > 2 && totalPages > MINIMUM_PAGES_FOR_ELLIPSIS && (
              <PaginationButton onClick={() => handlePageClick(1)} disabled={isLoading} data-testid="first-page-button">
                1
              </PaginationButton>
            )}

            {currentPage > 3 && totalPages > MINIMUM_PAGES_FOR_ELLIPSIS && (
              <motion.span role="separator" aria-hidden="true" variants={pageButtonVariants} className="px-2">
                ...
              </motion.span>
            )}

            {visiblePages.map((page) => (
              <PaginationButton
                key={page}
                onClick={() => handlePageClick(page)}
                disabled={page === currentPage || isLoading}
                active={page === currentPage}
                aria-current={page === currentPage ? 'page' : undefined}
                data-testid={`page-button-${page}`}
              >
                {page}
              </PaginationButton>
            ))}

            {currentPage < totalPages - 2 && totalPages > MINIMUM_PAGES_FOR_ELLIPSIS && (
              <motion.span role="separator" aria-hidden="true" variants={pageButtonVariants} className="px-2">
                ...
              </motion.span>
            )}

            {currentPage < totalPages - 1 && totalPages > 3 && (
              <PaginationButton
                onClick={() => handlePageClick(totalPages)}
                disabled={isLoading}
                data-testid="last-page-button"
              >
                {totalPages}
              </PaginationButton>
            )}

            <PaginationButton
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={isLastPage || isLoading}
              ariaLabel="Next page"
            >
              <ChevronRightIcon
                className={`h-5 w-5 ${isLastPage ? 'text-gray-300' : 'text-theme-primary-main'}`}
                aria-hidden="true"
              />
            </PaginationButton>
          </motion.nav>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default memo(Pagination);
