import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';

import { Skeleton } from '@components';
import { Direction, InputMod, VariantType } from '@enums';
import { validateValueEqual } from '@utils';
import ErrorMessage from './errorMessage';
import { InputWithIcons } from './inputWithIcons';

interface InputTextProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  name: string;
  multiline?: boolean;
  errorClassName?: string;
  isCorrect?: boolean;
  hints?: string;
  fontSize?: string;
  adornment?: React.ReactNode;
  withoutRing?: boolean;
  adornmentWithoutM?: boolean;
  rounded?: string;
  fullWidth?: boolean;
  customizeColor?: boolean;
  transform?: (event: React.ChangeEvent<HTMLInputElement>) => React.ChangeEvent<HTMLInputElement>;
  smallFont?: boolean;
  variant?: VariantType;
  clearFunction?: () => void;
  inputMod?: InputMod;
  withoutClear?: boolean;
  label?: string;
  autoComplete?: string;
  adornmentPosition?: Direction;
  loading?: boolean;
}

export const TextInput: React.FC<InputTextProps> = ({
  multiline = false,
  name,
  errorClassName = '',
  isCorrect,
  hints,
  fontSize,
  type = 'text',
  onBlur,
  transform,
  adornment,
  withoutRing,
  customizeColor = false,
  onPaste,
  disabled,
  autoComplete = 'off',
  smallFont = false,
  className = `py-1.5 ${type === 'number' ? 'pr-16' : 'pr-10'}`,
  variant = VariantType.Normal,
  inputMod,
  adornmentWithoutM = false,
  fullWidth,
  clearFunction,
  maxLength,
  min,
  max,
  required = false,
  withoutClear,
  rounded = 'rounded-md',
  adornmentPosition = Direction.Left,
  value,
  loading = false,
  onChange,
  label,
  placeholder,
}) => {
  const [field, meta, helpers] = useField(name);
  const formik = useFormikContext();

  const [showClearIcon, setShowClearIcon] = useState(false);
  const [isHide, setIsHide] = useState('password');
  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);
  const [padding, setPadding] = useState({ right: '10px', left: '0px', iconRight: '12px' });

  const adornmentRef = useRef<HTMLDivElement>(null);
  const iconsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isError = Boolean(meta.error && (meta.touched || meta.initialTouched || formik.submitCount > 0));
  const isCustom = validateValueEqual(variant, VariantType.Custom);
  const isFilled = validateValueEqual(inputMod, InputMod.Filled);
  const isPassword = validateValueEqual(type, 'password');
  const isNumber = validateValueEqual(type, 'number');
  const isAdornmentLeft = validateValueEqual(adornmentPosition, Direction.Left);
  const isAdornmenRight = validateValueEqual(adornmentPosition, Direction.Right);
  const isAdornmentOutside = validateValueEqual(adornmentPosition, Direction.Outside);

  useEffect(() => {
    const updatePadding = () => {
      const adornmentWidth = adornmentRef.current?.offsetWidth ?? 0;
      const iconsContainerWidth = iconsContainerRef.current?.offsetWidth ?? 0;

      setPadding({
        right: `${(isAdornmentLeft ? adornmentWidth : 0) + iconsContainerWidth + 3}px`,
        left: `${!isAdornmentLeft ? adornmentWidth + 10 : isFilled ? (adornment ? 12 : 16) : 12}px`,
        iconRight: `${(isAdornmentLeft ? adornmentWidth : 0) + (isFilled ? 14 : meta.touched ? 8 : 10)}px`,
      });
    };

    const debouncedUpdatePadding = debounce(updatePadding, 50);
    debouncedUpdatePadding();
    window.addEventListener('resize', debouncedUpdatePadding);

    return () => {
      window.removeEventListener('resize', debouncedUpdatePadding);
      debouncedUpdatePadding.cancel();
    };
  }, [adornment, showClearIcon, isError, isAdornmentLeft, isFilled, iconsContainerRef.current?.offsetWidth]);

  useEffect(() => {
    setShowClearIcon(!!field.value && userHasInteracted);
  }, [field.value, meta.touched, userHasInteracted]);

  const handleClear = () => helpers.setValue('');
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setUserHasInteracted(true);
    event.target.focus();
  };

  const handleHideIcon = () => setIsHide((prev) => (prev === 'password' ? 'text' : 'password'));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transformedEvent = transform ? transform(event) : event;
    field.onChange(transformedEvent);
    onChange?.(transformedEvent);
  };

  const disableNumberInputScroll = (e: React.WheelEvent<HTMLInputElement>) => {
    if (isNumber) {
      e.currentTarget.blur();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setUserHasInteracted(false);
    let trimmedValue: string | number = event.target.value?.trim?.() ?? event.target.value;

    if (isNumber && min !== undefined) {
      const numValue = Number(trimmedValue);
      if (isNaN(numValue) || numValue < Number(min)) {
        trimmedValue = Number(min);
      } else {
        trimmedValue = numValue;
      }
    }

    field.onChange({
      target: {
        name: field.name,
        value: trimmedValue,
      },
    });

    field.onBlur(event);

    if (onBlur) onBlur(event);

    event.target.value = trimmedValue.toString();
  };

  if (loading) {
    return <Skeleton className={classNames('h-10', { 'w-full': !adornment || fullWidth, [rounded]: rounded })} />;
  }

  return (
    <motion.div
      className={classNames('flex flex-col h-full space-y-3', { 'w-full': fullWidth })}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-1 gap-3">
        <motion.div className="relative group flex flex-1 items-center">
          {adornment && isAdornmenRight && (
            <div
              ref={adornmentRef}
              className={classNames('absolute left-0 h-full flex', { 'pl-3': !adornmentWithoutM })}
            >
              {adornment}
            </div>
          )}
          <motion.input
            {...field}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value ?? field.value ?? ''}
            autoComplete={autoComplete}
            required={required}
            onFocus={handleFocus}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChange={handleChange}
            onPaste={onPaste}
            disabled={disabled}
            type={isPassword ? isHide : type}
            min={isNumber ? min : undefined}
            max={isNumber ? max : undefined}
            aria-invalid={!!(meta.error && meta.touched)}
            aria-describedby={isError ? `${name}-error` : undefined}
            className={classNames('block border-0 text-ellipsis placeholder-gray-400 hover:cursor-text', {
              'w-full': !adornment || fullWidth,
              'sm:leading-6': fontSize,
              'px-2.5 py-1.5': !isCustom && !isFilled,
              'px-3': !isCustom && isFilled,
              'text-us': smallFont,
              'text-sm': !smallFont,
              'ring-1 ring-theme-border-default focus:ring-indigo-600': !withoutRing,
              'resize-y': multiline,
              'ring-theme-error-main ring-1 bg-red-50 focus:ring-theme-error-main': isError,
              'bg-gray-50 bg-opacity-10': !isError && !customizeColor,
              'w-full h-10 px-3 text-sm peer outline-none border-0 rounded': isFilled,
              'pt-9 pb-4 placeholder-transparent': isFilled && field.value,
              'group-focus-within:pt-9 peer-valid:pt-9 peer-valid:pb-4 group-focus-within:pb-4 group-focus-within:placeholder-gray-400 placeholder-transparent':
                isFilled && (!field.value || field.value === ''),
              'bg-theme-primary-light': disabled,
              '[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [-moz-appearance:textfield]':
                isNumber,
              [rounded]: rounded,
              [className]: className,
            })}
            style={{ paddingRight: padding.right, paddingLeft: padding.left }}
            animate={{ scale: userHasInteracted && !adornment ? 1.02 : 1 }}
            onWheel={disableNumberInputScroll}
            transition={{ duration: 0.15 }}
          />
          <AnimatePresence>
            {isFilled && label && (
              <motion.label
                htmlFor={name}
                transition={{ duration: 0.05 }}
                animate={{ x: userHasInteracted && !adornment ? -1.05 : 0 }}
                className={classNames(
                  'absolute top-0 left-0 flex h-full items-center transform transition-all text-gray-400 font-medium pointer-events-none select-none',
                  'group-focus-within:h-4/6',
                  {
                    'text-smLabel peer-valid:h-4/6 peer-invalid:h-4/6': field.value,
                    'text-xs group-focus-within:text-smLabel text-[0.58rem]': !field.value,
                    'pl-4': !adornment,
                    'pl-3': adornment,
                  },
                )}
              >
                {label}
              </motion.label>
            )}
          </AnimatePresence>
          <InputWithIcons
            isError={isError}
            isCorrect={isCorrect}
            field={field}
            iconInputRef={iconsContainerRef}
            withoutClear={withoutClear}
            hints={hints}
            isNumber={isNumber}
            paddingRight={padding.iconRight}
            clearFunction={clearFunction}
            isPassword={isPassword}
            isHide={isHide}
            handleHideIcon={handleHideIcon}
            handleClear={handleClear}
            showClearIcon={showClearIcon}
          />
          {adornment && isAdornmentLeft && (
            <div ref={adornmentRef} className={`absolute right-0 h-full flex ${adornmentWithoutM ? '' : 'pr-3'}`}>
              {adornment}
            </div>
          )}
        </motion.div>
        {adornment && isAdornmentOutside && (
          <div className={classNames('h-full flex', { 'pr-2': !adornmentWithoutM })}>{adornment}</div>
        )}
      </div>
      <AnimatePresence>
        {isError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ErrorMessage isError={isError} errorClassName={errorClassName} meta={meta} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
