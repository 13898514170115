import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CdnRouteListTable, getCdnRoutePriorityColumns } from '@constants';
import { FormListLayout } from '@containers';
import { ApiEndpoint, FormActionType, FormType, ServiceModalName, StatusType, Translation, VariantType } from '@enums';
import { useAppDispatch, useFetchFormList, useFormHandlers } from '@hooks';
import { addServiceModal, deleteFormContent, useFormList, useFormLoadingStatus, useFormSubmissionStatus } from '@store';
import { ServiceModalPayload } from '@types';
import { RouteModal } from '@pages/Cdn';

export const RouteForm = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnRoute;

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.route' });
  const { t: tButtons } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.route' });
  const { t: tRouteModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.route' });
  const { t: tModals } = useTranslation(Translation.Configuration, { keyPrefix: 'modals' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });
  const { t: tCommons } = useTranslation(Translation.Common);

  const originList = useFormList({ formType: FormType.CdnOrigin });
  const originLoading = useFormLoadingStatus({ formType: FormType.CdnOrigin });

  useFetchFormList({
    formType: FormType.CdnOrigin,
    params: { application_id },
    globalOptions: {
      endpoint: ApiEndpoint.CUSTOM,
    },
    additionalDeps: [originList],
    additionalCondition: !originLoading,
  });

  const isRouteSubmitting = useFormSubmissionStatus({ formType, endpoint: ApiEndpoint.SORTING });
  const isRouteLoading = useFormLoadingStatus({ formType, endpoint: ApiEndpoint.SORTING });
  const originOption = useFormList({ formType: FormType.CdnOrigin, endpoint: ApiEndpoint.CUSTOM });

  const cdnRouteTableColumns = useMemo(() => CdnRouteListTable(originOption), [originOption, tTables]) ?? [];
  const columns = useMemo(getCdnRoutePriorityColumns, [tTables]);

  const [addRouteModal, setAddRouteModal] = useState<boolean>(false);

  const routeFormHandlers = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Priority,
        function: () => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.PrioritizeModal,
              payload: {
                label: {
                  title: tRouteModal('prioritizeRoute'),
                  text: tRouteModal('prioritizeRouteDescription'),
                },
                option: {
                  modalTable: { columns, formType, application_id },
                  button: { loading: isRouteSubmitting, disabled: isRouteLoading },
                },
              } as ServiceModalPayload,
            }),
          );
        },
        label: tCommons('button.prioritySort'),
        buttonType: VariantType.Outlined,
        disabled: isRouteLoading,
      },
      {
        type: FormActionType.Create,
        function: () => setAddRouteModal(true),
        label: tButtons('addNewRoute'),
        disabled: !originOption.length,
      },
      {
        type: FormActionType.Edit,
        function: () => setAddRouteModal(true),
      },
      {
        type: FormActionType.Delete,
        function: (rowData) => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.ConfirmModal,
              payload: {
                label: {
                  title: tRouteModal('routeDelete'),
                  text: tModals('areYouSureDelete', { domain: rowData?.name }),
                  confirmButton: tCommons('button.delete'),
                },
                option: {
                  modalType: StatusType.Delete,
                  button: {
                    loading: isRouteSubmitting,
                  },
                },
                callbackFunction: {
                  submit: () =>
                    dispatch(
                      deleteFormContent({
                        formType,
                        params: {
                          content_id: rowData?.id,
                          application_id: application_id,
                        },
                        globalOptions: {
                          refetch_id: application_id,
                        },
                      }),
                    ),
                },
              },
            }),
          );
        },
      },
    ],
  });

  return (
    <FormListLayout
      label={{
        title: tTabs('applicationRoute'),
      }}
      tableData={{
        columns: cdnRouteTableColumns,
        actionHandlers: routeFormHandlers,
        hasChildren: true,
        formType,
      }}
    >
      <RouteModal open={addRouteModal} onClose={() => setAddRouteModal(false)} />
    </FormListLayout>
  );
};
