import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '@hooks';

export const profileSettingsInitialState: any = {
  isLoading: false,
  isSubmitting: false,
  saveSuccess: false,
  loadSuccess: false,
  list: [],
  content: {},
  error: null,
};

export const profileSlice = createSlice({
  name: 'profilesettings',
  initialState: profileSettingsInitialState,
  reducers: {
    submitChangePasswordForm: (state, { payload }: PayloadAction<any>) => {
      state.isSubmitting = true;
      state.saveSuccess = false;
    },
    submitChangePasswordFormSuccess: (state) => {
      state.isSubmitting = false;
      state.saveSuccess = true;
    },
    submitChangePasswordFormError: (state, { payload }: PayloadAction<any>) => {
      state.isSubmitting = false;
      state.saveSuccess = false;
      state.error = payload.data;
    },
    clearProfileError: (state) => {
      state.error = null;
    },
    resetProfileSettings: (state) => {
      return profileSettingsInitialState;
    },
  },
});

export const {
  submitChangePasswordForm,
  submitChangePasswordFormSuccess,
  submitChangePasswordFormError,
  resetProfileSettings,
  clearProfileError,
} = profileSlice.actions;

export const useProfileSettingsLoading = () => useAppSelector((state: RootState) => state.profilesettings.isLoading);
export const useProfileSettingsSubmitting = () =>
  useAppSelector((state: RootState) => state.profilesettings.isSubmitting);
export const useProfileSettingsSaveSuccess = () =>
  useAppSelector((state: RootState) => state.profilesettings.saveSuccess);
export const useProfileSettingsData = () => useAppSelector((state: RootState) => state.profilesettings.list);
export const useProfileSettingsTableData = () => useAppSelector((state: RootState) => state.profilesettings.content);
export const useProfileSettingsError = () => useAppSelector((state: RootState) => state.profilesettings.error);

const profileReducer = profileSlice.reducer;
export default profileReducer;
