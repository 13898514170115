import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import appLogo from '@assets/logo.webp';

import { Button, Text } from '@components';
import { Translation, VariantType } from '@enums';
import { useAppDispatch, useSidebarNavigation } from '@hooks';
import { setSidebarCollapsible, useSidebarCollapsed } from '@store';

import { AnimatePresence, motion } from 'framer-motion';
import { NavigationCategory } from './NavigationCategory';

export const Sidebar = ({ status, sidebarAction }) => {
  const { t: tCommon } = useTranslation(Translation.Common);

  const dispatch = useAppDispatch();

  const categories = useSidebarNavigation();
  const isCollapse = useSidebarCollapsed();

  const toggleSidebar = () => dispatch(setSidebarCollapsible());

  const sidebarVariants = {
    expanded: {
      width: '280px',
    },
    collapsed: {
      width: '80px',
    },
  };

  const logoTextVariants = {
    expanded: {
      opacity: 1,
      x: 0,
      display: 'block',
    },
    collapsed: {
      opacity: 0,
      x: -20,
      display: 'none',
    },
  };

  return (
    <>
      <Transition show={status} as={Fragment}>
        <Dialog as="div" className={`fixed inset-0 overflow-hidden z-30 xl:hidden w-72`} onClose={sidebarAction}>
          <TransitionChild
            as={Fragment}
            enter="transform ease-in-out duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="flex p-4 bg-theme-primary-dark flex-col h-screen w-72 text-white overflow-y-auto shadow-md padded-scrollbar-track">
              <div className="flex flex-col h-screen w-full pb-8 gap-y-8">
                <div className="flex flex-col flex-1 space-y-6">
                  <div className="flex w-full"></div>
                  <NavigationCategory isCollapse={false} categories={categories} />
                </div>
                <div className="flex items-center justify-center font-semibold text-md pb-2">
                  <img src={appLogo} alt="7Bull Logo" className="w-10" />
                </div>
              </div>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <motion.div
        initial={false}
        animate={isCollapse ? 'collapsed' : 'expanded'}
        variants={sidebarVariants}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className={`hidden xl:flex xl:inset-y-0 sticky xl:flex-col bg-white bg-opacity-90 shadow-card text-black rounded-xl overflow-hidden mb-8 min-w-[80px]`} // Add min-width
      >
        <div className={`flex flex-col max-h-screen h-full space-y-4 ${isCollapse ? 'items-center pt-3' : 'py-3'}`}>
          <div className="flex items-center pt-3 px-6">
            <motion.img
              src={appLogo}
              alt="7Bull Logo"
              initial={false}
              transition={{
                duration: 0.3,
                ease: 'easeInOut',
              }}
              animate={{
                scale: isCollapse ? 1.12 : 1,
                opacity: isCollapse ? 0.95 : 1,
              }}
              className="transform-gpu w-10"
            />
            <motion.div
              variants={logoTextVariants}
              transition={{ duration: 0.3 }}
              className={`${isCollapse ? 'hidden' : 'block flex-1 ml-3'} `}
            >
              <Text $level={2} $customizeColor className="font-semibold text-lg">
                7Bull
              </Text>
              <Text $level={6} color="text-gray-400" className="font-medium text-lg">
                {tCommon('unifiedDashboardPortal')}
              </Text>
            </motion.div>
          </div>

          <motion.div
            className={`${isCollapse ? 'pt-1' : 'px-7 pt-5'} overflow-y-auto justify-between overflow-x-hidden flex flex-1 flex-col pb-3 gap-3 `}
          >
            <motion.div
              animate={{
                gap: isCollapse ? '8px' : '20px',
              }}
              className={`${isCollapse ? 'space-y-2' : 'space-y-5'}`}
              transition={{ duration: 0.3 }}
            >
              <NavigationCategory isCollapse={isCollapse} categories={categories} />
            </motion.div>
          </motion.div>

          <motion.div className="px-7" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              variant={VariantType.Transparent}
              withoutCenter
              withoutPadding
              iconWithoutM
              icon={
                <motion.div initial={false} animate={{ rotate: isCollapse ? 180 : 0 }} transition={{ duration: 0.3 }}>
                  {isCollapse ? (
                    <ChevronDoubleRightIcon className="w-5 h-5" />
                  ) : (
                    <ChevronDoubleLeftIcon className="w-5 h-5" />
                  )}
                </motion.div>
              }
              onClick={toggleSidebar}
              className={`rounded-md space-x-3 items-center text-slate-600 ${isCollapse ? 'pb-5' : 'pb-3'}`}
            >
              <AnimatePresence>
                {!isCollapse && (
                  <motion.div
                    initial={false}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Text $level={5} $customizeColor>
                      {tCommon('button.toggleSideBar')}
                    </Text>
                  </motion.div>
                )}
              </AnimatePresence>
            </Button>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};
