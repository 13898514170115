import { clsx } from 'clsx';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../Badges/Badge';

export const fontLevelClass = {
  1: 'text-xl/3  sm:text-xl',
  2: 'text-lg/3  sm:text-lg',
  3: 'text-base/3  sm:text-base',
  4: 'text-sm/3  sm:text-sm',
  5: 'text-xs/3  sm:text-xs',
  6: 'text-us/3  sm:text-us',
};

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement | HTMLHeadingElement> {
  className?: string;
  color?: string;
  $level?: 1 | 2 | 3 | 4 | 5 | 6 | null;
  $customizeColor?: boolean;
}

const StyledText = styled.p.attrs<TextProps>(({ color, $customizeColor, $level = 1 }) => ({
  className: `
    ${$level && fontLevelClass[$level]}
    ${color && !$customizeColor ? colors[color] || color : ''}
    ${!$customizeColor && !color ? 'text-zinc-500 dark:text-zinc-400' : ''}
    select-text
  `,
}))<TextProps>``;

export function Text({ color, $level = 1, $customizeColor, ...props }: Readonly<TextProps>) {
  return <StyledText data-slot="text" {...props} color={color} $level={$level} $customizeColor={$customizeColor} />;
}

export function TextLink({ className, ...props }: Readonly<React.ComponentPropsWithoutRef<typeof Link>>) {
  return (
    <Link
      {...props}
      className={clsx(
        className,
        'select-text text-zinc-950 underline decoration-zinc-950/50 data-[hover]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white',
      )}
    />
  );
}

export function Strong({ className, ...props }: Readonly<React.ComponentPropsWithoutRef<'strong'>>) {
  return <strong {...props} className={clsx(className, 'font-medium text-zinc-950 dark:text-white')} />;
}

export function Code({ className, ...props }: Readonly<React.ComponentPropsWithoutRef<'code'>>) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        'rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 text-sm font-medium text-zinc-950 sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white',
      )}
    />
  );
}
