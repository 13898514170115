import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants';
import { useAppDispatch } from '@hooks';
import { updateRedirectPath, useApiStatus } from '@store';

export const ApiGuard = ({ children }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const location = useLocation();
  const isApiConnected = useApiStatus();

  useEffect(() => {
    if (location.pathname !== ROUTES.maintenance && !isApiConnected) {
      dispatch(updateRedirectPath({ previousPath: location.pathname }));
      navigate(ROUTES.maintenance);
    }
  }, [isApiConnected]);
  return <Fragment>{children}</Fragment>;
};
