import classNames from 'classnames';
import { FieldArray, useField } from 'formik';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormField } from '@components';
import { columnSpace, Dictionaries, InputMod, InputType, Translation, VariantType } from '@enums';
import { getOptionListFromCatalog, validateValueEqual } from '@utils';
import { ActionTableForm } from './ActionTableForm';

interface ActionTableInputProps {
  name: string;
  columnPercentage?: columnSpace;
  format?: (key: string) => string;
  emptyData: { main: object };
  className: string;
}

export const ActionTableInput = ({
  name,
  columnPercentage = columnSpace.TwoOverFive,
  format,
  emptyData,
  className = '',
}: PropsWithChildren<ActionTableInputProps>) => {
  const [field] = useField(name);

  const { t: tForm } = useTranslation(Translation.Form, { keyPrefix: 'common' });

  const routeActionOptionList = useMemo(() => getOptionListFromCatalog(Dictionaries.RouteAction), [tForm]);

  if (!name) return;

  const isTwoOverFive = validateValueEqual(columnPercentage, columnSpace.TwoOverFive);
  const isThreeOverFive = validateValueEqual(columnPercentage, columnSpace.ThreeOverFive);
  const isHalf = validateValueEqual(columnPercentage, columnSpace.Half);

  const dynamicLabelClasses = classNames('text-gray-700 text-left text-sm font-normal leading-6', {
    'w-2/5': isTwoOverFive,
    'w-3/5': isThreeOverFive,
    'w-2/4': isHalf,
    [className]: className,
  });

  const dynamicTableClasses = classNames('flex flex-col space-y-3 ', {
    'w-3/5': isTwoOverFive,
    'w-2/5': isThreeOverFive,
    'w-2/4': isHalf,
    [className]: className,
  });

  return (
    <FieldArray name={name}>
      {({ push, remove: removeRow }) => (
        <div className="space-y-4">
          {field?.value?.map((row, rowIndex) => {
            return (
              <div key={rowIndex} className="flex">
                <label htmlFor={name} className={dynamicLabelClasses}>
                  {`Action ${rowIndex + 1}`}
                </label>

                <div className={dynamicTableClasses}>
                  <FormField
                    fullWidth
                    smallFont
                    withoutLabel
                    name={`${name}.[${rowIndex}].field`}
                    label={'Action Type'}
                    inputMod={InputMod.Filled}
                    inputType={InputType.Select}
                    options={routeActionOptionList}
                  />
                  <ActionTableForm
                    format={format}
                    name={name}
                    row={row}
                    rowId={rowIndex}
                    removeRow={removeRow}
                    emptyData={emptyData}
                  />
                  {!validateValueEqual(field.value?.length, 0) && (
                    <>
                      <div className="w-full inset-0 flex items-center relative">
                        <div className="absolute inset-0 flex justify-end">
                          <div className={`border-t border-dashed border-gray-300 w-full my-1`}></div>
                        </div>
                      </div>
                      {!validateValueEqual(field.value?.length, 0) && (
                        <div className="flex space-x-3 pt-2">
                          <Button
                            variant={VariantType.Outlined}
                            className="ring-red-500 text-red-500 w-full"
                            onClick={() => removeRow(rowIndex)}
                          >
                            <article>Delete Action</article>
                          </Button>
                          {validateValueEqual(rowIndex, field.value?.length - 1) && (
                            <Button
                              className={` bg-theme-primary-main text-white w-full`}
                              onClick={() => push(emptyData.main)}
                            >
                              <span className="text-us font-normal">{tForm('addMoreAction')}</span>
                            </Button>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}

          {validateValueEqual(field.value?.length, 0) && (
            <div className="flex">
              <div className={dynamicLabelClasses}>
                <label htmlFor={name} className={dynamicLabelClasses}>
                  {`Action`}
                </label>
              </div>
              <Button
                className={`px-3 bg-theme-primary-main text-white ${dynamicTableClasses}`}
                onClick={() => push(emptyData.main)}
              >
                <span className="text-us font-normal">{tForm('addMoreAction')}</span>
              </Button>
            </div>
          )}
        </div>
      )}
    </FieldArray>
  );
};
