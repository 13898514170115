import { domainValidationReg, ipValidationReg } from '@/regexp';

export const determineOriginType = (origins, hostType) => {
  if (!origins || origins.length === 0) return hostType.Ip;

  for (const origin of origins) {
    if (origin.origin) {
      if (ipValidationReg.test(origin.origin)) {
        return hostType.Ip;
      }
      if (domainValidationReg.test(origin.origin)) {
        return hostType.Domain;
      }
    }
  }
  return hostType.Ip;
};
