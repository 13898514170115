// export const TOKEN_MANAGER_CONSTANTS = {
//   REFRESH_THRESHOLD: 30_000, // 30 seconds before expiry
//   CRITICAL_THRESHOLD: 15_000, // 15 seconds before expiry
//   CHECK_INTERVAL: 5_000, // Check every 5 seconds
//   MIN_REFRESH_INTERVAL: 5_000, // Minimum 5 seconds between refreshes
//   AGGRESSIVE_INTERVAL: 2_000, // 2 seconds for aggressive checking
//   LOCK_TIMEOUT: 10_000, // 10 seconds lock timeout
//   MAX_RETRIES: 2, // Reduced retries due to shorter timeframe
//   INIT_DELAY: 500, // 500ms initial delay
//   MIN_WAKE_DELAY: 100, // 100ms minimum wake delay
//   MAX_WAKE_DELAY: 1000, // 1 second maximum wake delay
// } as const;

export const TOKEN_MANAGER_CONSTANTS = {
  REFRESH_THRESHOLD: 300_000, // 5 minutes before expiry
  CRITICAL_THRESHOLD: 180_000, // 3 minutes before expiry
  CHECK_INTERVAL: 60_000, // Check every 1 minute
  AGGRESSIVE_INTERVAL: 10_000, // 10 seconds for aggressive checking
  LOCK_TIMEOUT: 30_000, // 30 seconds lock timeout
  MAX_RETRIES: 3, // 3 retries is reasonable with this timeframe
  INIT_DELAY: 1000, // 1 second initial delay
  MIN_WAKE_DELAY: 100, // 100ms minimum wake delay
  MAX_WAKE_DELAY: 2000, // 2 seconds maximum wake delay
} as const;

export const STORAGE_KEYS = {
  LOCK: 'token_refresh_lock',
  LOGOUT: 'user_logged_out',
  LAST_REFRESH: 'last_token_refresh',
} as const;
