import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiEndpoint, CdnDashboardFilter, DashboardEndpoint, FormActionType, FormType } from '@enums';
import { RootState, useAppSelector } from '@hooks';

import type { FilterState, FormPayload, FormSliceState, FormState, PaginationProps, SortableFormItem } from '@types';

const EMPTY_ARRAY: any[] = [];

const CDN_FORMS = {
  pagination: {
    page_size: 5,
  },
};

const CDN_DASHBORADFILTER: { filter: FilterState } = {
  filter: {
    searchTerm: '',
    filterCriteria: [
      {
        from: DashboardEndpoint.TOTAL_TRAFFIC,
        label: CdnDashboardFilter.Time,
        value: '15m',
      },
      {
        from: DashboardEndpoint.TOTAL_TRAFFIC,
        label: CdnDashboardFilter.Domain,
        value: 'all',
      },
    ],
  },
};

const formConfigs = new Map<FormType, Partial<FormState>>([
  [FormType.CdnOrigin, { ...CDN_FORMS }],
  [FormType.CdnRoute, { ...CDN_FORMS }],
  [FormType.CdnDashboard, { ...CDN_DASHBORADFILTER }],
]);

const defaultFormState: FormState = {
  init: true,
  loading: {},
  submitting: {},
  sortLoading: false,
  saveSuccess: false,
  deleteSuccess: false,
  formList: { [ApiEndpoint.DEFAULT]: [] },
  formContent: {},
  sortingList: [],
  filter: {
    searchTerm: '',
    filterCriteria: [{ label: 'status', value: 'all' }],
  },
  selectedIds: [],
  pagination: {
    first_page: 1,
    last_page: 1,
    next_page: 1,
    page: 1,
    total: 0,
    total_pages: 0,
    page_size: 10,
  },
  procedure: FormActionType.Idle,
  error: null,
  lastUpdated: 0,
};

const initialState: FormSliceState = Object.values(FormType).reduce((acc, type) => {
  const customConfig = formConfigs.get(type as FormType);

  acc[type as FormType] = {
    ...defaultFormState,
    ...(customConfig && {
      filter: {
        ...defaultFormState.filter,
        ...customConfig.filter,
      },
      pagination: {
        ...defaultFormState.pagination,
        ...customConfig.pagination,
      },
    }),
  };

  return acc;
}, {} as FormSliceState);

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    fetchFormList: (state, { payload }: PayloadAction<FormPayload>) => {
      const { formType, globalOptions: { endpoint = ApiEndpoint.DEFAULT } = {} } = payload;
      state[formType].loading[endpoint] = true;
      state[formType].formList = {
        ...state[formType].formList,
        [endpoint]: state[formType].formList?.[endpoint] ?? [],
      };
    },
    fetchFormListSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        formType: FormType;
        endpoint: ApiEndpoint | DashboardEndpoint;
        content?: boolean;
        lastUpdated: number;
        list: any[];
        paginationInfo?: PaginationProps;
      }>,
    ) => {
      const { formType, lastUpdated, endpoint, content = false, list, paginationInfo } = payload;

      state[formType].init = false;
      state[formType].loading[endpoint] = false;
      if (content) {
        state[formType].formContent = list;
      } else {
        state[formType].formList[endpoint] = list;
      }
      state[formType].lastUpdated = lastUpdated;
      state[formType].pagination = {
        ...state[formType].pagination,
        ...paginationInfo,
      };
    },
    fetchFormListError: (
      state,
      {
        payload,
      }: PayloadAction<{ formType: FormType; endpoint: ApiEndpoint | DashboardEndpoint; responseResult: any }>,
    ) => {
      const { formType, endpoint, responseResult } = payload;
      state[formType].init = false;
      state[formType].loading[endpoint] = false;
      state[formType].error = responseResult;
    },
    fetchDashboardGraph: (state, { payload }: PayloadAction<any>) => {},
    updateSortingList(state, { payload }: PayloadAction<{ formType: FormType; list: SortableFormItem[] }>) {
      const { formType, list } = payload;
      if (state[formType]) {
        state[formType].sortingList = list.map((item, index) => ({
          id: item.id,
          name: item.name,
          sort_order: index + 1,
          original_id: item.original_id,
        }));
      }
    },
    fetchSortingListSuccess(
      state,
      {
        payload,
      }: PayloadAction<{ formType: FormType; endpoint: ApiEndpoint | DashboardEndpoint; responseResult: any }>,
    ) {
      const { formType, endpoint, responseResult } = payload;
      if (state[formType]) {
        state[formType].loading[endpoint] = false;
        state[formType].sortingList = responseResult;
      }
    },
    submitFormRequest: (state, { payload }: PayloadAction<FormPayload>) => {
      const { formType, globalOptions: { endpoint = ApiEndpoint.DEFAULT } = {} } = payload;
      state[formType].submitting[endpoint] = true;
    },
    submitFormSuccess: (
      state,
      { payload }: PayloadAction<{ formType: FormType; endpoint: ApiEndpoint | DashboardEndpoint; content?: any }>,
    ) => {
      const { formType, endpoint, content = defaultFormState.formContent } = payload;
      state[formType].error = null;
      state[formType].submitting[endpoint] = false;
      state[formType].formContent = content;
      state[formType].saveSuccess = true;
    },
    submitFormError: (
      state,
      {
        payload,
      }: PayloadAction<{ formType: FormType; endpoint: ApiEndpoint | DashboardEndpoint; responseResult: string }>,
    ) => {
      const { formType, endpoint, responseResult } = payload;
      state[formType].submitting[endpoint] = false;
      state[formType].error = responseResult;
    },
    updateFormFilter: (
      state,
      { payload }: PayloadAction<{ formType: FormType; endpoint?: ApiEndpoint | DashboardEndpoint; value: any }>,
    ) => {
      const { formType, endpoint, value } = payload;
      const currentCriteria = state[formType].filter.filterCriteria;

      const newFilterCriteria = currentCriteria.map((criterion) => {
        if (criterion.from === endpoint) {
          const newValue = value.find((v) => v.label === criterion.label);
          if (newValue) {
            return {
              ...criterion,
              value: newValue.value,
            };
          }
        }
        return criterion;
      });

      state[formType].filter = {
        ...state[formType].filter,
        filterCriteria: newFilterCriteria,
      };
    },
    updateFormContent: (state, { payload }: PayloadAction<{ formType: FormType; content: any }>) => {
      const { formType, content } = payload;
      state[formType].formContent = content;
    },
    updateFormProcedure: (state, { payload }: PayloadAction<{ formType: FormType; procedure: FormActionType }>) => {
      const { formType, procedure } = payload;
      state[formType].procedure = procedure;
    },
    updateFormPages: (state, { payload }: PayloadAction<FormPayload & { page: number }>) => {
      const { formType, page } = payload;
      state[formType].pagination.page = page;
    },
    updateSelectedIds: (state, { payload }: PayloadAction<{ formType: FormType; id: string | string[] }>) => {
      const { formType, id } = payload;
      const selected = state[formType].selectedIds;

      if (Array.isArray(id)) {
        state[formType].selectedIds = JSON.stringify(id) === JSON.stringify(selected) ? [] : id;
      } else {
        const index = selected.indexOf(id);
        if (index === -1) {
          selected.push(id);
        } else {
          selected.splice(index, 1);
        }
      }
    },
    deleteFormContent: (state, { payload }: PayloadAction<FormPayload>) => {
      const { formType, globalOptions: { endpoint = ApiEndpoint.DEFAULT } = {} } = payload;
      if (state[formType]) {
        state[formType].loading[endpoint] = true;
      }
    },
    deleteFormContentError: (
      state,
      { payload }: PayloadAction<{ formType: FormType; endpoint: ApiEndpoint | DashboardEndpoint; error: any }>,
    ) => {
      const { formType, endpoint, error } = payload;
      if (state[formType]) {
        state[formType].loading[endpoint] = false;
        state[formType].error = error;
      }
    },
    resetSubmitFormState: (state, { payload }: PayloadAction<FormType>) => {
      if (state[payload]) {
        state[payload].submitting = defaultFormState.submitting;
        state[payload].saveSuccess = defaultFormState.saveSuccess;
        state[payload].error = defaultFormState.error;
      }
    },
    resetFormPagination: (state, { payload }: PayloadAction<FormType>) => {
      if (state[payload]) {
        state[payload].pagination = initialState[payload].pagination;
      }
    },
    resetDashboardFilter: (state, { payload }: PayloadAction<FormType>) => {
      if (state[payload]) {
        state[payload].filter = CDN_DASHBORADFILTER.filter;
      }
    },
    cancelAutoReload: () => {},
    resetCurrentForm: (state, { payload }: PayloadAction<FormType>) => {
      state[payload] = { ...defaultFormState };
    },
    resetAllForms: () => initialState,
  },
});

export const {
  fetchFormList,
  fetchFormListSuccess,
  fetchFormListError,
  fetchDashboardGraph,
  fetchSortingListSuccess,
  submitFormRequest,
  submitFormSuccess,
  submitFormError,
  updateSortingList,
  resetFormPagination,
  updateFormFilter,
  updateFormContent,
  updateFormProcedure,
  updateFormPages,
  updateSelectedIds,
  deleteFormContent,
  deleteFormContentError,
  resetSubmitFormState,
  resetCurrentForm,
  resetDashboardFilter,
  resetAllForms,
  cancelAutoReload,
} = formSlice.actions;

export default formSlice.reducer;

export const useFormState = (formType: FormType) => useAppSelector((state: RootState) => state.form[formType]);
export const useFormInit = (formType: FormType) => useAppSelector((state: RootState) => state.form[formType].init);
export const useFormLoadingStatus = ({
  formType,
  endpoint = ApiEndpoint.DEFAULT,
}: {
  formType: FormType;
  endpoint?: ApiEndpoint | DashboardEndpoint;
}) => useAppSelector((state: RootState) => state.form[formType]?.loading?.[endpoint] ?? false);
export const useFormLoading = ({ formType }: { formType: FormType }) =>
  useAppSelector((state: RootState) => state.form[formType]?.loading);
export const useFormSubmissionStatus = ({
  formType,
  endpoint = ApiEndpoint.DEFAULT,
}: {
  formType: FormType;
  endpoint?: ApiEndpoint;
}) => useAppSelector((state: RootState) => state.form[formType]?.submitting?.[endpoint] ?? false);
export const useFormSaveStatus = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].saveSuccess);
export const useFormSortStatus = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].sortLoading);
export const useFormAllList = ({ formType }: { formType: FormType }) =>
  useAppSelector((state: RootState) => state.form[formType].formList ?? EMPTY_ARRAY);
export const useFormList = ({
  formType,
  endpoint = ApiEndpoint.DEFAULT,
}: {
  formType: FormType;
  endpoint?: ApiEndpoint | DashboardEndpoint;
}) => useAppSelector((state: RootState) => state.form[formType].formList?.[endpoint] ?? EMPTY_ARRAY);
export const useFormContent = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].formContent);

export const useFormLastUpdated = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].lastUpdated);
export const useFormSortedList = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].sortingList);
export const useFormFilter = (formType: FormType) => useAppSelector((state: RootState) => state.form[formType].filter);
export const useFormFilterCriteria = ({
  formType,
  endpoint,
}: {
  formType: FormType;
  endpoint?: ApiEndpoint | DashboardEndpoint;
}) => useAppSelector((state: RootState) => state.form[formType].filter.filterCriteria);
export const useFormSelectedList = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].selectedIds);
export const useFormPagination = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].pagination);
export const useFormProcedure = (formType: FormType) =>
  useAppSelector((state: RootState) => state.form[formType].procedure);
export const useFormError = (formType?: FormType) =>
  useAppSelector((state: RootState) => (formType ? state.form[formType].error : null));
