export const dashboardGraphByteUnitConverter = (value: number) => {
  const units = [
    { unit: 'TB', divisor: 1e12 },
    { unit: 'GB', divisor: 1e9 },
    { unit: 'MB', divisor: 1e6 },
    { unit: 'KB', divisor: 1e3 },
    { unit: 'Bytes', divisor: 1 },
  ];

  const unit = units.find(({ divisor }) => value >= divisor) ?? { unit: 'Bytes', divisor: 1 };

  const dividedValue = value / unit.divisor;

  return { value: dividedValue, unit: unit.unit };
};
