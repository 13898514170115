import { ReactNode } from 'react';

export const capitalize = <T>(value: T | null | undefined): ReactNode => {
  if (typeof value === 'string') {
    return value.replace(/\b\w/g, (c) => c.toUpperCase());
  }
  if (value === null || value === undefined) {
    return '';
  }
  return value as ReactNode;
};

export const capitalizeFirstLetter = (text: string): string => {
  const match = text.match(/[a-zA-Z]/);
  if (!match) return text;

  const pos = match.index!;
  return text.slice(0, pos) + text[pos].toUpperCase() + text.slice(pos + 1).toLowerCase();
};
