import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframe for the pulse animation
const pulse = keyframes`
  0%, 100% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
`;

interface ContainerProps {
  $color: string;
  $size: number;
  className?: string;
}

interface DotProps {
  $index: number;
  $totalDots: number;
}

const Container = styled.div.attrs<ContainerProps>(({ className, $color, $size }) => ({
  className: className,
  style: {
    '--uib-size': `${$size}px`,
    '--uib-color': $color,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 'var(--uib-size)',
    width: 'var(--uib-size)',
  },
}))<ContainerProps>`
  --uib-speed: 0.9s;
`;

// Dot styled component
const Dot = styled.div<DotProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  transform: rotate(${(props) => (props.$index * 360) / props.$totalDots}deg);

  &::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: ${pulse} calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    animation-delay: calc(var(--uib-speed) * -${(props) => props.$index / props.$totalDots});
    transition: background-color 0.3s ease;
  }
`;

interface InitLoaderProps {
  color?: string;
  className?: string;
  size?: number;
  dot?: number;
}

export const InitLoader: FC<InitLoaderProps> = ({ color = 'white', size = 40, dot = 8, className }) => {
  return (
    <Container $color={color} $size={size} className={className}>
      {Array.from({ length: dot }).map((_, index) => (
        <Dot key={index} $totalDots={dot} $index={index + 1} />
      ))}
    </Container>
  );
};
