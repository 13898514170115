import { useTranslation } from 'react-i18next';

import { Badges } from '@components';
import { FormType, StatusType, Translation, certificateStatusType, verificationType } from '@enums';

interface RichTableCellDefaultProps<T> {
  data?: T;
  rawData: T;
  formType: FormType;
}

export const BadgeTableCell = <T,>({ data, rawData, formType }: RichTableCellDefaultProps<T>) => {
  const { t: tVerification } = useTranslation(Translation.Common, { keyPrefix: 'verificationStatus' });
  const { t: tcVerification } = useTranslation(Translation.Common, { keyPrefix: 'certificateStatus' });

  const isError =
    data === tVerification(verificationType.UNVERIFIED) || data === tcVerification(certificateStatusType.Expired);

  const isWarning =
    data === tcVerification(certificateStatusType.ExpiredSoon) ||
    data === tcVerification(certificateStatusType.PendingIssue);

  function getStatusType(isError: boolean, isWarning: boolean): StatusType {
    if (isError) {
      return StatusType.Error;
    } else if (isWarning) {
      return StatusType.Warning;
    } else {
      return StatusType.Success;
    }
  }

  return (
    <div className="h-full gap-x-2 text-black items-center hover:cursor-pointer">
      <Badges name={data} type={getStatusType(isError, isWarning)} />
    </div>
  );
};
