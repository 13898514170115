import i18n from 'i18next';

import { Column, Text } from '@components';
import { CellType } from '@enums';
import { capitalize, capitalizeFirstLetter, formatTableTimestamp, getColumnSizes } from '@utils';

export const getSslStatusLogTable = (): Column[] => [
  {
    label: i18n.t('configuration:tables.time'),
    format: (value) => {
      const formattedDate = formatTableTimestamp(value);

      return (
        <div className="flex flex-col space-y-1.5 flex-wrap">
          <Text $level={5} color="text-black" className="pt-1.5">
            {formattedDate}
          </Text>
        </div>
      );
    },

    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
    value: 'timestamp',
  },

  {
    label: i18n.t('configuration:tables.type'),
    value: 'type',
    multiLines: true,
    dotsTextOverflow: true,
    ...getColumnSizes(60),
    format: (value) => {
      return (
        <Text className="font-medium" color="text-black" $level={5}>
          {capitalize(value)}
        </Text>
      );
    },
  },
  {
    label: i18n.t('configuration:tables.messages'),
    value: 'message',
    ...getColumnSizes(240),
    multiLines: true,
    format: (value) => {
      return (
        <Text className="font-medium" color="text-black" $level={5}>
          {capitalizeFirstLetter(value)}
        </Text>
      );
    },
  },
];
