import { useEffect } from 'react';

export const useElementScrollLock = (id: string, isModalOpen: boolean) => {
  const toggleElementScroll = (id: string, shouldDisable: boolean) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.overflow = shouldDisable ? 'hidden' : '';
    }
  };

  useEffect(() => {
    toggleElementScroll(id, isModalOpen);

    return () => {
      toggleElementScroll(id, false);
    };
  }, [id, isModalOpen]);
};
