import { ApexOptions } from 'apexcharts';
import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { DashboardEndpoint } from '@enums';
import { useChartDimensions, useTimeAndDomainFilters } from '@hooks';
import { useFormList } from '@store';
import { convertGraphDateTimeFormat, formatNumberWithSeparator, formatYAxisValue, validateValueEqual } from '@utils';

export const CdnConfigurationDashboardQpsGraph = memo(({ payload }: any) => {
  const graphData = useFormList(payload);

  const { timeFilter } = useTimeAndDomainFilters({
    formType: payload.formType,
    endpoint: DashboardEndpoint.TOTAL_TRAFFIC,
  });

  const dimensionData = {
    mobile: { height: 250 },
    tablet: { height: 300 },
    desktop: { height: 450 },
    large: { height: 550 },
  };

  const { chartHeight } = useChartDimensions({ dimensionsData: dimensionData });

  const seriesData = useMemo(
    () =>
      (graphData ?? []).map(({ timestamp = 0, avg_qps = 0 }) => ({
        x: timestamp * 1000,
        y: Math.round(avg_qps * 100) / 100,
      })),
    [graphData],
  );

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        type: 'area',
        zoom: { enabled: false },
        stroke: { curve: 'smooth' },
        toolbar: { show: false },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: { enabled: true, delay: 150 },
          dynamicAnimation: { enabled: true, speed: 350 },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      dataLabels: { enabled: false },
      xaxis: {
        type: 'datetime',
        lines: { show: false },
        labels: {
          format: convertGraphDateTimeFormat(timeFilter),
          datetimeUTC: false,
        },
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: (value) =>
            validateValueEqual(value, 0)
              ? ''
              : formatNumberWithSeparator(formatYAxisValue({ value, allowFloating: true })),
        },
      },
      tooltip: {
        x: { format: 'dd MMM yyyy HH:mm' },
        y: { formatter: (value) => formatNumberWithSeparator(formatYAxisValue({ value, decimalPlaces: 2 })) },
      },
    }),
    [timeFilter, chartHeight],
  );

  const series = useMemo(() => [{ name: 'Avg QPS', data: seriesData }], [seriesData]);
  if (!timeFilter) return null;

  return (
    <motion.div
      className="flex justify-center -mx-2 mt-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ReactApexChart options={options} series={series} type="area" height={chartHeight} className="w-full" />
    </motion.div>
  );
});
