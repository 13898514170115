import { Options } from '@types';

export const convertEnumToObjectArray = (input: { [key: string]: string } | string[] | number[]): Options => {
  if (!input || typeof input !== 'object') return [];

  const values = Array.isArray(input) ? input : Object.values(input);

  return values.every((value) => typeof value === 'string' || typeof value === 'number')
    ? values.map((value) => ({ label: String(value), value }))
    : [];
};
