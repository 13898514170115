import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ServiceModalName } from '@enums';
import { useAppSelector } from '@hooks/storeHooks';
import type { ServiceModalPayload, ServiceModalSliceState } from '@types';

const initialState: ServiceModalSliceState = {};

export const serviceModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    addServiceModal: (
      state,
      { payload: { name, payload } }: PayloadAction<{ name: ServiceModalName; payload: ServiceModalPayload }>,
    ) => {
      state[name] = payload;
    },
    removeServiceModal: (state, { payload }: PayloadAction<ServiceModalName>) => {
      const { [payload]: _, ...rest } = state;
      return rest;
    },
  },
});

export const { addServiceModal, removeServiceModal } = serviceModalSlice.actions;

export const useServiceModal = (modalName: ServiceModalName) =>
  useAppSelector((state) => state.serviceModal[modalName]);
export const useAllServiceModal = () => useAppSelector((state) => state.serviceModal);
export const useHasServiceModal = () => useAppSelector((state) => !!Object.keys(state.serviceModal).length);

const serviceModalReducer = serviceModalSlice.reducer;
export default serviceModalReducer;
