import { breakpoints, useMediaQuery } from '@components';
import { useMemo } from 'react';

type Dimension = {
  height: number;
  yAxisWidth?: number;
  strokeWidth?: number;
};

type DimensionsConfig<T> = {
  mobile: Dimension & T;
  tablet: Dimension & T;
  desktop: Dimension & T;
  large: Dimension & T;
};

const DEFAULT_DIMENSIONS: Dimension = {
  height: 400,
  yAxisWidth: 120,
  strokeWidth: 0.2,
};

const QUERIES = {
  sm: `(max-width: ${breakpoints.sm}px)`,
  lg: `(max-width: ${breakpoints.lg}px)`,
  xl: `(max-width: ${breakpoints['2xl']}px)`,
} as const;

export const useChartDimensions = <T extends object>({ dimensionsData }: { dimensionsData: DimensionsConfig<T> }) => {
  const isBelowSm = useMediaQuery(QUERIES.sm);
  const isBelowLg = useMediaQuery(QUERIES.lg);
  const isBelow2xl = useMediaQuery(QUERIES.xl);

  return useMemo(() => {
    const config = isBelowSm
      ? dimensionsData.mobile
      : isBelowLg
        ? dimensionsData.tablet
        : isBelow2xl
          ? dimensionsData.desktop
          : dimensionsData.large;

    return {
      chartHeight: config.height ?? DEFAULT_DIMENSIONS.height,
      yAxisWidth: config.yAxisWidth ?? DEFAULT_DIMENSIONS.yAxisWidth,
      strokeWidth: config.strokeWidth ?? DEFAULT_DIMENSIONS.strokeWidth,
    };
  }, [isBelowSm, isBelowLg, isBelow2xl, dimensionsData]);
};
