import { CdnForm } from './CdnForm';

export enum CdnStatus {
  Active = 1,
  InActive = 0,
}

export interface CdnData {
  id: string;
  name: string;
  status: CdnStatus;
  allow_wildcard: boolean;
  allow_domain_count: number;
  allow_total_domain_count: number;
  allow_page_rules_count: number;
}

export const Cdn = () => {
  return <div className="flex flex-1 flex-col gap-8">{<CdnForm />}</div>;
};
