import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormField, PredefinedCards, Skeleton } from '@components';
import {
  Dictionaries,
  FormType,
  InputMod,
  InputType,
  StatusType,
  Translation,
  pageRulesRedirectOptionType,
} from '@enums';
import { useAppDispatch } from '@hooks';
import {
  addSnack,
  submitFormRequest,
  useFormContent,
  useFormError,
  useFormLoadingStatus,
  useFormSaveStatus,
  useFormSubmissionStatus,
} from '@store';
import { getOptionListFromCatalog } from '@utils';

interface PredefinedPageRulesViewProps {
  onFormikInstance: any;
}

export const PredefinedPageRulesForm = ({ onFormikInstance }: PredefinedPageRulesViewProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnPageRules;

  const { t: tToasts } = useTranslation(Translation.Configuration, { keyPrefix: 'toasts.predefinedPageRules' });
  const { t: tForms } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.pageRules' });

  const [showCurrentToast, setShowCurrentToast] = useState<boolean>(false);
  const [currentDataKey, setCurrentDataKey] = useState<any>(null);

  const pageRulesList = useFormContent(formType);
  const isDataLoading = useFormLoadingStatus({ formType });
  const isSubmitting = useFormSubmissionStatus({ formType });
  const submitError = useFormError(formType);
  const saveSuccess = useFormSaveStatus(formType);

  const initialPageRulesValues = {
    http_redirection: pageRulesRedirectOptionType.No_Redirection,
  };

  const initialValues = {
    http_redirection: pageRulesList?.http_redirection ?? initialPageRulesValues.http_redirection,
  };

  const handleSubmit = (values) => {
    dispatch(
      submitFormRequest({
        formType,
        formData: { application_id, ...values },
        globalOptions: {
          returnResult: true,
        },
      }),
    );
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      if (showCurrentToast && currentDataKey) {
        dispatch(addSnack({ type: StatusType.Success, message: tToasts(`${currentDataKey}Configured`) }));
        setShowCurrentToast(false);
        setCurrentDataKey(null);
      }
    }
  }, [application_id, currentDataKey, isSubmitting, saveSuccess, showCurrentToast, submitError]);

  const httpRedirectionOption = useMemo(() => getOptionListFromCatalog(Dictionaries.PageRulesRedirectOption), [tForms]);

  if (isDataLoading)
    return (
      <div className="space-y-4">
        {[...Array(1)].map((_, index) => (
          <Skeleton key={index} className="flex w-full rounded-2xl h-32" />
        ))}
      </div>
    );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => {
        if (onFormikInstance) onFormikInstance(formik);
        return (
          <PredefinedCards
            title={tForms('httpHttpsRedirection')}
            description={tForms('chooseHowYouWouldLikeToHnadleHTTPAndHTTPSRedirectionForYourSite')}
          >
            <FormField
              label={tForms('redirection')}
              name="http_redirection"
              inputType={InputType.Select}
              inputMod={InputMod.Filled}
              withoutLabel
              smallFont
              fullWidth
              loading={isSubmitting}
              placeholder={tForms('pleaseSelectYourRedirectDestination')}
              options={httpRedirectionOption}
            />
          </PredefinedCards>
        );
      }}
    </Formik>
  );
};
