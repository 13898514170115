import i18n from 'i18next';

import { InputHTMLAttributes } from 'react';

import { ConditionOperatorType, ConditionVariableType, Dictionaries, InputType, originType } from '@enums';
import { getOptionListFromCatalog } from '@utils';

interface Field extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  value?: any;
  emptyValue?: any;
  inputType?: InputType;
  option?: any[];
  format?: any;
  previousActionKey?: string;
  description?: string;
  hints?: string;
}

export const ConditionFieldInput = (actionKey: ConditionVariableType): Field[] => {
  const schemeOperator = [ConditionOperatorType.stringEqual, ConditionOperatorType.stringNotEqual] as string[];
  const schemeOption = getOptionListFromCatalog(Dictionaries.Operator)?.filter((option) =>
    schemeOperator.includes(option.value),
  );

  switch (actionKey) {
    case ConditionVariableType.URI:
      return [
        {
          name: 'operator',
          value: ConditionOperatorType.prefixMatches,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.Operator),
        },
        {
          name: 'values',
          value: [{ value: '' }],
          emptyValue: '',
          inputType: InputType.Text,
          placeholder: i18n.t(`validation:format.${actionKey}`),
        },
      ];
    case ConditionVariableType.UserAgent:
      return [
        {
          name: 'operator',
          value: ConditionOperatorType.stringContainsWord,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.Operator),
        },
        {
          name: 'values',
          value: [{ value: '' }],
          emptyValue: '',
          inputType: InputType.Text,
          placeholder: i18n.t(`validation:format.${actionKey}`),
        },
      ];
    case ConditionVariableType.ClientCountry:
    case ConditionVariableType.ClientAddress:
    case ConditionVariableType.HostName:
      return [
        {
          name: 'operator',
          value: ConditionOperatorType.stringEqual,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.Operator),
        },
        {
          name: 'values',
          value: [{ value: '' }],
          emptyValue: '',
          inputType: InputType.Text,
          placeholder: i18n.t(`validation:format.${actionKey}`),
        },
      ];
    case ConditionVariableType.Scheme:
      return [
        {
          name: 'operator',
          value: ConditionOperatorType.stringEqual,
          inputType: InputType.Select,
          option: schemeOption,
        },
        {
          name: 'values',
          value: [{ value: originType.HTTP }],
          emptyValue: originType.HTTP,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.OriginType),
        },
      ];

    default:
      return [];
  }
};
