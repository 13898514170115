import { combinedDomainValidationReg, domainValidationReg, ipValidationReg } from '@/regexp';
import i18n from 'i18next';
import * as Yup from 'yup';

export const domainFormSchema = ({ allowWildCard }) => {
  return Yup.object().shape({
    name: Yup.string().required(() => i18n.t('validation:domain.nameIsRequired')),
    domain: Yup.string()
      .required(() => i18n.t('validation:domain.domainIsRequired'))
      .matches(allowWildCard ? combinedDomainValidationReg : domainValidationReg, ({ value }) => {
        if (allowWildCard) {
          return value.startsWith('*')
            ? i18n.t('validation:format.wildCarddomain')
            : i18n.t('validation:format.domain');
        }
        return i18n.t('validation:format.domain');
      }),
    origin: Yup.string()
      .nullable()
      .matches(
        ipValidationReg,
        () => `${i18n.t('validation:origin.checkIpFormat')} (${i18n.t('validation:format.ip')})`,
      ),
    connect_timeout: Yup.number()
      .required(() => i18n.t('validation:route.connectionTimeOut.required'))
      .positive(() => i18n.t('validation:route.connectionTimeOut.MustBePositive'))
      .integer(() => i18n.t('validation:route.connectionTimeOut.MustBeInteger'))
      .min(1, () => i18n.t('validation:route.connectionTimeOut.MustMoreThan1'))
      .max(60, () => i18n.t('validation:route.connectionTimeOut.MustNotMoreThan', { amount: 60 })),
    send_timeout: Yup.number()
      .required(() => i18n.t('validation:route.sendTimeOut.required'))
      .positive(() => i18n.t('validation:route.sendTimeOut.MustBePositive'))
      .integer(() => i18n.t('validation:route.sendTimeOut.MustBeInteger'))
      .min(1, () => i18n.t('validation:route.sendTimeOut.MustMoreThan1'))
      .max(60, () => i18n.t('validation:route.sendTimeOut.MustNotMoreThan', { amount: 60 })),
    read_timeout: Yup.number()
      .required(() => i18n.t('validation:route.readTimeOut.required'))
      .positive(() => i18n.t('validation:route.readTimeOut.MustBePositive'))
      .integer(() => i18n.t('validation:route.readTimeOut.MustBeInteger'))
      .min(1, () => i18n.t('validation:route.readTimeOut.MustMoreThan1'))
      .max(1800, () => i18n.t('validation:route.readTimeOut.MustNotMoreThan', { amount: 1800 })),
  });
};
