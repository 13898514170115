import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormField, FormModal } from '@components';
import { sslFormSchema } from '@constants';
import {
  Dictionaries,
  FormActionType,
  FormType,
  InputType,
  Translation,
  columnDirection,
  sslCertificateType,
} from '@enums';
import { useAppDispatch } from '@hooks';
import { submitFormRequest, useCdnSummary, useFormContent, useFormProcedure, useFormSubmissionStatus } from '@store';
import { SSLConfigurationForm } from '@types';
import { checkSSLFormType, decrypt64Base, encrypt64Base, getOptionListFromCatalog, validateValueEqual } from '@utils';
import { useMemo } from 'react';

interface SSLModalProps {
  open?: boolean;
  onClose: () => void;
}

export const SSLModal = ({ open, onClose }: SSLModalProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnSSL;

  const currentForm = useFormContent(formType);
  const currentProcedure = useFormProcedure(formType);
  const isSubmitting = useFormSubmissionStatus({ formType });

  const { domain } = useCdnSummary();

  const verifiedDomains = useMemo(
    () => domain.filter(({ cname_verified }) => cname_verified).map(({ domain }) => domain),
    [domain],
  );

  const { normalDomains } = useMemo(
    () => ({
      wildcardDomains: verifiedDomains.filter((d: string) => d.startsWith('*.')),
      normalDomains: verifiedDomains.filter((d: string) => !d.startsWith('*.')),
    }),
    [verifiedDomains],
  );
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.ssl' });
  const { t: tModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.ssl' });
  const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.ssl' });

  const isEdit = validateValueEqual(currentProcedure, FormActionType.Edit);

  const handleSubmit = (values: SSLConfigurationForm, helpers: FormikHelpers<SSLConfigurationForm>) => {
    const { type, domain, public_key, private_key, ...updatedValues } = values;

    const validDomains = domain.filter((d) => d.trim() !== '');

    const encryptedPrivateKey = encrypt64Base(private_key);
    const encryptedPublicKey = encrypt64Base(public_key);
    const formData = {
      ...updatedValues,
      type: type,
      domain: validDomains,
      public_key: encryptedPublicKey,
      private_key: encryptedPrivateKey,
    };
    dispatch(
      submitFormRequest({
        formType,
        formData: isEdit ? formData : { application_id, ...formData, ...checkSSLFormType(formData) },
        ...(isEdit && {
          params: {
            content_id: currentForm?.id,
          },
        }),
        globalOptions: {
          refetch_id: application_id,
        },
      }),
    );
  };

  const initialValues: SSLConfigurationForm = {
    name: currentForm?.name ?? '',
    type: currentForm?.type ?? normalDomains.length > 0 ? sslCertificateType.letsEncrypt : sslCertificateType.manual,
    public_key: decrypt64Base(currentForm?.public_key) ?? currentForm?.public_key ?? '',
    private_key: decrypt64Base(currentForm?.private_key) ?? currentForm?.private_key ?? '',
    domain: currentForm?.domain ?? [],
  };

  const SSLTypeOption = useMemo(
    () =>
      normalDomains.length
        ? getOptionListFromCatalog(Dictionaries.SSLType)
        : getOptionListFromCatalog(Dictionaries.SSLType, [0]),
    [tForm, normalDomains],
  );

  if (!open) return;

  return (
    <Formik initialValues={initialValues} validationSchema={sslFormSchema} onSubmit={handleSubmit}>
      {({ values }) => {
        return (
          <FormModal
            title={tModal(isEdit ? 'editCertificate' : 'addCertificate')}
            description={tModal(isEdit ? 'editSSLDescription' : 'addNewSSLDescription')}
            onClose={onClose}
            formType={formType}
            open={open}
            size="lg"
            confirmButton={{
              children: tButton(isEdit ? 'editSSL' : 'addSSL'),
              disabled: isEdit && currentForm?.type === sslCertificateType.letsEncrypt,
            }}
          >
            <FormField
              label={tForm('certificateName')}
              name="name"
              disabled={isSubmitting || (isEdit && currentForm?.type === sslCertificateType.letsEncrypt)}
              required
              smallFont
              direction={columnDirection.Row}
              placeholder={tForm('enterYourCertificateName')}
              fullWidth
            />
            <FormField
              label={tForm('sslType')}
              name="type"
              inputType={InputType.Select}
              direction={columnDirection.Row}
              fullWidth
              smallFont
              disabled={isEdit}
              placeholder={tForm('selectYourSSLType')}
              options={SSLTypeOption}
            />

            {validateValueEqual(values.type, sslCertificateType.manual) && (
              <>
                <FormField
                  label={tForm('publicKey')}
                  name="public_key"
                  placeholder={tForm('enterYourPublicKey')}
                  direction={columnDirection.Row}
                  disabled={isSubmitting}
                  uploadLabel={tForm('uploadPublicKeyFile')}
                  textInputClassName="padded-scrollbar-track"
                  inputType={InputType.Area}
                  fullWidth
                  smallFont
                  required
                />
                <FormField
                  label={tForm('privateKey')}
                  name="private_key"
                  placeholder={tForm('enterYourPrivateKey')}
                  disabled={isSubmitting}
                  direction={columnDirection.Row}
                  inputType={InputType.Area}
                  textInputClassName="padded-scrollbar-track"
                  uploadLabel={tForm('uploadPrivateKeyFile')}
                  fullWidth
                  smallFont
                  required
                />
              </>
            )}
            {validateValueEqual(values.type, sslCertificateType.letsEncrypt) && (
              <FormField
                label={tForm('domainNameList')}
                name="domain"
                emptyData={''}
                inputType={InputType.MultipleSelectInput}
                smallFont
                disabled={isSubmitting || (isEdit && currentForm?.type === sslCertificateType.letsEncrypt)}
                placeholder={tForm('enterYourDomainNameList')}
                fullWidth
                options={normalDomains}
                direction={columnDirection.Row}
                maxSelections={5}
                disabledSearch
              />
            )}

            {isEdit && currentForm?.type === sslCertificateType.letsEncrypt && (
              <div className="ring-1 ring-red-400 bg-red-50 px-2.5 py-1.5 rounded-md text-red-400">
                <article className="text-us">
                  We apologize for the inconvenience, but the Manual Certificate Type is currently unable to be updated
                  due to technical issues with our service. We are working diligently to resolve this matter and
                  appreciate your patience.
                </article>
              </div>
            )}
          </FormModal>
        );
      }}
    </Formik>
  );
};
