import { Reducer } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import cdnReducer from './cdnSlice';
import formReducer from './form/formSlice';
import initReducer from './initSlice';
import profileReducer from './profileSlice';
import serviceModalReducer from './serviceModalSlice';
import snackReducer from './snackSlice';
import systemReducer from './systemSlice';
import userReducer from './userSlice';
import workspaceReducer from './workspace/workspaceOptionsSlice';

export const rootReducers: { [key: string]: Reducer } = {
  init: initReducer,
  user: userReducer,
  auth: authReducer,
  system: systemReducer,
  snack: snackReducer,
  cdn: cdnReducer,
  form: formReducer,
  profilesettings: profileReducer,
  serviceModal: serviceModalReducer,
  workspace: workspaceReducer,
};
