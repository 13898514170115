import { format } from 'date-fns';
import i18n from 'i18next';

import { Badge, Column } from '@components';
import { CellType } from '@enums';
import { capitalize, getColumnSizes } from '@utils';

export const getDashboardTrafficLogsColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', multiLines: true, ...getColumnSizes(40) },
  {
    label: i18n.t('configuration:tables.logInformation'),
    value: '',
    multiLines: true,
    cellType: CellType.Custom,
    format: (_value, rowData) => {
      const logEntries = [
        { label: i18n.t('configuration:tables.ipAddress'), value: rowData?.ip_address ?? '-' },
        { label: i18n.t('configuration:tables.host'), value: rowData?.host ?? '-' },
        {
          label: i18n.t('configuration:tables.request'),
          value: rowData?.request_method && rowData?.uri ? `${rowData?.request_method} ${rowData?.uri}` : '-',
        },
        { label: i18n.t('configuration:tables.user-agent'), value: rowData?.user_agent ?? '-' },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <span className="text-black font-medium text-sm">{entry.label}</span>
              <span className="text-zinc-500 text-xs w-full whitespace-break-spaces">{entry.value}</span>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(250),
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'limit_action',
    format: (value) => {
      return (
        <div className="h-full">
          <Badge
            color={value === 'block' ? 'red' : value === 'challenge' ? 'cyan' : 'yellow'}
            className="self-start px-2.5 py-1"
          >
            {i18n.t(`common:tables.${value && value !== '' ? value : 'noActionTaken'}`)}
          </Badge>
        </div>
      );
    },
    cellType: CellType.Custom,
    multiLines: true,
    dotsTextOverflow: true,
    ...getColumnSizes(90),
  },
  {
    label: i18n.t('configuration:tables.logDate'),
    value: 'timestamp',
    format: (value) => {
      const date = new Date(value * 1000);
      const formattedDate = format(date, 'dd/MM/yyyy hh:mm a');

      return (
        <div className="flex flex-col space-y-4 pt-1.5 h-full">
          <div className="flex flex-col space-y-1.5 flex-wrap">
            <span className="text-zinc-500 text-xs w-full whitespace-break-spaces">{formattedDate}</span>
          </div>
        </div>
      );
    },

    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
  },
];

//Atack Logs Table
export const getDashboardAttackLogsColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', multiLines: true, ...getColumnSizes(40) },
  {
    label: i18n.t('configuration:tables.logInformation'),
    value: '',
    multiLines: true,
    cellType: CellType.Custom,
    format: (_value, rowData) => {
      const logEntries = [
        { label: i18n.t('configuration:tables.ipAddress'), value: rowData?.remote_addr ?? '-' },
        { label: i18n.t('configuration:tables.host'), value: rowData?.host ?? '-' },
        {
          label: i18n.t('configuration:tables.request'),
          value: `${rowData?.request ?? '-'}`,
        },
        { label: i18n.t('configuration:tables.header'), value: rowData?.header ?? '-' },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <span className="text-black font-medium text-sm">{entry.label}</span>
              <span className="text-zinc-500 text-xs w-full whitespace-break-spaces">{entry.value}</span>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(180),
  },
  {
    label: i18n.t('configuration:tables.reason'),
    value: 'matches',
    multiLines: true,
    cellType: CellType.Custom,
    format: (value) => {
      const ruleName = value.map(({ group }) => group);
      const message = value.map(({ message }) => message);
      const keywordGroup = value.map(({ matches = [] }: { matches: string[] }) =>
        matches.map((item) => (item?.toString() === '0' ? '' : item)),
      );

      const logEntries = [
        { label: i18n.t('configuration:tables.rule'), value: ruleName },
        { label: i18n.t('configuration:tables.keyword'), value: keywordGroup },
        {
          label: i18n.t('configuration:tables.messages'),
          value: message,
        },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text h-full">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <span className="text-black font-medium text-sm">{entry.label}</span>
              <span className="text-zinc-500 text-xs w-full whitespace-break-spaces">{entry.value}</span>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(180),
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'action',
    format: (value = '') => (
      <div className="h-full">
        <Badge color={value === 'close-connection' ? 'red' : 'green'} className="self-start px-2.5 py-1">
          {value ? capitalize(value) : capitalize('Close Connection')}
        </Badge>
      </div>
    ),
    cellType: CellType.Custom,
    multiLines: true,
    dotsTextOverflow: true,
    ...getColumnSizes(180),
  },
  {
    label: i18n.t('configuration:tables.logDate'),
    value: 'matches',
    format: (value) => {
      const date = new Date();
      const formattedDate = format(date, 'dd/MM/yyyy hh:mm a');

      return (
        <div className="flex flex-col space-y-4 pt-1.5 h-full">
          <div className="flex flex-col space-y-1.5 flex-wrap">
            <span className="text-zinc-500 text-xs w-full whitespace-break-spaces">{formattedDate}</span>
          </div>
        </div>
      );
    },

    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
  },
];
