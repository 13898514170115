import { useMemo } from 'react';

import { DraggableTable } from '@components';
import { getSslStatusLogTable } from '@constants/components/ssLStatusLogTable';
import { ApiEndpoint, FormType } from '@enums';
import { useFetchFormList } from '@hooks';
import { useFormList } from '@store';

export const SslStatusLog = ({ sslId }: { sslId: number }) => {
  const formType = FormType.CdnSSL;

  const sslStatusLogTableColumns = useMemo(getSslStatusLogTable, []);

  const SSLLog = useFormList({ formType, endpoint: ApiEndpoint.LOG });

  useFetchFormList({
    formType,
    params: { content_id: sslId },
    globalOptions: {
      endpoint: ApiEndpoint.LOG,
      endPath: true,
    },
  });
  return <DraggableTable rows={SSLLog} columns={sslStatusLogTableColumns} isDataLoading={false} formType={formType} />;
};
