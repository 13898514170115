import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAllStorageData } from '@/storage';
import { FormField, FormModal, Text } from '@components';
import { applicationFormSchema } from '@constants';
import { columnDirection, Dictionaries, FormType, InputType, originType, Translation } from '@enums';
import { useAppDispatch } from '@hooks';
import { submitFormRequest, useFormContent, useFormError } from '@store';
import { getOptionListFromCatalog, validateValueEqual } from '@utils';

interface CdnApplicationModalProps {
  open: boolean;
  onClose: () => void;
}

export const CdnApplicationModal = ({ open, onClose }: CdnApplicationModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formType = FormType.Cdn;

  const { t: tCdnApplicationForm } = useTranslation(Translation.Form, { keyPrefix: 'addApplication.content' });
  const { t: tModal } = useTranslation(Translation.Cdn, { keyPrefix: 'modals' });
  const { t: tValidation } = useTranslation(Translation.Validation, { keyPrefix: 'format' });
  const { t: tCommonIp } = useTranslation(Translation.Common, { keyPrefix: 'ip' });
  const { t: tOriginForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.origin' });

  const { current_workspace_id: workspace_id } = getAllStorageData();

  const currentCdnData = useFormContent(FormType.Cdn);
  const submitError = useFormError(FormType.Cdn);

  const handleSubmit = (values: any) => {
    const { domain, name, type, origin, verify_ssl_cert } = values;
    const formData = {
      domain,
      name,
      ...(origin && {
        type,
        origin,
        verify_ssl_cert: type === originType.HTTPS ? verify_ssl_cert : false,
      }),
      workspace_id: workspace_id,
    };
    dispatch(submitFormRequest({ formType, formData }));
  };

  const navigateToApplication = () => {
    currentCdnData?.id && navigate(`${currentCdnData.id}/configuration`);
  };

  const initialValues: any = {
    name: '',
    domain: '',
    type: originType.HTTPS,
    origin: '',
    verify_ssl_cert: true,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={applicationFormSchema} onSubmit={handleSubmit}>
      {({ values, touched, errors }) => {
        return (
          <FormModal
            title={tModal('addNewApplication')}
            description={tModal('addNewApplicationDescription')}
            onClose={onClose}
            submitError={submitError}
            formType={formType}
            open={open}
            additionalReturnFunction={navigateToApplication}
            size="lg"
            confirmButton={{ children: tModal('addNewApplication') }}
          >
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('applicationName')}
              name="name"
              smallFont
              placeholder={tCdnApplicationForm('enterYourApplicationName')}
              isCorrect={touched.name && !errors.name}
              required
              fullWidth
            />
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('domainName')}
              name="domain"
              hints={tValidation('domain')}
              smallFont
              placeholder={tCdnApplicationForm('enterYourDomain')}
              required
              fullWidth
            />
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('originType')}
              className={'text-gray-400'}
              name="type"
              smallFont
              inputType={InputType.Select}
              fullWidth
              placeholder={tCdnApplicationForm('selectYourOriginType')}
              options={getOptionListFromCatalog(Dictionaries.OriginType)}
            />
            <FormField
              direction={columnDirection.Row}
              label={
                <Text $level={5} $customizeColor className="text-gray-700 text-left font-normal leading-6">
                  {`${tCdnApplicationForm('origin')}`}
                  <span className="text-gray-400 text-us pl-2"> {`(${tCommonIp('ipv4Address')})`}</span>
                </Text>
              }
              smallFont
              hints={tValidation('ip')}
              name="origin"
              placeholder={tCdnApplicationForm('enterYourOrigin')}
              fullWidth
            />
            {validateValueEqual(values.type, originType.HTTPS) && (
              <FormField
                label={tOriginForm('verifySSL')}
                name="verify_ssl_cert"
                inputType={InputType.Switch}
                direction={columnDirection.Row}
                smallFont
                mobileTextInput="justify-between"
              />
            )}
          </FormModal>
        );
      }}
    </Formik>
  );
};
