import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getSSLCertificateColumns } from '@constants';
import { FormListLayout } from '@containers';
import { FormActionType, FormType, ServiceModalName, StatusType, Translation } from '@enums';
import { useAppDispatch, useFormHandlers } from '@hooks';
import { addServiceModal, deleteFormContent, useFormSubmissionStatus } from '@store';

import { SSLModal } from './sslModal';

export const SSLForm = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnSSL;

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.ssl' });
  const { t: tButtons } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.ssl' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });
  const { t: tModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals' });
  const { t: tSSLModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.ssl' });
  const { t: tCommon } = useTranslation(Translation.Common);

  const [addSSLModal, setAddSSLModal] = useState<boolean>(false);

  const isSSLSubmitting = useFormSubmissionStatus({ formType });

  const sslCertificateTableColumns = useMemo(getSSLCertificateColumns, [tTables]);

  const sslFormHandlers = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Create,
        function: () => setAddSSLModal(true),
        label: tButtons('addNewCertificate'),
      },
      {
        type: FormActionType.Delete,
        function: (rowData) => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.ConfirmModal,
              payload: {
                label: {
                  title: tSSLModal('sslDelete'),
                  text: tModal('areYouSureDelete', { domain: rowData?.name }),
                  confirmButton: tCommon('button.delete'),
                },
                option: {
                  modalType: StatusType.Delete,
                  button: {
                    loading: isSSLSubmitting,
                  },
                },
                callbackFunction: {
                  submit: () =>
                    dispatch(
                      deleteFormContent({
                        formType,
                        params: {
                          content_id: rowData?.id,
                          application_id: application_id,
                        },
                        globalOptions: {
                          refetch_id: application_id,
                        },
                      }),
                    ),
                },
              },
            }),
          );
        },
      },
    ],
  });
  return (
    <FormListLayout
      label={{
        title: tTabs('certificateList'),
      }}
      tableData={{
        columns: sslCertificateTableColumns,
        actionHandlers: sslFormHandlers,
        hasChildren: true,
        formType,
      }}
    >
      {addSSLModal && <SSLModal open={addSSLModal} onClose={() => setAddSSLModal(false)} />}
    </FormListLayout>
  );
};
