import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState, useAppSelector } from '@hooks';
import type { UserSliceState } from '@types';

const userInitialState: UserSliceState = {
  isLoading: false,
  user: [],
  error: null,
  workSpaceId: null,
  workSpaceList: [],
  workSpaceError: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    getUserSummary: (state) => {
      state.isLoading = true;
    },
    getUserSummarySuccess: (state, { payload }: PayloadAction<any>) => {
      state.user = payload;
      state.isLoading = false;
    },
    getUserSummaryError: (state, { payload }: PayloadAction<Error>) => {
      state.error = payload;
      state.isLoading = false;
    },
    clearUserDetails: (state) => {
      state = userInitialState;
    },
  },
});

export const { getUserSummary, getUserSummarySuccess, getUserSummaryError, clearUserDetails } = userSlice.actions;

export const useUserLoading = () => useAppSelector((state: RootState) => state.user.isLoading);
export const useUserError = () => useAppSelector((state: RootState) => state.user.error);
export const useUserDetails = () => useAppSelector((state: RootState) => state.user.user);
export const useUserRoles = () => useAppSelector((state) => state.user?.roles || '');
export const useUserPermissions = () => {
  const permissions = useAppSelector((state) => state.user.user?.permissions);

  return useMemo(() => {
    return permissions ?? [];
  }, [permissions]);
};

const userReducer = userSlice.reducer;
export default userReducer;
