import { ChevronRightIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import { Button } from '@components';
import { VariantType } from '@enums';
import { useBreadcrumbItems } from '@hooks';
import { useCdnSummary } from '@store';

export const NavigatorButton = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { t: translate } = useTranslation('common');

  const cdnSummary = useCdnSummary();

  const breadcrumbItems = useBreadcrumbItems(pathname, cdnSummary, translate);

  const generateHref = (index: number) =>
    `/${breadcrumbItems
      .slice(0, index + 1)
      .map((bi) => bi.id ?? bi.name)
      .join('/')}`;

  return (
    <Fragment>
      {breadcrumbItems.map((item, index) => (
        <li key={index} className="flex items-center gap-x-3">
          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          <Button
            onClick={() => navigate(generateHref(index))}
            withoutPadding
            rounded="rounded-none"
            variant={VariantType.Transparent}
            disabled={index === breadcrumbItems.length - 1}
            className={`text-sm ${index === breadcrumbItems.length - 1 ? 'text-gray-400 font-medium' : 'font-normal text-gray-600 hover:font-medium hover:text-gray-500'} py-0.5`}
          >
            {item.name}
          </Button>
        </li>
      ))}
    </Fragment>
  );
};
