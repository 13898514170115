import jwt_decode from 'jwt-decode';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import { accessToken } from '@/storage';
import { STORAGE_KEYS, TOKEN_MANAGER_CONSTANTS } from '@constants';
import { logOut, updateToken } from '@store';
import { useAppDispatch } from '@hooks';

interface DecodedToken {
  exp: number;

  [key: string]: unknown;
}

export const useTokenManager = () => {
  const dispatch = useAppDispatch();
  const mounted = useRef(true);
  const isRefreshing = useRef(false);
  const retryCount = useRef(0);
  const checkInterval = useRef<NodeJS.Timeout>();
  const initialCheckTimeoutRef = useRef<NodeJS.Timeout>();
  const retryTimeoutRef = useRef<NodeJS.Timeout>();

  const getRandomDelay = () => {
    return Math.floor(
      Math.random() * (TOKEN_MANAGER_CONSTANTS.MAX_WAKE_DELAY - TOKEN_MANAGER_CONSTANTS.MIN_WAKE_DELAY) +
        TOKEN_MANAGER_CONSTANTS.MIN_WAKE_DELAY,
    );
  };

  const getLastRefreshTime = () => {
    const lastRefresh = localStorage.getItem(STORAGE_KEYS.LAST_REFRESH);
    return lastRefresh ? parseInt(lastRefresh, 10) : Date.now();
  };

  const handleLogout = useCallback(() => {
    localStorage.setItem(STORAGE_KEYS.LOGOUT, 'true');
    dispatch(logOut());
  }, [dispatch]);

  const cleanup = useCallback(() => {
    if (checkInterval.current) {
      clearInterval(checkInterval.current);
      checkInterval.current = undefined;
    }

    if (retryTimeoutRef.current) {
      clearTimeout(retryTimeoutRef.current);
      retryTimeoutRef.current = undefined;
    }

    if (initialCheckTimeoutRef.current) {
      clearTimeout(initialCheckTimeoutRef.current);
      initialCheckTimeoutRef.current = undefined;
    }

    isRefreshing.current = false;
    retryCount.current = 0;
  }, []);

  const shouldRefreshToken = useCallback(() => {
    try {
      const token = accessToken.getItem();
      if (!token) return false;

      const decodedToken = jwt_decode<DecodedToken>(token);
      const timeToExpiry = decodedToken.exp * 1000 - Date.now();

      return timeToExpiry <= TOKEN_MANAGER_CONSTANTS.REFRESH_THRESHOLD;
    } catch {
      return false;
    }
  }, []);

  const refreshToken = useCallback(
    (skipDelayCheck = false) => {
      if (isRefreshing.current || !mounted.current) return;

      try {
        isRefreshing.current = true;

        if (!skipDelayCheck) {
          const timeSinceLastRefresh = Date.now() - getLastRefreshTime();
          if (timeSinceLastRefresh < TOKEN_MANAGER_CONSTANTS.MIN_WAKE_DELAY) {
            return;
          }
        }

        if (!shouldRefreshToken()) return;

        dispatch(updateToken());
        retryCount.current = 0;
      } catch (error) {
        console.error('Token refresh failed:', error);

        if (retryCount.current < TOKEN_MANAGER_CONSTANTS.MAX_RETRIES) {
          retryCount.current++;
          retryTimeoutRef.current = setTimeout(refreshToken, 1000 * Math.pow(2, retryCount.current));
        } else {
          handleLogout();
        }
      } finally {
        isRefreshing.current = false;
      }
    },
    [dispatch, handleLogout, shouldRefreshToken],
  );

  const debouncedRefreshToken = useCallback(debounce(refreshToken, 250), [refreshToken]);
  const checkTokenStatus = useCallback(() => {
    if (!mounted.current) return;

    // Quick check before doing heavy operations
    const token = accessToken.getItem();
    if (!token) return;

    // Use requestIdleCallback if available, otherwise fallback to setTimeout
    const scheduleRefresh = window.requestIdleCallback || setTimeout;
    scheduleRefresh(() => {
      if (shouldRefreshToken()) {
        debouncedRefreshToken();
      }
    });
  }, [shouldRefreshToken, debouncedRefreshToken]);

  useEffect(() => {
    mounted.current = true;

    // Store the initial timeout reference
    initialCheckTimeoutRef.current = setTimeout(() => {
      checkTokenStatus();
      checkInterval.current = setInterval(checkTokenStatus, TOKEN_MANAGER_CONSTANTS.CHECK_INTERVAL);
    }, getRandomDelay());

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setTimeout(() => {
          refreshToken(false);
        }, getRandomDelay());
      }
    };

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'access_token' && !e.newValue) {
        handleLogout();
      }
      if (e.key === STORAGE_KEYS.LOGOUT && e.newValue === 'true') {
        cleanup();
        dispatch(logOut());
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('storage', handleStorageChange);

    return () => {
      mounted.current = false;
      cleanup();
      debouncedRefreshToken.cancel();
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [checkTokenStatus, dispatch, debouncedRefreshToken, cleanup, handleLogout]);

  return null;
};
