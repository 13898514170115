import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components';
import { Translation } from '@enums';
import { useFormList, useFormLoadingStatus } from '@store';
import { formatNumberWithSeparator } from '@utils';

import WorldMap from '@components/worldMap/worldMap';

export const MapGraph = memo(({ payload }: any) => {
  const { t: tModals } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.dashboard' });
  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.dashboard' });

  const mapData = useFormList(payload);
  const mapLoading = useFormLoadingStatus(payload);

  const totalTraffic = useMemo(
    () => formatNumberWithSeparator(mapData.reduce((sum, item) => sum + (item.count || 0), 0)),
    [mapData],
  );

  return (
    <>
      <div className="flex flex-col gap-3 px-2">
        <motion.div className="font-medium text-2xl text-theme-primary-main">
          <Text $level={3} $customizeColor>
            {tModals('trafficMap')}
          </Text>
        </motion.div>
        <motion.div animate={{ x: 0 }} transition={{ delay: 0.2 }} className="font-medium text-zinc-500 text-sm">
          <Text $level={5} $customizeColor>
            {tTabs('theApplicationHassRecordedAToTalOfNumberAccossAllRegions', {
              formattedcount: totalTraffic,
            })}
          </Text>
        </motion.div>
      </div>
      <motion.div
        className="gap-6 flex flex-col pt-8 pb-2 px-2"
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <WorldMap data={mapData} backgroundColor="#f0f0f0" mapLoading={mapLoading} />
      </motion.div>
    </>
  );
});
