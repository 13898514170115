import { HasId } from '@types';

export function checkIdProperty(obj: any): obj is HasId {
  return 'id' in obj || false;
}

export function checkNameProperty(obj: any): obj is { name: string } {
  return 'name' in obj || false;
}

export function checkStatusProperty(obj: any): obj is { status: string } {
  return 'status' in obj || false;
}
export function checkCnameProperty(obj: any): obj is { cname_verified: string } {
  return 'cname_verified' in obj || false;
}
