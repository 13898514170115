import { useField } from 'formik';

export interface Option {
  label: string;
  value: string | number;
}

interface UseSelectBoxProps {
  name: string;
  options: Option[];
}

export const useSelectBox = ({ name, options }: UseSelectBoxProps) => {
  const [field, meta, helpers] = useField(name);
  const selected = options.find((option) => option.value?.toString() === field.value?.toString());
  const setValue = (value: string | number) => {
    helpers.setValue(value);
  };
  return {
    field,
    meta,
    selected,
    setValue,
  };
};
