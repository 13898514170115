import clsx from 'clsx';
import { HTMLAttributes, memo, PropsWithChildren, ReactNode } from 'react';

import { Subheading, Text } from '@components';
import { validateValueEqual } from '@utils';

interface FormTableRowProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string | ReactNode;
}

export const FormTableRow = memo(
  ({ title, description, className = '', children }: PropsWithChildren<FormTableRowProps>) => {
    return (
      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-7">
        <div className="space-y-3 col-span-3">
          <Subheading level={4}>{title}</Subheading>
          {validateValueEqual(typeof description, 'string') ? (
            <Text $level={4} className="font-light leading-5">
              {description}
            </Text>
          ) : (
            description
          )}
        </div>
        <div className={clsx('flex flex-1 gap-4 flex-col col-span-4', className)}>{children}</div>
      </section>
    );
  },
);
