import i18n from 'i18next';
import * as Yup from 'yup';

import { domainValidationReg, ipValidationReg, urlRegexp } from '@/regexp';
import { CountryCode } from '@components/worldMap/components';
import { ConditionOperatorType, ConditionVariableType } from '@enums';

const isUsingOperatorSchema = (operator) =>
  [
    ConditionOperatorType.notPrefixMatches,
    ConditionOperatorType.prefixMatches,
    ConditionOperatorType.stringNotEqual,
    ConditionOperatorType.stringEqual,
  ].includes(operator);

const variableValidateSchema = (field, operator, optionList) => {
  switch (field) {
    case ConditionVariableType.URI:
      return isUsingOperatorSchema(operator)
        ? Yup.string().matches(urlRegexp, () => i18n.t(`validation:format.${field}`))
        : Yup.string();
    case ConditionVariableType.HostName:
      return isUsingOperatorSchema(operator)
        ? Yup.string()
            .matches(domainValidationReg, () => i18n.t(`validation:format.${field}`))
            .test('domain-validation', i18n.t('validation:route.hostnameNotMatch'), function (hostname) {
              const validateDomain = (allowedDomain: string) => {
                if (!allowedDomain.startsWith('*.')) {
                  return allowedDomain === hostname;
                }

                const baseDomain = allowedDomain.substring(1);
                const isValidSubdomain =
                  hostname?.endsWith(baseDomain) && hostname.split('.').length === allowedDomain.split('.').length;

                return isValidSubdomain;
              };

              const isValidHostname = optionList.some(({ value }) => validateDomain(value));

              return (
                isValidHostname ||
                this.createError({
                  message: i18n.t('validation:route.hostnameNotMatch'),
                })
              );
            })
        : Yup.string();
    case ConditionVariableType.ClientAddress:
      return isUsingOperatorSchema(operator)
        ? Yup.string().matches(ipValidationReg, () => i18n.t(`validation:format.${field}`))
        : Yup.string();
    case ConditionVariableType.ClientCountry:
      return isUsingOperatorSchema(operator)
        ? Yup.string().test(
            'is-valid-country-code',
            () => i18n.t(`validation:format.${field}`),
            (value) => {
              if (!value) return true; // Allow empty values, adjust if needed
              return Object.values(CountryCode).includes(value as CountryCode);
            },
          )
        : Yup.string();
    default:
      return Yup.string();
  }
};

export const routeFormSchema = ({ optionList }) => {
  return Yup.object().shape({
    name: Yup.string().required(() => i18n.t('validation:route.ruleNameRequired')),
    origin: Yup.string().required(() => i18n.t('validation:route.originServerRequired')),
    conditions: Yup.array().of(
      Yup.object().shape({
        field: Yup.string(),
        argument: Yup.string().nullable(),
        operator: Yup.string(),
        values: Yup.array().of(
          Yup.object().shape({
            value: Yup.string().test({
              name: 'conditions-value-validation',
              test: async function (value) {
                if (!value || value.trim() === '') {
                  return this.createError({ message: i18n.t('validation:valueRequired') });
                }

                const indexString = this.path.split('.')[0];
                const index = parseInt(RegExp(/\d+/).exec(indexString)?.[0] ?? '');
                const { field, operator } = this?.options?.context?.conditions[index] ?? {};

                if (field && operator) {
                  const variableSchema = variableValidateSchema(field, operator, optionList);
                  try {
                    await variableSchema.validate(value, { context: this.options.context });
                    return true;
                  } catch (error: any) {
                    return this.createError({ message: error.message });
                  }
                } else {
                  return true;
                }
              },
            }),
          }),
        ),
      }),
    ),
    connect_timeout: Yup.number()
      .required(() => i18n.t('validation:route.connectionTimeOut.required'))
      .positive(() => i18n.t('validation:route.connectionTimeOut.MustBePositive'))
      .integer(() => i18n.t('validation:route.connectionTimeOut.MustBeInteger'))
      .min(1, () => i18n.t('validation:route.connectionTimeOut.MustMoreThan1'))
      .max(60, () => i18n.t('validation:route.connectionTimeOut.MustNotMoreThan', { amount: 60 })),
    send_timeout: Yup.number()
      .required(() => i18n.t('validation:route.sendTimeOut.required'))
      .positive(() => i18n.t('validation:route.sendTimeOut.MustBePositive'))
      .integer(() => i18n.t('validation:route.sendTimeOut.MustBeInteger'))
      .min(1, () => i18n.t('validation:route.sendTimeOut.MustMoreThan1'))
      .max(60, () => i18n.t('validation:route.sendTimeOut.MustNotMoreThan', { amount: 60 })),
    read_timeout: Yup.number()
      .required(() => i18n.t('validation:route.readTimeOut.required'))
      .positive(() => i18n.t('validation:route.readTimeOut.MustBePositive'))
      .integer(() => i18n.t('validation:route.readTimeOut.MustBeInteger'))
      .min(1, () => i18n.t('validation:route.readTimeOut.MustMoreThan1'))
      .max(1800, () => i18n.t('validation:route.readTimeOut.MustNotMoreThan', { amount: 1800 })),
  });
};
