export enum ConditionVariableType {
  HostName = 'host',
  URI = 'uri',
  URIArgument = 'uriArgument',
  RequestMethod = 'requestMethod',
  RequestHeader = 'requestHeader',
  QueryString = 'query_string',
  Scheme = 'scheme',
  FirstAddress = 'firstAddress',
  LastAddress = 'lastAddress',
  UserAgent = 'user-agent',
  UserAgentIsMobile = 'userAgentIsMobile',
  ClientContinent = 'clientContinent',
  ClientCountry = 'client-country',
  UploadedFileExtensionsMatchFileContent = 'uploadedFileExtensionsMatchFileContent',
  UploadedFileExtensions = 'uploadedFileExtensions',
  ClientAddress = 'ip-address',
}

export enum ConditionOperatorType {
  stringEqual = 'equal',
  stringNotEqual = '!equal',
  stringContains = 'contains',
  stringContainsWord = 'contains-word',
  notStringContainsWord = '!contains-word',
  prefixMatches = 'prefix',
  notPrefixMatches = '!prefix',
  suffixMatches = 'suffix',
  notSuffixMatches = '!suffix',
}
