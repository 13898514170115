import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { PageError } from '@containers';
import { FrontendErrorType } from '@enums';
import { ApiGuard } from '@guards';
import { ErrorLogging } from '@services';
import { useSystemIsBackOnline } from '@store';
import { checkIsDevelopmentMode } from '@utils';

interface ErrorBoundaryProps {
  children: ReactNode;
  logErrors?: boolean;
  resetKey?: string;
}

const FallbackComponent: React.FC<FallbackProps> = () => {
  return <PageError />;
};

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, logErrors = false, resetKey }) => {
  const backOnline = useSystemIsBackOnline() ?? false;
  const consoleSourceMethod = useRef<Console['error'] | null>(null);

  const handleConsoleError = useCallback(
    (...args: any[]) => {
      args.forEach((message) => {
        if (logErrors) {
          ErrorLogging.catch(FrontendErrorType.CONSOLE, new Error(message));
        }
      });

      if (checkIsDevelopmentMode() && consoleSourceMethod.current) {
        consoleSourceMethod.current.apply(window.console, args);
      } else if (consoleSourceMethod.current) {
        consoleSourceMethod.current.apply(
          window.console,
          args.filter((message) => !ErrorLogging.checkIsErrorInExceptionList(new Error(message))),
        );
      }
    },
    [logErrors],
  );

  useEffect(() => {
    if (logErrors) {
      const originalConsoleError = window.console.error;
      consoleSourceMethod.current = originalConsoleError;
      window.console.error = handleConsoleError;

      return () => {
        if (consoleSourceMethod.current) {
          window.console.error = consoleSourceMethod.current;
        }
      };
    }
  }, [logErrors, handleConsoleError]);

  useEffect(() => {
    if (backOnline && logErrors) {
      ErrorLogging.send();
    }
  }, [backOnline, logErrors]);

  const handleError = useCallback(
    (error: Error) => {
      if (logErrors) {
        ErrorLogging.catch(FrontendErrorType.RENDER, error);
      }
    },
    [logErrors],
  );

  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent} onError={handleError} resetKeys={[resetKey]}>
      <ApiGuard>{children} </ApiGuard>
    </ReactErrorBoundary>
  );
};
