import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { accessToken, clearAllStorageData, refreshToken } from '@/storage';
import { ROUTES } from '@constants';
import { Headers } from '@enums';
import {
  clearLoginError,
  getUserSummary,
  getUserSummarySuccess,
  logOut,
  login,
  loginError,
  loginSuccess,
} from '@store';
import { API, setHeaders } from '@utils';
import { take } from 'lodash';

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  token_type: string;
}

export function* userLoginSaga({ payload }: PayloadAction<any>) {
  try {
    const { data } = yield call(API.post, `/user/login`, payload);

    yield call(accessToken.setItem, data.access_token);
    yield call(refreshToken.setItem, data.refresh_token);
    yield call(setHeaders, { [Headers.Authorization]: `Bearer ${data.access_token}` });

    yield put(clearLoginError());
    yield put(loginSuccess());
    yield put(getUserSummary());
    yield take(getUserSummarySuccess.type);
  } catch (error: any) {
    yield put(loginError(error?.response?.data?.data));
  }
}

export function* logOutSaga() {
  yield call(clearAllStorageData);
  yield call([window.location, 'replace'], ROUTES.login);
}

export function* authSagaWatcher() {
  yield takeLatest(login.type, userLoginSaga);
  yield takeLatest(logOut.type, logOutSaga);
}
