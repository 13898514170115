import { format, fromUnixTime } from 'date-fns';

export const formatTableTimestamp = (unixTimestamp) => {
  if (!unixTimestamp || isNaN(unixTimestamp)) {
    return 'Invalid timestamp';
  }

  const date = fromUnixTime(Number(unixTimestamp));
  return format(date, 'dd/MM/yyyy hh:mm a');
};
