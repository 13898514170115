import { FC, ReactNode, memo, useEffect, useMemo, useState } from 'react';

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface ResponsiveProps {
  children: ReactNode;
  showAbove?: Breakpoint;
  showBelow?: Breakpoint;
}

export const breakpoints: Readonly<Record<Breakpoint, number>> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
} as const;

const mediaQueryCache = new Map<string, MediaQueryList>();

const getMediaQuery = (query: string): MediaQueryList | null => {
  if (typeof window === 'undefined') return null;

  if (!mediaQueryCache.has(query)) {
    mediaQueryCache.set(query, window.matchMedia(query));
  }
  return mediaQueryCache.get(query) ?? null;
};

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(() => getMediaQuery(query)?.matches ?? false);

  useEffect(() => {
    const mediaQuery = getMediaQuery(query);
    if (!mediaQuery) return;

    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches);

    mediaQuery.addEventListener('change', onChange);
    setMatches(mediaQuery.matches);

    return () => mediaQuery.removeEventListener('change', onChange);
  }, [query]);

  return matches;
};

const createMediaQuery = (above?: Breakpoint, below?: Breakpoint): string => {
  if (above) return `(min-width: ${breakpoints[above]}px)`;
  if (below) return `(max-width: ${breakpoints[below] - 1}px)`;
  return '';
};

export const Responsive: FC<ResponsiveProps> = memo(({ children, showAbove, showBelow }) => {
  const mediaQuery = useMemo(() => createMediaQuery(showAbove, showBelow), [showAbove, showBelow]);

  const isVisible = useMediaQuery(mediaQuery);

  if (!showAbove && !showBelow) return null;
  return isVisible ? <>{children}</> : null;
});

Responsive.displayName = 'Responsive';
