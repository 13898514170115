import { call, put, takeLatest } from 'redux-saga/effects';

import { getCdnSummary, getCdnSummaryError, getCdnSummarySuccess } from '@store';

import { PayloadAction } from '@reduxjs/toolkit';
import { API } from '@utils';

export function* getCdnSummarySaga({ payload }: PayloadAction<string | number>) {
  try {
    const { data } = yield call(API.get, `/cdn/application/${payload}`);
    yield put(getCdnSummarySuccess(data));
  } catch (error) {
    yield put(getCdnSummaryError(error as Error));
  }
}

export function* cdnSagaWatcher() {
  yield takeLatest(getCdnSummary.type, getCdnSummarySaga);
}
