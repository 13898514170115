import { ApexOptions } from 'apexcharts';
import React, { memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { breakpoints, NoRecordFound, useMediaQuery } from '@components';
import { ApiEndpoint, DashboardEndpoint, FormType } from '@enums';
import { useChartDimensions } from '@hooks';
import { useFormList } from '@store';
import { formatBarChartYAxisUnit, formatNumberWithSeparator, validateValueEqual } from '@utils';

const PADDING_FACTOR = 1.15;
const dimensionData = {
  mobile: { height: 350, yAxisWidth: 80 },
  tablet: { height: 400, yAxisWidth: 100 },
  desktop: { height: 480, yAxisWidth: 130 },
  large: { height: 500, yAxisWidth: 150 },
};

interface HorizontalBarChartProps {
  payload: {
    endpoint: ApiEndpoint;
    formType: FormType;
  };
}

export const HorizontalBarChart: React.FC<HorizontalBarChartProps> = memo(({ payload }) => {
  const isBelowSm = useMediaQuery(`(max-width: ${breakpoints.sm}px)`);
  const graphData = useFormList(payload);

  const { seriesData, maxValue } = useMemo(() => {
    if (!graphData.length) {
      return { seriesData: { value: [], key: [] }, maxValue: 0 };
    }

    const xKey = validateValueEqual(payload.endpoint, DashboardEndpoint.TOP_TEN_URL) ? 'url' : 'ip_address';
    const value: number[] = [];
    const key: string[] = [];
    let max = 0;

    graphData.forEach((entry) => {
      const y = typeof entry.count === 'number' && !isNaN(entry.count) ? entry.count : 0;
      const x = String(entry[xKey] ?? '');

      value.push(y);
      key.push(x);
      if (y > max) max = y;
    });

    return { seriesData: { value, key }, maxValue: max };
  }, [graphData, payload.endpoint]);

  const { chartHeight, yAxisWidth } = useChartDimensions({ dimensionsData: dimensionData });
  const paddedMaxValue = maxValue * PADDING_FACTOR;

  const formatters = useMemo(
    () => ({
      yAxis: (value: string | number) => {
        const numericValue = typeof value === 'string' ? parseFloat(value) : value;
        return formatBarChartYAxisUnit(numericValue);
      },
      tooltip: (value: number) => formatNumberWithSeparator(value),
    }),
    [],
  );

  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        type: 'bar',
        height: chartHeight,
        width: '100%',
        toolbar: { show: false },
        animations: { enabled: false },
      },
      dataLabels: { enabled: false },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '70%',
          dataLabels: { position: 'outside' },
        },
      },
      background: { enabled: true },
      grid: {
        show: true,
        borderColor: '#e7e7e7',
        strokeDashArray: 4,
        position: 'back',
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: false } },
      },
      labels: seriesData.key,
      xaxis: {
        labels: { formatter: formatters.yAxis },
        tickAmount: isBelowSm ? 3 : undefined,
        max: paddedMaxValue,
        min: 0,
      },
      yaxis: {
        labels: {
          minWidth: yAxisWidth,
          maxWidth: yAxisWidth,
          style: { colors: ['#000'] },
        },
      },
      tooltip: {
        y: { formatter: formatters.tooltip },
      },
    }),
    [seriesData.key, isBelowSm, paddedMaxValue, yAxisWidth, chartHeight, formatters],
  );

  const series = useMemo(
    () => [
      {
        name: 'Request Count',
        data: seriesData.value,
      },
    ],
    [seriesData.value],
  );

  if (!seriesData.value.length) {
    return <NoRecordFound />;
  }

  return (
    <div className="justify-center mt-8">
      <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
    </div>
  );
});
