import { useTranslation } from 'react-i18next';

import { breakpoints, Button, useMediaQuery } from '@components';
import { Translation } from '@enums';

export const SettingsButtons = ({ handleSaveModal, isLoading }: any) => {
  const { t: tConfigurationButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons' });

  const isAboveSm = useMediaQuery(`(min-width: ${breakpoints.sm}px)`);

  return (
    <div className="flex gap-4 flex-1 items-end sm:flex-none">
      <Button fullWidth={!isAboveSm} onClick={handleSaveModal} loading={isLoading} disabled={false}>
        {tConfigurationButton('saveSettings')}
      </Button>
    </div>
  );
};
