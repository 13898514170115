import { validateValueEqual } from '@/utils';
import { VariantType } from '@enums';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { colors } from '../Badges/Badge';
import { Button } from '../Buttons';
import { Text } from '../Text';
import { Option, useSelectBox } from './useSelectBox';

type Align = 'start' | 'end';
type Placement = 'top' | 'right' | 'bottom' | 'left';
type AnchorProps = `${Placement}` | `${Placement} ${Align}`;

interface SelectBoxProps {
  options: Option[];
  fullWidth?: boolean;
  smallFont?: boolean;
  name: string;
  onOptionSelect?: (option: Option) => void;
  color?: any;
  customMenu?: ReactNode;
  menuItemClassName?: string;
  withoutPadding?: boolean;
  anchorDirection?: AnchorProps;
}

export const SelectBox: React.FC<SelectBoxProps> = ({
  options,
  smallFont = false,
  fullWidth = false,
  name,
  onOptionSelect,
  withoutPadding,
  anchorDirection = 'bottom end',
  color = 'bg-slate-700 bg-opacity-5',
  customMenu,
  menuItemClassName = 'px-3 py-3.5',
}) => {
  const { field, meta, selected, setValue } = useSelectBox({ name, options });

  const handleOptionClick = (option: Option) => {
    setValue(option.value);
    if (onOptionSelect) {
      onOptionSelect(option);
    }
  };

  const dynamicContainerClasses = classNames(
    'inline-flex items-center justify-between gap-2 rounded-md text-sm/6 shadow-inner shadow-white/10 focus:outline-none',
    colors[color] ?? color,
    {
      'px-2.5 py-2 sm:py-2.5 sm:px-3.5': customMenu,
      'sm:py-2.5 sm:px-3.5 py-2 px-2.5': !customMenu,
      'w-full': fullWidth,
      'w-fit': !fullWidth,
      'text-xs': smallFont,
      'text-sm': !smallFont,
    },
  );

  if (!name || !selected) return;

  return (
    <div className="flex text-right">
      <Menu>
        <MenuButton as={'div'}>
          <Button withoutScale customizeColor withoutPadding={withoutPadding} className={dynamicContainerClasses}>
            {customMenu ?? (
              <>
                <Text $level={6} color="text-gray-600">
                  {selected?.label}
                </Text>
                <ChevronUpDownIcon className="size-4 sm:size-5 text-gray-400" aria-hidden="true" />
              </>
            )}
          </Button>
        </MenuButton>
        <MenuItems
          anchor={anchorDirection}
          className={`w-fit origin-top-right my-1.5 h-auto bg-white shadow-card rounded-xl`}
        >
          <div className="max-h-40 flex flex-col p-2 gap-1 overflow-y-auto padded-scrollbar-track">
            {options.map((option, index) => {
              const isCurrent = validateValueEqual(option.value.toString(), field.value.toString());

              const dynamicRowClasses = classNames(
                'group flex w-full min-w-40 max-w-52 items-center rounded-lg text-opacity-100 justify-between relative ',
                {
                  'bg-slate-100 text-slate-900 hover:cursor-not-allowed pr-10': isCurrent,
                  'text-gray-900 hover:text-green-900 hover:bg-gray-100': isCurrent,
                },
                menuItemClassName,
              );
              return (
                <MenuItem key={index}>
                  <Button
                    variant={VariantType.Clean}
                    rounded="rounded-2xl"
                    withoutPadding
                    withoutCenter
                    disabled={isCurrent}
                    className={dynamicRowClasses}
                    onClick={() => handleOptionClick(option)}
                  >
                    <Text $level={5} className="truncate">
                      {option.label}
                    </Text>
                    {isCurrent && <CheckIcon className="h-4 w-4 absolute right-3" aria-hidden="true" />}
                  </Button>
                </MenuItem>
              );
            })}
          </div>
        </MenuItems>
      </Menu>
      {meta.touched && meta.error && <div className="text-red-500 text-xs mt-1">{meta.error}</div>}
    </div>
  );
};
