import { format, fromUnixTime } from 'date-fns';

// Memoization cache
const dateCache = new Map();

/**
 * Converts a Unix timestamp to a formatted date string.
 * @param {number} unixTimestamp - The Unix timestamp to convert.
 * @returns {string} The formatted date string in 'yyyy-MM-dd HH:mm:ss' format.
 */
export const formatUnixTimestamp = (unixTimestamp) => {
  if (unixTimestamp == null) return '';

  // Check cache first
  const cachedResult = dateCache.get(unixTimestamp);
  if (cachedResult) return cachedResult;

  // Convert and format
  const dateObject = fromUnixTime(unixTimestamp);
  const formattedDate = format(dateObject, 'yyyy-MM-dd HH:mm:ss');

  // Cache the result
  dateCache.set(unixTimestamp, formattedDate);

  return formattedDate;
};
