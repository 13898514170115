import { VariantType } from '@/enums';
import { MinusCircleIcon } from '@heroicons/react/20/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Field, FieldArray, useField } from 'formik';
import { Button } from '../Buttons';
import { TextInput } from './TextInput/TextInput';

export const MultipleInput = ({
  emptyData,
  name,
  nestedName,
  placeholder,
  smallFont,
  disabled,
  onChange,
  type,
  withoutRing,
  className,
  customizeColor,
  defaultValue,
  hints,
  label,
  value,
  fullWidth,
  inputMod,
  withoutClear,
  maxField,
}) => {
  const [field, meta] = useField(name);

  return (
    <FieldArray name={name}>
      {({ remove, push, name }) => {
        return (
          <div className="grid grid-cols-1 gap-y-4 flex-col w-full">
            {field.value.map((_fieldValue, index) => (
              <div
                key={index}
                className={`${meta.error?.[name]?.[index] && 'mb-4'} flex w-full space-x-3 items-center`}
              >
                <div className="flex-grow">
                  <Field
                    smallFont={smallFont}
                    name={nestedName ? `${name}.${index}.${nestedName}` : `${name}.${index}`}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange}
                    type={type}
                    withoutRing={withoutRing}
                    className={className}
                    onBlur={field.onBlur}
                    customizeColor={customizeColor}
                    defaultValue={defaultValue}
                    emptyData={emptyData}
                    nestedName={nestedName}
                    hints={hints}
                    label={label}
                    value={value}
                    fullWidth={fullWidth}
                    inputMod={inputMod}
                    withoutClear={withoutClear}
                    as={TextInput}
                  />
                </div>
                <div className="flex space-x-3 items-start pt-1.5 h-full">
                  <Button
                    className="bg-white"
                    withoutPadding
                    disabled={disabled || field.value.length >= maxField}
                    variant={VariantType.Clean}
                    onClick={() => push(emptyData)}
                  >
                    <PlusCircleIcon
                      className={`w-6 h-6 ${field.value.length >= maxField ? 'text-gray-300' : 'text-blue-500'}`}
                    />
                  </Button>
                  <Button
                    className="bg-white"
                    variant={VariantType.Clean}
                    disabled={disabled || field.value.length <= 1}
                    withoutPadding
                    onClick={() => remove(index)}
                  >
                    <MinusCircleIcon
                      className={`w-6 h-6 ${field.value.length <= 1 ? 'text-gray-300' : 'text-red-500'}`}
                    />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        );
      }}
    </FieldArray>
  );
};
