import * as Headless from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { AnimatePresence, easeOut, motion } from 'framer-motion';
import type React from 'react';
import { Fragment, ReactNode, useEffect, useRef } from 'react';

import { fontLevelClass, Text } from '@components';
import { Form } from 'formik';

type DivProps = React.ComponentPropsWithoutRef<'div'>;
type FormProps = React.ComponentPropsWithoutRef<'form'>;

interface DisclosureBodyProps extends DivProps {
  notForm?: boolean;
}

const sizes = {
  xs: 'sm:max-w-xs',
  sm: 'sm:max-w-sm',
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '4xl': 'sm:max-w-4xl',
  '5xl': 'sm:max-w-5xl',
};

export function DisclosureModal({
  size = 'lg',
  className,
  notForm = false,
  children,
  button = {
    label: '',
    defaultOpen: false,
  },
  ...props
}: {
  size?: keyof typeof sizes;
  className?: string;
  children: ReactNode;
  button: any;
  notForm?: boolean;
} & Omit<Headless.DisclosureProps, 'className'>) {
  const disclosureRef = useRef<any>(null);

  useEffect(() => {
    if (button.defaultOpen && disclosureRef.current) {
      disclosureRef.current.defaultOpen = true;
    }
  }, [button.defaultOpen]);
  return (
    <Headless.Disclosure ref={disclosureRef} defaultOpen={button.defaultOpen} {...props}>
      {({ open }) => (
        <div className="shadow-card p-6 gap-5 rounded-lg">
          <Headless.DisclosureButton
            className={clsx(
              'group flex justify-between items-center w-full text-gray-300',
              open ? 'border-b pb-5' : '',
            )}
          >
            <Text $level={5} className={clsx(button.className, 'font-semibold')}>
              {button.label}
            </Text>
            <ChevronDownIcon
              className={clsx('w-5 text-gray-500 transition-transform duration-200', open ? 'rotate-180' : '')}
            />
          </Headless.DisclosureButton>
          <AnimatePresence>
            {open && (
              <Headless.DisclosurePanel static as={Fragment}>
                <motion.div
                  initial={{ opacity: 0, y: -24 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -24 }}
                  transition={{ duration: 0.2, ease: easeOut }}
                  className="origin-top"
                >
                  {children}
                </motion.div>
              </Headless.DisclosurePanel>
            )}
          </AnimatePresence>
        </div>
      )}
    </Headless.Disclosure>
  );
}

export function DisclosureTitle({
  className,
  level = 2,
  children,
  ...props
}: {
  className?: string;
  level?: number;
  children: React.ReactNode;
} & Omit<Headless.DisclosureButtonProps, 'className'>) {
  return (
    <Headless.DisclosureButton
      {...props}
      className={clsx(
        className,
        fontLevelClass[level],
        'w-full border-b pb-2 text-left text-balance font-medium text-zinc-950 sm:text-base/6 dark:text-white',
      )}
    >
      {children}
    </Headless.DisclosureButton>
  );
}

export function DisclosureDescription({
  className,
  $level = 5,
  ...props
}: { className?: string; $level?: number } & Omit<Headless.DisclosurePanelProps<typeof Text>, 'className'>) {
  return (
    <Headless.DisclosurePanel
      as={Text}
      {...props}
      $level={$level}
      className={clsx(className, fontLevelClass[$level], 'sm:mt-3 text-pretty leading-4')}
    />
  );
}

export function DisclosureBody({ className, notForm = false, ...props }: DisclosureBodyProps) {
  const commonClassName = clsx(className, 'mt-8 gap-4 sm:gap-5 flex flex-col');

  if (notForm) {
    return <div {...props} className={commonClassName} />;
  }

  return <Form {...(props as FormProps)} className={commonClassName} />;
}

export function DisclosureActions({ className, ...props }: Readonly<React.ComponentPropsWithoutRef<'div'>>) {
  return (
    <div
      {...props}
      className={clsx(className, 'mt-3 flex flex-row items-center justify-end gap-3 *:w-full sm:flex-row w-auto')}
    />
  );
}
