import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { AuthSliceState } from '@/types/slices/authSlice';
import { RootState, useAppSelector } from '@hooks';

const authInitialState: AuthSliceState = {
  isLoading: false,
  isAuthenticated: false,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    login: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
    },
    loginSuccess: (state) => {
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    loginError: (state, { payload }: PayloadAction<Error>) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = payload;
    },
    logOut: (state) => {
      state = authInitialState;
    },
    changeSystemAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    clearLoginError: (state) => {
      state.error = null;
    },
  },
});

export const { login, loginSuccess, loginError, logOut, changeSystemAuthenticated, clearLoginError } =
  authSlice.actions;

export const useLoginLoading = () => useAppSelector((state: RootState) => state.auth.isLoading);
export const useLoginError = () => useAppSelector((state: RootState) => state.auth.error);
export const useUserAuthenticated = () => useAppSelector((state: RootState) => state.auth.isAuthenticated);

const authReducer = authSlice.reducer;
export default authReducer;
