import i18n from 'i18next';

import { Column } from '@components';
import { CellType } from '@enums';
import { formatTableTimestamp, getColumnSizes } from '@utils';

export const cdnErrorLogTable = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', multiLines: true, ...getColumnSizes(60) },
  {
    label: i18n.t('configuration:tables.level'),
    value: 'level',
    cellType: CellType.Custom,
    format: (value = '-') => {
      return (
        <div className="flex flex-col space-y-4 h-full">
          <div className="flex flex-col space-y-1.5 flex-wrap">
            <span className="text-black text-sm w-full">{value}</span>
          </div>
        </div>
      );
    },
    ...getColumnSizes(90),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.logInformation'),
    value: '',
    multiLines: true,
    cellType: CellType.Custom,
    format: (_value, rowData) => {
      const logEntries = [
        { label: i18n.t('configuration:tables.requestId'), value: rowData?.req_id ?? '_' },
        { label: i18n.t('configuration:tables.host'), value: rowData?.host ?? '-' },
        { label: i18n.t('configuration:tables.upstream'), value: rowData?.upstream ?? '-' },
        { label: i18n.t('configuration:tables.client_ip'), value: rowData?.client_ip ?? '-' },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <span className="text-black font-medium text-sm ">{entry.label}</span>
              <span className="text-zinc-500 text-xs w-full whitespace-break-spaces break-words">{entry.value}</span>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(240),
  },
  {
    label: i18n.t('configuration:tables.messages'),
    value: 'message',
    cellType: CellType.Custom,
    format: (value = '-') => {
      return (
        <div className="flex flex-col space-y-4 pt-1.5 h-full">
          <div className="flex flex-col space-y-1.5 flex-wrap">
            <span className="text-zinc-500 text-xs w-full break-words">{value}</span>
          </div>
        </div>
      );
    },
    ...getColumnSizes(280),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.logDate'),
    value: 'timestamp',
    format: (value) => {
      const formattedDate = formatTableTimestamp(value);

      return (
        <div className="flex flex-col space-y-4 pt-1.5 h-full">
          <div className="flex flex-col space-y-1.5 flex-wrap">
            <span className="text-zinc-500 text-xs w-full whitespace-break-spaces">{formattedDate}</span>
          </div>
        </div>
      );
    },

    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
  },
];
