import classNames from 'classnames';
import { useMemo } from 'react';
import { InitLoader } from './initLoader';

interface GlobalLoaderProps {
  className?: string;
  overlay?: boolean;
  color?: string;
}

export const GlobalLoader = ({ className = '', color = 'rgb(118, 122, 131)', overlay }: GlobalLoaderProps) => {
  const dynamicClasses = useMemo(
    () =>
      classNames('flex items-center justify-center', {
        'flex flex-1 min-h-screen text-theme-primary-main bg-theme-surface-default': overlay,

        [className]: className,
      }),
    [className, overlay],
  );
  return (
    <div className={dynamicClasses}>
      <div className="bg-opacity-0 backdrop-filter backdrop-blur-sm rounded-lg"></div>
      <div aria-label="Loading...">
        <InitLoader color={color} />
      </div>
    </div>
  );
};
