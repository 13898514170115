import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import type { PaginationProps } from '@types';
import { Column, Text } from '@components';

interface TableCellProps {
  data?: ReactNode;
  column: Column;
  rowId: number;
  pagination?: PaginationProps;
  multiLines?: boolean;
}

const formatCellData = (
  data: ReactNode,
  options: {
    isIdColumn: boolean;
    isSortedId: boolean;
    rowId: number;
    startIndex: number;
  },
): ReactNode => {
  const { isIdColumn, isSortedId, rowId, startIndex } = options;

  if (isIdColumn) {
    return rowId + startIndex;
  }

  if (!data) {
    return '—';
  }

  if (typeof data === 'number' || typeof data === 'string' || isSortedId) {
    return data;
  }

  if (typeof data === 'object') {
    return data;
  }

  return '—';
};

export const DefaultTableCell: FC<TableCellProps> = ({ data, rowId, pagination, column, multiLines = false }) => {
  const { value, label } = column;
  const isSortedId = value === 'original_id' && label === '#';
  const isIdColumn = value === 'id' && label === '#';
  const startIndex = pagination ? (pagination.page - 1) * pagination.page_size + 1 : 1;

  const cellClasses = classNames('text-sm font-normal select-text leading-6', {
    'pl-3': isIdColumn || isSortedId,
    'h-full flex flex-col whitespace-break-spaces break-words': multiLines,
    'truncate ellipsis-text': !multiLines,
  });

  const content = formatCellData(data, {
    isIdColumn,
    isSortedId,
    rowId,
    startIndex,
  });

  return (
    <Text $level={4} color="text-black" className={cellClasses}>
      {content}
    </Text>
  );
};
