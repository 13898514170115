export const createFormError = (error: unknown): any => {
  if (error instanceof Error) {
    return {
      message: error.message,
      code: (error as any).code,
      details: (error as any).details,
    };
  }
  return { message: String(error) };
};
