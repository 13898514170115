import { ServiceModalName } from '@enums';
import { useAllServiceModal } from '@store';
import { lazy, Suspense, useCallback } from 'react';

const GlobalConfirmModal = lazy(() => import('./GlobalModal/GConfirmModal/GConfirmModal'));
const GlobalFormModal = lazy(() => import('./GlobalModal/GFormModal/GFormModal'));
const GlobalPrioritizeModal = lazy(() => import('./GlobalModal/GPrioritizeModal/GPrioritizeModal'));

export const ServiceModal = () => {
  const modals = useAllServiceModal();

  const renderModal = useCallback((modalName: string, index: number) => {
    switch (modalName) {
      case ServiceModalName.ConfirmModal:
        return (
          <Suspense key={modalName} fallback={null}>
            <GlobalConfirmModal index={index} />
          </Suspense>
        );
      case ServiceModalName.FormModal:
        return (
          <Suspense key={modalName} fallback={null}>
            <GlobalFormModal index={index} />
          </Suspense>
        );
      case ServiceModalName.PrioritizeModal:
        return (
          <Suspense key={modalName} fallback={null}>
            <GlobalPrioritizeModal index={index} />
          </Suspense>
        );
      default:
        return null;
    }
  }, []);

  if (!Object.keys(modals).length) return null;
  return <>{Object.entries(modals)?.map(([modalName], idx) => renderModal(modalName, 1200 + idx + 1))}</>;
};

export default ServiceModal;
