import i18n from 'i18next';
import { InputHTMLAttributes } from 'react';

import {
  Dictionaries,
  InputType,
  PageRulesAction,
  limitRequestRate_key,
  limitRequestRate_rejectAction,
  limitResponseDataRate_rateUnit,
  redirect_currentCustom,
  redirect_hostCustom,
  redirect_statusCode,
  responseCookie_headerValue,
  responseHeader_headerVariable,
} from '@enums';
import { getOptionListFromCatalog } from '@utils';

interface Field extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  value: any;
  inputType: InputType;
  option?: any[];
  format?: any;
  previousActionKey?: string;
  description?: string;
}

export const fieldActionInput = (actionKey: PageRulesAction): Field[] => {
  switch (actionKey) {
    case PageRulesAction.Redirect:
      return [
        {
          name: 'uri',
          value: redirect_currentCustom.Current,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.RedirectCurrent),
        },
        {
          name: 'uriValue',
          value: '',
          placeholder: i18n.t('validation:format.uriValue'),
          inputType: InputType.Text,
        },
        {
          name: 'uriArguments',
          value: redirect_currentCustom.Current,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.RedirectCurrent),
        },
        {
          name: 'customUriArguments',
          value: '',
          placeholder: i18n.t('validation:format.customUriArguments'),
          inputType: InputType.Text,
        },
        {
          name: 'scheme',
          value: redirect_hostCustom.Current,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.RedirectHost),
        },
        {
          name: 'host',
          value: redirect_currentCustom.Current,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.RedirectCurrent),
        },
        {
          name: 'domain',
          value: '',
          placeholder: i18n.t('validation:format.domain'),
          inputType: InputType.Text,
        },
        {
          name: 'port',
          value: '',
          placeholder: i18n.t('validation:format.port'),
          inputType: InputType.Text,
        },
        {
          name: 'statusCode',
          value: redirect_statusCode.MovedTemporarily,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.RedirectStatusCode),
        },
      ];
    case PageRulesAction.Delay:
      return [
        {
          name: 'value',
          value: '',
          placeholder: i18n.t('validation:format.fiveSeconds'),
          inputType: InputType.Text,
        },
      ];
    case PageRulesAction.Limit_Request_Rate:
      return [
        {
          name: 'key',
          value: limitRequestRate_key.ClientIpAddress,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.LimitRequestRate),
        },
        {
          name: 'shapeAt',
          value: '1 request/second',
          placeholder: i18n.t('validation:format.oneRequestPerSecond'),
          inputType: InputType.Text,
        },
        {
          name: 'rejectAt',
          value: '1 request/second',
          placeholder: i18n.t('validation:format.oneRequestPerSecond'),
          inputType: InputType.Text,
        },
        {
          name: 'rejectAction',
          value: limitRequestRate_rejectAction.ErrorPage,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.LimitRequestRateRejectionAction),
        },
        {
          name: 'statusCode',
          value: '503',
          placeholder: i18n.t('validation:format.statusCode'),
          inputType: InputType.Text,
        },
        {
          name: 'preventCcAttack',
          value: true,
          inputType: InputType.Switch,
        },
      ];
    case PageRulesAction.Limit_Request_Count:
      return [
        {
          name: 'key',
          value: limitRequestRate_key.NoKey,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.LimitRequestRate),
        },
        {
          name: 'rejectAt',
          value: '',
          inputType: InputType.Text,
          placeholder: i18n.t('validation:format.number'),
          type: 'number',
        },
        {
          name: 'timeWindow',
          value: '',
          inputType: InputType.Text,
          placeholder: i18n.t('validation:format.number'),
          type: 'number',
        },
      ];
    case PageRulesAction.Block_Request:
      return [
        {
          name: 'key',
          value: limitRequestRate_key.NoKey,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.LimitRequestRate),
        },
        {
          name: 'targetRate',
          value: '1 request/second',
          placeholder: i18n.t('validation:format.oneRequestPerSecond'),
          inputType: InputType.Text,
        },
        {
          name: 'rejectRate',
          value: '1 request/second',
          placeholder: i18n.t('validation:format.oneRequestPerSecond'),
          inputType: InputType.Text,
        },
        {
          name: 'blockThreshold',
          value: '2',
          inputType: InputType.Text,
          placeholder: i18n.t('validation:format.number'),
          type: 'number',
        },
        {
          name: 'observeInterval',
          value: '5 seconds',
          placeholder: i18n.t('validation:format.fiveSeconds'),
          inputType: InputType.Text,
        },
        {
          name: 'blockTime',
          value: '60 seconds',
          placeholder: i18n.t('validation:format.sixtySeconds'),
          inputType: InputType.Text,
        },
      ];
    case PageRulesAction.Set_Response_Header:
    case PageRulesAction.Set_PROXY_HEADER:
    case PageRulesAction.Add_Response_Header:
      return [
        {
          name: 'headerName',
          value: '',
          placeholder: i18n.t('configuration:forms.pageRules.pleaseEnterYourName'),
          inputType: InputType.Text,
        },
        {
          name: 'headerVariable',
          value: responseHeader_headerVariable.String,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.ResponseHeaderVariable),
        },
        {
          name: 'headerValue',
          value: '',
          description: i18n.t('configuration:forms.pageRules.pleaseEnterYourHeaderValue'),
          inputType: InputType.Text,
        },
      ];
    case PageRulesAction.Set_Expiration_Time:
      return [
        {
          name: 'expiresIn',
          value: '1 Hour',
          placeholder: i18n.t('validation:format.oneHour'),
          inputType: InputType.Text,
        },
        {
          name: 'enforceCache',
          value: false,
          inputType: InputType.Switch,
          description: i18n.t('description:configuration.pageRules.actionTable.enforceCacheDescription'),
        },
      ];
    case PageRulesAction.Set_RESPONSE_COOKIE:
      return [
        {
          name: 'name',
          value: '',
          placeholder: i18n.t('configuration:forms.pageRules.pleaseEnterYourName'),
          inputType: InputType.Text,
        },
        {
          name: 'headerVariable',
          value: 'String',
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.ResponseHeaderVariable),
        },
        {
          name: 'headerValue',
          value: responseCookie_headerValue.userId,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.ResponseCookieValue),
        },
        {
          name: 'domain',
          value: '',
          placeholder: i18n.t('validation:format.domain'),
          inputType: InputType.Text,
        },
        {
          name: 'path',
          value: '',
          inputType: InputType.Text,
        },
        {
          name: 'httpOnly',
          value: true,
          inputType: InputType.Switch,
        },
        {
          name: 'maxAge',
          value: '1 Hours',
          placeholder: i18n.t('validation:format.oneHour'),
          inputType: InputType.Text,
        },
      ];
    case PageRulesAction.LIMIT_RESPONSE_DATA_RATE:
      return [
        {
          name: 'rate',
          value: '',
          placeholder: i18n.t('validation:format.number'),
          inputType: InputType.Text,
        },
        {
          name: 'rateUnit',
          value: limitResponseDataRate_rateUnit.KbPerSecond,
          inputType: InputType.Select,
          option: getOptionListFromCatalog(Dictionaries.LimitResponseDataRateUnit),
        },
      ];
    case PageRulesAction.ENABLE_H_CAPTCHA:
      return [
        {
          name: 'clearanceTime',
          value: '60 seconds',
          placeholder: i18n.t('validation:format.sixtySeconds'),
          inputType: InputType.Text,
        },
      ];
    case PageRulesAction.Set_PROXY_HOST:
      return [
        {
          name: 'hostName',
          value: '',
          placeholder: i18n.t('configuration:forms.pageRules.pleaseEnterYourHostName'),
          inputType: InputType.Text,
        },
      ];
    default:
      return [];
  }
};
