import { StatusType } from '@/enums';

interface SnackState {
  type?: StatusType;
  message: string;
  timeout?: number | null;
}

export const CONSOLE_EXCLUDED_ERROR_FRAGMENTS = [
  'MUI: ',
  'Warning: validateDOMNesting',
  'GET ',
  'POST ',
  'PUT ',
  '404 Not Found',
  'Error during service worker registration',
  'A bad HTTP response code (403) was received when fetching the script',
  'No routes matched location',
];

export const HTTP_STATUS_MESSAGES = {
  400: 'Bad request. Please check your input and try again.',
  401: 'Unauthorized. You do not have permission to view this resource.',
  403: 'Forbidden. Access is denied to the requested resource.',
  404: 'Not found. The requested resource could not be found.',
  405: 'Method not allowed. The method is not supported for this resource.',
  500: 'Internal server error. Something went wrong on our end.',
  502: 'Bad gateway. The server received an invalid response.',
  503: 'Service unavailable. Please try again later.',
  504: 'Gateway timeout. The server did not receive a timely response.',
};

export enum ERROR_CODES {
  ERR_CANCELED = 'ERR_CANCELED',
  ERR_NETWORK = 'ERR_INTERNET_DISCONNECTED',
  TOO_MANY_LOGIN_ATTEMPTS = 'TOO_MANY_LOGIN_ATTEMPTS',
  USER_CREDENTIAL_INVALID = 'USER_CREDENTIAL_INVALID',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = '404',
}

export enum ERROR_MESSAGE {
  DEFAULT_WAF_NOT_FOUND = 'Default WAF Rules not Found',
  DEFAULT_PAGE_RULES_NOT_FOUND = 'Default Page Rule not Found',
  DEFAULT_CACHE_RULES_NOT_FOUND = 'Default Cache Rules not Found',
  LOGIN_EXPIRED = 'Signature has expired',
  LOGIN_VERIFACTION_FAILED = 'Signature verification failed',
  NON_REFERSH_TOKENS_ARE_ALLOWED = 'Only non-refresh tokens are allowed',
  TOKEN_EXPIRED_OR_INVALID = 'Token expired or invalid',
  NOT_ENOUGH_SEGMENTS = 'Not enough segmenets',
  APPLICATION_NOT_FOUND = 'Application Not Found',
  SSL_LOG_NOT_EXIST = 'SSL does not exists',
}

export const ERROR_CUSTOM_SNACKS: { [key in keyof typeof ERROR_CODES]?: SnackState } = {};
