export const formatBarChartYAxisUnit = (value: number, dp: number = 0): string => {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(dp)}B`;
  } else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(dp)}M`;
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(dp)}K`;
  }
  return value?.toFixed(dp);
};
