export enum sslCertificateType {
  manual = 'manual',
  letsEncrypt = 'auto',
}

export enum certificateStatusType {
  Expired = 'expired',
  ExpiredSoon = 'expiredSoon',
  PendingIssue = 'pendingIssue',
  Active = 'active',
  Inactive = 'inactive',
}
